import { faComment, faFileDownload, faPaperclip, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UISref } from '@uirouter/react';
import { Button, Popconfirm, Tooltip } from 'antd';
import React from 'react';

import { VoidFnType } from '@/types/common';
import { getTranslatedString } from '@/utils/intl';

export interface EditActionProps {
  className?: string;
  icon?: any;
  uiSref: {
    to: string;
    params: Record<string, unknown>;
  };
  title?: string;
  tooltipTitle?: string;
}

export interface DeleteActionProps {
  onConfirm: VoidFnType;
  title?: string;
}

const stopPropagationHandler = (event: React.MouseEvent) => {
  event.stopPropagation();
};

export const AddAction = ({ icon, uiSref: { to, params }, title, tooltipTitle }: EditActionProps) => {
  return (
    <UISref to={to} params={params}>
      <a onClick={stopPropagationHandler}>
        <Tooltip title={tooltipTitle ? getTranslatedString(`${tooltipTitle}`) : ''}>
          <Button
            className={title ? 'action-button' : ''}
            type="primary"
            shape={title ? 'round' : 'circle'}
            icon={icon}
          >
            {title}
          </Button>
        </Tooltip>
      </a>
    </UISref>
  );
};

export const EditAction = ({ uiSref: { to, params }, title }: EditActionProps) => {
  return (
    <UISref to={to} params={params}>
      <a onClick={stopPropagationHandler}>
        <Tooltip title={getTranslatedString('table.edit')}>
          <Button type="primary" shape={title ? 'round' : 'circle'} icon={<FontAwesomeIcon icon={faPen} />}>
            {title}
          </Button>
        </Tooltip>
      </a>
    </UISref>
  );
};

export const DeleteAction = ({ onConfirm, title }: DeleteActionProps) => {
  return (
    <Popconfirm title={title || 'Sure to delete?'} onConfirm={onConfirm} onCancel={stopPropagationHandler}>
      <Tooltip title={getTranslatedString('table.delete')}>
        <Button
          onClick={stopPropagationHandler}
          type="primary"
          danger={true}
          shape="circle"
          icon={<FontAwesomeIcon icon={faTrash} />}
        />
      </Tooltip>
    </Popconfirm>
  );
};

export const AttachmentAction = ({ uiSref: { to, params } }: EditActionProps) => {
  return (
    <UISref to={to} params={params}>
      <a onClick={stopPropagationHandler}>
        <Tooltip title={getTranslatedString('attachments.attachments')}>
          <Button type="primary" shape="circle" icon={<FontAwesomeIcon icon={faPaperclip} />} />
        </Tooltip>
      </a>
    </UISref>
  );
};

export const ReportAction = ({ onClick }: any) => {
  return (
    <a onClick={stopPropagationHandler}>
      <Tooltip title={getTranslatedString('table.report')}>
        <Button type="primary" shape="circle" onClick={onClick} icon={<FontAwesomeIcon icon={faFileDownload} />} />
      </Tooltip>
    </a>
  );
};

export const NotesAction = ({uiSref: {to, params}}: EditActionProps) => {
  return (
    <UISref to={to} params={params}>
      <a onClick={stopPropagationHandler}>
        <Tooltip title={getTranslatedString('table.notes')}>
          <Button type="primary" shape="circle" icon={<FontAwesomeIcon icon={faComment} />} />
        </Tooltip>
      </a>
    </UISref>
  );
};

export const SelectorAction = ({ uiSref: { to, params }, title }: any) => {
  return (
    <UISref to={to} params={params}>
      <a onClick={stopPropagationHandler}>
        <Button type="primary" shape="round" icon={<FontAwesomeIcon icon={faPen} />}>{title}</Button>
      </a>
    </UISref>
  );
};
