import React from 'react';
import {
  faPallet,
  faRectangleLandscape,
  faSack,
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ProductIconProps {
  type: cyrian.api.products.Type;
}

interface ProductSettngs {
  letter: string;
  styles: Record<'l1' | 'l2' | 'l3', Record<string, unknown>>,
}

const typeSettings: Record<cyrian.api.products.Type, ProductSettngs> = {
  'Utz': {
    letter: 'U',
    styles: {
      l1: {
        color: 'purple',
        '--fa-secondary-opacity': 0.7,
      },
      l2: {
        color: 'purple',
        '--fa-secondary-opacity': 1.0,
      },
      l3: {
        color: 'purple',
        '--fa-secondary-opacity': 0.7,
      },
    },
  },
  'Fair trade': {
    letter: 'F',
    styles: {
      l1: {
        color: 'blue',
        '--fa-secondary-opacity': 0.7,
      },
      l2: {
        color: 'blue',
        '--fa-secondary-opacity': 1.0,
      },
      l3: {
        color: 'blue',
        '--fa-secondary-opacity': 0.7,
      },
    },
  },
  'Rainforest alliance': {
    letter: 'R',
    styles: {
      l1: {
        color: 'green',
        '--fa-secondary-opacity': 0.7,
      },
      l2: {
        color: 'green',
        '--fa-secondary-opacity': 1.0,
      },
      l3: {
        color: 'green',
        '--fa-secondary-opacity': 0.7,
      },
    },
  },
  'Ordinary': {
    letter: '',
    styles: {
      l1: {
        color: 'brown',
        '--fa-secondary-opacity': 0.7,
      },
      l2: {
        '--fa-primary-color': 'grey',
        '--fa-secondary-color': 'brown',
        '--fa-secondary-opacity': 1.0,
      },
      l3: {
        color: 'brown',
        '--fa-secondary-opacity': 0.7,
      },
    },
  },
};

const unknownProductSettings: ProductSettngs = {
  letter: '?',
  styles: {
    l1: {
      color: 'grey',
      '--fa-secondary-opacity': 0.7,
    },
    l2: {
      '--fa-primary-color': 'grey',
      '--fa-secondary-color': 'grey',
      '--fa-secondary-opacity': 1.0,
    },
    l3: {
      color: 'grey',
      '--fa-secondary-opacity': 0.7,
    },
  },
};

export const ProductIcon = ({ type }: ProductIconProps) => {
  const { letter, styles: { l1, l2, l3 } } = typeSettings[type] ?? unknownProductSettings;

  return (
    <span className="fa-stack">
      <FontAwesomeIcon icon={faRectangleLandscape} mask={faPallet} transform={'up-4'} className="fad fa-rectangle-landscape fa-stack-2x" style={{ color: 'grey' }}/>
      <FontAwesomeIcon icon={faSack} transform={'shrink-1 left-10 up-4'} className="fad fa-sack fa-stack-1x" style={l1 as React.CSSProperties}/>
      <FontAwesomeIcon icon={faSack} transform={'shrink-1 up-4'} className="fad fa-sack fa-stack-1x" style={l2 as React.CSSProperties}/>
      <FontAwesomeIcon icon={faSack} transform={'shrink-1 right-10 up-4'} className="fad fa-sack fa-stack-1x" style={l3 as React.CSSProperties}/>
      { letter && <span className="fa-layers-text" data-fa-transform="up-2 shrink-5" style={{ color: 'white' }}>{letter}</span> }
    </span>
  );
}
