import { CounterpartyType } from '@/types/enums';
import { buildDropdownOptionsFromEnum } from '@/utils/common';

export const getFormFields = () => {
  return [
    {
      name: 'name',
      componentName: 'input',
      params: {
        i18nLabel: 'counterparties.name',
      },
      rules: [{ required: true }],
    },
    {
      name: 'type',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'counterparties.type',
        options: buildDropdownOptionsFromEnum(CounterpartyType),
      },
      rules: [{ required: true }],
    },
  ];
};
