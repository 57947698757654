import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UIView } from '@uirouter/react';
import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Table } from '@/components/table';
import { PERMISSION_CREATE, PERMISSION_OBJ_TRANSIT_ORDER } from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';
import { transitOrderStore } from '@/stores';
import { useMassUpdateCashedStores } from '@/utils/store';

import { columns } from './setup';

const entity = 'transit-orders';
const store = transitOrderStore;

const CreateButton = withPermissions([[PERMISSION_OBJ_TRANSIT_ORDER, PERMISSION_CREATE]])(({ onClick }: any) => {
  return (
    <Button onClick={onClick} type="primary" icon={<FontAwesomeIcon icon={faPlus} />}>
      &nbsp;&nbsp;<FormattedMessage id="transit-orders.add-new" />
    </Button>
  );
});

export const TransitOrders = observer(({ transition }: any) => {
  const items = store.items;

  useMassUpdateCashedStores([transitOrderStore]);

  const onAdd = () => {
    transition.router.stateService.go('base-layout.transit-orders.create');
  };

  return (
    <>
      <CreateButton onClick={onAdd} />
      <Table
        rowKey="id"
        columns={columns}
        dataSource={[...items]}
        store={store}
        scroll={{ x: 1550 }}
      />
      <UIView />
    </>
  );
});
