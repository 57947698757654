import { UIViewInjectedProps } from '@uirouter/react';
import React from 'react';

import { Attachments } from '@/components/attachments';
import { AttachmentType } from '@/types/enums';

export const ContractLineItemAttachments = ({ transition }: UIViewInjectedProps) => {
  const params = {
    transition,
    apiEntity: 'ContractLineItem',
    paramId: 'lineItemId',
    attachmentType: AttachmentType.ContractLineItem,
    closeRoute: 'base-layout.contract-line-items'
  };

  return <Attachments<cyrian.api.contractLineItems.LineItemWithAttachment> {...params} />;
};
