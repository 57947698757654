import { addressStore } from '@/stores';
import { buildStoreOptions } from '@/utils/common';

export const getFormFields = () => [
  {
    name: 'addressId',
    componentName: 'dropdown',
    params: {
      i18nLabel: 'lot.fumigation.location',
      options: buildStoreOptions(addressStore, 'name'),
    },
    rules: [{ required: true }],
  },
  {
    name: 'startDate',
    componentName: 'datepicker',
    params: {
      i18nLabel: 'lot.fumigation.start-date',
      showTime: true,
    },
    rules: [{ required: true }],
  },
  {
    name: 'endDate',
    componentName: 'datepicker',
    params: {
      i18nLabel: 'lot.fumigation.end-date',
      showTime: true,
    },
    rules: [{ required: true }],
  },
];
