import { FormInstance } from 'antd/lib/form/hooks/useForm';
import React, { useState } from 'react';

import { counterpartyContractStore } from '@/stores';

export const useContracts = (form: FormInstance) => {
  const [customerContracts, setCustomerContracts] = useState<any[]>([]);

  const onCustomerChange = (customerId: number) => {
    const contracts = counterpartyContractStore.items
      .filter(({ counterparty }: cyrian.api.counterpartyContracts.CounterpartyContract) => (
        counterparty.id === customerId
      ))
      .map((item: cyrian.api.counterpartyContracts.CounterpartyContract) => ({
        value: item.id,
        label: item.clientContract,
      }));

    setCustomerContracts(contracts);
    form.resetFields(['contractId']);
  };

  return { customerContracts, onCustomerChange };
};
