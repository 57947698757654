import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Transition } from '@uirouter/react';
import { Avatar, Button, Comment, Empty, Form, Input, List } from 'antd';
import React, { useEffect, useRef, useState } from 'react';

import { api } from '@/api';
import { WrappedItemsResult, WrappedResult } from '@/api/types';
import { Drawer } from '@/components/drawer';
import { Loader } from '@/components/loader';
import { NoteObjectType } from '@/types/enums';
import { getTranslatedString } from '@/utils';
import { showError } from '@/utils/common';
import { getFormattedDate } from '@/utils/moment';

const {TextArea} = Input;
const DefaultAvatar = <Avatar icon={<FontAwesomeIcon icon={faUser} />} />;

const CommentList = ({comments}: { comments: cyrian.api.notes.Note[] }) => {
  return <List
    dataSource={comments}
    itemLayout="horizontal"
    renderItem={(props: cyrian.api.notes.Note) => <Comment
      author={props.user.name || props.user.email}
      avatar={DefaultAvatar}
      content={<p>{props.text}</p>}
      datetime={getFormattedDate(props.createdAt)}
    />}
  />;
};

const Editor = ({onChange, onSubmit, submitting, value}) => (
  <>
    <Form.Item>
      <TextArea rows={2} onChange={onChange} value={value} />
    </Form.Item>
    <Form.Item noStyle={true}>
      <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary" disabled={!value}>
        Add Comment
      </Button>
    </Form.Item>
  </>
);

interface NotesProps {
  transition: Transition;
  paramId: string;
  closeEntity: string;
  objectType: NoteObjectType;
}

export const Notes = (
  {
    transition,
    paramId,
    objectType,
    closeEntity,
  }: NotesProps
) => {
  const [comments, setComments] = useState<cyrian.api.notes.Note[]>([]);
  const [value, setValue] = useState<string>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const {[paramId]: objectId} = transition.router.globals.params;

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({behavior: "smooth"});
    }
  };

  useEffect(() => {
    setLoading(true);
    api.notes
      .get({objectType, objectId, sort: 'createdAt:-1', limit: 20}).source
      .then(({data}: WrappedItemsResult<cyrian.api.notes.Note[]>) => setComments(data.items.reverse()))
      .catch(showError)
      .finally(() => {
        setLoading(false);
        scrollToBottom();
      });
  }, [objectId, objectType]);

  const handleChange = e => {
    setValue(e.target.value);
  };

  const handleSubmit = () => {
    if (!value) {
      return;
    }

    setSubmitting(true);

    api.notes.create({objectId: +objectId, objectType, text: value}).source
      .then(({data}: WrappedResult<cyrian.api.notes.Note>) => {
        setComments([...comments, data]);
        setValue('');
        scrollToBottom();
      })
      .catch(showError)
      .finally(() => setSubmitting(false));
  };

  const onClose = () => {
    transition.router.stateService.go(`base-layout.${closeEntity}`);
  };

  const title = getTranslatedString('table.notes');

  const Footer = () => <Comment
    avatar={DefaultAvatar}
    content={
      <Editor
        onChange={handleChange}
        onSubmit={handleSubmit}
        submitting={submitting}
        value={value}
      />
    }
  />;

  return (
    <Drawer
      onClose={onClose}
      visible={true}
      title={title}
      footer={Footer}
    >
      {
        loading
          ? <Loader />
          : comments.length > 0 ? <CommentList comments={comments} /> : <Empty />
      }
      <div ref={messagesEndRef} />
    </Drawer>
  );
};
