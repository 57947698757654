import { ApiResponse, WrappedItemsResult, WrappedResult } from '@/api/types';
import { request } from '@/utils/request';

export default {
  getAll(type: cyrian.api.attachments.AttachmentType, objectId: number | number[]): ApiResponse<WrappedResult<cyrian.api.attachments.Attachment[]>> {
    const query = new URLSearchParams();
    query.append('type', type);
    if (Array.isArray(objectId)) {
      for (const id of objectId) {
        query.append('objectId', id.toString());
      }
    } else {
      query.append('objectId', objectId.toString());
    }

    return request({
      method: 'get',
      url: `/attachments?${query}`,
    });
  },
  get(uuid: string) {
    return request({
      method: 'get',
      url: `/attachments/${uuid}`,
    });
  },
  attachFile(data: cyrian.api.attachments.AttachmentParams): ApiResponse<WrappedResult<cyrian.api.attachments.Attachment>> {
    return request({
      method: 'post',
      url: '/attachments',
      headers: {
        Accept: '*/*',
        'Content-Type': 'multipart/form-data',
      },
      data,
    });
  },
  attachFiles(data: cyrian.api.attachments.MultipleAttachmentParams): ApiResponse<WrappedItemsResult<cyrian.api.attachments.Attachment[]>> {
    return request({
      method: 'post',
      url: '/attachments/multiple',
      headers: {
        Accept: '*/*',
        'Content-Type': 'multipart/form-data',
      },
      data,
    });
  },
};
