import { addressStore, counterpartyStore } from '@/stores';
import { buildStoreOptions } from '@/utils/common';

export const getFormFields = () => [
  {
    name: 'addressId',
    componentName: 'dropdown',
    params: {
      i18nLabel: 'lot.weighing.address',
      options: buildStoreOptions(addressStore, 'name'),
    },
    rules: [{ required: true }],
  },
  {
    name: 'counterpartyId',
    componentName: 'dropdown',
    params: {
      i18nLabel: 'lot.weighing.counterparty',
      options: buildStoreOptions(counterpartyStore, 'name'),
    },
    rules: [{ required: true }],
  },
  {
    name: 'date',
    componentName: 'datepicker',
    params: {
      i18nLabel: 'lot.weighing.date',
      showTime: true,
    },
    rules: [{ required: true }],
  },
  {
    name: 'weight',
    componentName: 'input-number',
    params: {
      i18nLabel: 'lot.weighing.weight',
      min: 0,
    },
    rules: [{ required: true }],
  },
];
