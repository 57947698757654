import moment from 'moment';

import { Period } from '@/types/common';

export const getFormattedDate = (date: string) => date && moment(date).format('DD.MM.YYYY hh:mm:ss');

export const getFormattedDateOnly = (date: string) => date && moment(date).format('DD/MM/YYYY');

export const getFormattedRangeDate = (range: Period): string => {
  if (!range) {
    return '';
  }

  const from = moment(range.from);
  const to = moment(range.to);
  const quarter = moment(range.from).startOf('quarter').quarter();

  const yearsStr = from.year() === to.year()
    ? `${to.format('YYYY')}`
    : `${from.format('YYYY')} - ${to.format('YYYY')}`;

  let periodStr = '';

  const fromMonth = from.month();
  const toMonth = to.month();

  if (toMonth === fromMonth) {
    periodStr = `${from.format('DD MMM')} - ${to.format('DD MMM')}`;
  } else {
    periodStr = new Array(
      toMonth > fromMonth
        ? toMonth - fromMonth
        : toMonth + 12 - fromMonth,
    )
      .fill(from)
      .reduce((period, date, i) => {
        return `${period}${moment(date).add(i, 'M').format('MMMM').slice(0, 1)}`;
      }, '');
  }

  return `${quarter !== 4 ? quarter + 1 : 1}. ${periodStr} ${yearsStr}`;
};

export const getFormattedQuarterDate = (date: moment.Moment): string => {
  return getFormattedRangeDate({
    from: date.toString(),
    to: moment(date.toString()).add(3, 'M').toString(),
  });
};

export const getFromFormattedQuarterCurrentDate = (date: moment.Moment = moment()): moment.Moment => {
  const currentMonth = moment(date).month();
  const currentQuarterNumber = Math.floor(currentMonth / 3);
  const firstMonthCurrentQuarter = moment(date).month(currentQuarterNumber * 3).day(1);

  return firstMonthCurrentQuarter;
};
