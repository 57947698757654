import { UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { api } from '@/api';
import { WrappedResult } from '@/api/types';
import { getAllowedFormFields } from '@/components/generic-form/utils';
import { DrawerFormWithNote } from '@/components/note-form';
import { PERMISSION_OBJ_LOT_WEIGHING } from '@/constants/permissions';
import { addressStore, counterpartyStore, lotStore } from '@/stores';
import { NoteObjectType } from '@/types/enums';
import { getTranslatedString } from '@/utils';
import { useMassUpdateCashedStores } from '@/utils/store';

import { getFormFields } from './setup';

export const WeightForm = observer(({ transition }: UIViewInjectedProps) => {
  const { lotId } = transition.router.globals.params;
  const lot = lotStore.items.find(({ id }: any) => id === +lotId);

  const { loading } = useMassUpdateCashedStores([counterpartyStore, addressStore]);

  const resourceController = {
    create: (values: any) => api.lots.createWeighing(lotId, values).source
      .then(({ data }: WrappedResult<cyrian.api.lots.LotWeighing>) => {
        lotStore.updateItem({ ...lot, weight: data.weight });

        return data;
      }),
  };

  const onClose = () => {
    transition.router.stateService.go('base-layout.lots');
  };

  const initValues = {};
  const title = getTranslatedString('lot.weighing.title', { 0: lotId });
  const formFields = getFormFields();
  const allowedFields = getAllowedFormFields(formFields, true, PERMISSION_OBJ_LOT_WEIGHING);

  return (
    <DrawerFormWithNote
      objectType={NoteObjectType.LotWeighing}
      resourceController={resourceController}
      title={title}
      initValues={initValues}
      formFields={allowedFields}
      onClose={onClose}
      loaderCondition={loading || !lot}
    />
  );
});
