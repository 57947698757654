import { campaignStore, counterpartyStore, currencyStore, cvStore } from '@/stores';
import { buildStoreOptions } from '@/utils/common';
import { getFormattedVolume } from '@/utils/formatters';
import { getFormattedQuarterDate as format } from '@/utils/moment';
import { getParsedVolume } from '@/utils/parsers';

export const getFormFields = () => {
  return [
    {
      name: 'cvId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'cv-executions.cv-unlocking',
        options: buildStoreOptions(cvStore, 'number'),
      },
      rules: [{ required: true }],
    },
    {
      name: 'counterpartyId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'cv-executions.counterparty',
        options: buildStoreOptions(counterpartyStore, 'name'),
      },
      rules: [{ required: true }],
    },
    {
      name: 'campaignId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'cv-executions.campaign',
        options: buildStoreOptions(campaignStore, 'name'),
      },
      rules: [{ required: true }],
    },
    {
      name: 'number',
      componentName: 'input',
      params: {
        i18nLabel: 'cv-executions.cv-execution',
      },
      rules: [
        { required: true },
        { max: 50 },
      ],
    },
    {
      name: 'period',
      componentName: 'datepicker',
      params: {
        i18nLabel: 'cv-executions.execution-period',
        picker: 'quarter',
        format,
      },
      rules: [{ required: true }],
    },
    {
      name: 'volume',
      componentName: 'input-number',
      params: {
        i18nLabel: 'cv-executions.volume',
        min: 0,
        formatter: getFormattedVolume,
        parser: getParsedVolume,
      },
      rules: [{ required: true }],
    },
    {
      name: 'price',
      componentName: 'input-with-dropdown',
      params: {
        i18nLabel: 'cv-executions.price',
        min: 0,
        options: buildStoreOptions(currencyStore, 'code'),
      },
      rules: [{ required: false }],
    },
  ];
};
