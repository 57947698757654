import { request } from '@/utils/request';
import { ApiResponse, WrappedResult } from './types';

export default {
  getAll(params?: cyrian.api.contractLineItems.GetParams): ApiResponse<WrappedResult<cyrian.api.contractLineItems.LineItem[]>> {
    return request({
      method: 'get',
      url: '/contract-line-item',
      params,
    });
  },
  create(data: cyrian.api.contractLineItems.LineItemParams): ApiResponse<WrappedResult<cyrian.api.counterpartyContracts.LineItem>> {
    return request({
      method: 'post',
      url: '/contract-line-item',
      data,
    });
  },
  update(id: number, data: cyrian.api.contractLineItems.LineItemParams): ApiResponse<WrappedResult<cyrian.api.counterpartyContracts.LineItem>> {
    return request({
      method: 'patch',
      url: `/contract-line-item/${id}`,
      data,
    });
  },
  delete(id: number): ApiResponse<WrappedResult<any>> {
    return request({
      method: 'delete',
      url: `/contract-line-item/${id}`,
    });
  },
};
