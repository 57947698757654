import {
  PERMISSION_OBJ_CV,
  PERMISSION_CREATE,
  PERMISSION_READ,
  PERMISSION_UPDATE,
} from '@/constants/permissions';

import { CVAttachments } from './components/attachments';
import { CVForm } from './components/form';
import { CVs } from './components/table';

export const states = [
  {
    name: 'base-layout.cvs',
    url: '/cvs',
    component: CVs,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_CV, PERMISSION_READ],
      ],
    },
  },
  {
    name: 'base-layout.cvs.create',
    url: '/new',
    component: CVForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_CV, PERMISSION_CREATE],
      ],
    },
  },
  {
    name: 'base-layout.cvs.edit',
    url: '/:cvId/edit',
    component: CVForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_CV, PERMISSION_UPDATE],
      ],
    },
  },
  {
    name: 'base-layout.cvs.attachments',
    url: '/:cvId/attachments',
    component: CVAttachments,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_CV, PERMISSION_UPDATE],
      ],
    },
  },
];
