import { UIViewInjectedProps } from '@uirouter/react';
import React from 'react';

import { Attachments } from '@/components/attachments';
import { AttachmentType } from '@/types/enums';

export const LotAttachments = ({ transition }: UIViewInjectedProps) => {
  const params = {
    transition,
    apiEntity: 'Lot',
    paramId: 'lotId',
    attachmentType: AttachmentType.Lot,
    closeRoute: 'base-layout.lots',
  };

  return <Attachments<cyrian.api.lots.Lot & { attachments: cyrian.api.attachments.Attachment[]} > {...params} />;
};
