import { request } from '@/utils/request';
import { ApiResponse, WrappedResult } from './types';

export default {
  getAll(params?: cyrian.api.cvExecutions.GetParams): ApiResponse<WrappedResult<cyrian.api.cvExecutions.CVExecution[]>> {
    return request({
      method: 'get',
      url: '/cv-executions',
      params,
    });
  },
  get(id: number): ApiResponse<WrappedResult<cyrian.api.cvExecutions.CVExecutionWithAttachment>> {
    return request({
      method: 'get',
      url: `/cv-executions/${id}`,
    });
  },
  create(data: cyrian.api.cvExecutions.CVExecutionParams): ApiResponse<WrappedResult<cyrian.api.cvExecutions.CVExecution>> {
    return request({
      method: 'post',
      url: '/cv-executions',
      data,
    });
  },
  update(id: number, data: cyrian.api.cvExecutions.CVExecutionParams): ApiResponse<WrappedResult<cyrian.api.cvExecutions.CVExecution>> {
    return request({
      method: 'patch',
      url: `/cv-executions/${id}`,
      data,
    });
  },
  delete(id: number): ApiResponse<WrappedResult<any>> {
    return request({
      method: 'delete',
      url: `/cv-executions/${id}`,
    });
  },
};
