import { getTranslatedString } from '@/utils';
import { Dispatch, SetStateAction } from 'react';

export const getFormFields = (
  contractProductId: number,
  lots: cyrian.api.boardings.Lot[],
  targetLots: number[],
  warning: boolean,
  setTargetLots: Dispatch<SetStateAction<number[]>>,
  setWarning: Dispatch<SetStateAction<boolean>>,
) => {
  const onChange = (targetKeys: number[]) => {
    const warning = lots.filter(lot => targetKeys.includes(lot.id)).some((lot: any) => lot.product.id !== contractProductId);
    setWarning(warning);
    setTargetLots(targetKeys);
  };

  return [
    {
      name: 'lots',
      componentName: 'transfer',
      params: {
        style: { justifyContent: 'center' },
        rowKey: (({ id }) => id),
        dataSource: lots,
        titles: ['Source', 'Target'],
        targetKeys: targetLots,
        listStyle: {
          height: window.innerHeight * 0.5,
        },
        onChange,
        render: item => item.number,
      },
    },
    {
      name: 'message',
      componentName: 'labeled-span',
      params: {
        style: { padding: '0 25px' },
        value: getTranslatedString('boardings.warning-product-not-contract'),
        i18nLabel: 'boardings.warning',
      },
      conditionalRender: () => warning,
    },
  ];
};
