import { addressStore, campaignStore, counterpartyStore } from '@/stores';
import { TransferStatus } from '@/types/enums';
import { buildDropdownOptionsFromEnum, buildStoreOptions, productOptions } from '@/utils/common';
import { getTranslatedString } from '@/utils';

export const getFormFields = () => {
  return [
    {
      name: 'productId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'transfers.product',
        options: productOptions(),
      },
      rules: [{ required: true }],
    },
    {
      name: 'campaignId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'transfers.campaign',
        options: buildStoreOptions(campaignStore, 'name'),
      },
      rules: [{ required: true }],
    },
    {
      name: 'carrierId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'transfers.carrier',
        options: buildStoreOptions(counterpartyStore, 'name'),
      },
      rules: [{ required: true }],
    },
    {
      name: 'transportChargeCounterpartyId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'transfers.transport-charge-counterparty',
        options: buildStoreOptions(counterpartyStore, 'name'),
      },
      rules: [{ required: true }],
    },
    {
      name: 'loadingCounterpartyId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'transfers.loading-counterparty',
        options: buildStoreOptions(counterpartyStore, 'name'),
      },
      rules: [{ required: true }],
    },
    {
      name: 'startLocationId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'transfers.start-location',
        options: buildStoreOptions(addressStore, 'name'),
      },
      rules: [{ required: true }],
    },
    {
      name: 'endLocationId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'transfers.end-location',
        options: buildStoreOptions(addressStore, 'name'),
      },
      rules: [{ required: true }],
    },
    {
      name: 'number',
      componentName: 'input',
      params: {
        i18nLabel: 'transfers.number',
      },
      rules: [
        { required: true },
        { max: 50 },
      ],
    },
    {
      name: 'status',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'transfers.status',
        options: buildDropdownOptionsFromEnum(TransferStatus)
          .map(({ label, value }) => ({
            value,
            label: getTranslatedString(`transfers.status.${label}`),
          })),
      },
      rules: [{ required: true }],
    },
    {
      name: 'phytoPosition',
      componentName: 'switch',
      params: {
        i18nLabel: 'transfers.phyto-position',
      },
    },
    {
      name: 'phytoTreatmentDays',
      componentName: 'input-number',
      params: {
        i18nLabel: 'transfers.phyto-treatment-days',
        min: 0,
      },
      rules: [{ required: true }],
    },
    {
      name: 'phoneNumber',
      componentName: 'input',
      params: {
        i18nLabel: 'transfers.phone-number',
      },
      rules: [
        { required: true },
        { max: 50 },
      ],
    },
  ];
};
