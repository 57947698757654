import { Form, FormItemProps, Input as AntdInput } from 'antd';
import { TextAreaProps as AntdTextAreaProps } from 'antd/lib/input';
import React from 'react';

import { Ii18nLabel } from '@/types/common';

import { getFormItemLabel } from './utils';

interface TextAreaProps extends FormItemProps, AntdTextAreaProps, Ii18nLabel {
  name: string;
  children: any;
  onReset: any;
}

export const TextArea = (
  {
    name,
    label,
    i18nLabel,
    rules,
    disabled,
    ...props
  }: TextAreaProps,
) => {
  const fieldLabel = getFormItemLabel(i18nLabel, label);

  return (
    <Form.Item {...props} name={name} label={fieldLabel} rules={rules}>
      <AntdInput.TextArea disabled={disabled} />
    </Form.Item>
  );
};
