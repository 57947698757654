import { notification } from 'antd';
import React from 'react';

import { productStore } from '@/stores';
import { IKeyValueObject } from '@/types/common';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

export const showError = (err: any) => {
  notification.error({
    message: err.message,
    description: err.response?.data?.error?.message,
    duration: 3,
  });
};

export const booleanFormatted = (value: boolean) => value
  ? <CheckOutlined style={{ color: 'green' }} />
  : <CloseOutlined style={{ color: 'red' }} />;

export const productFormattedLabel = (item: cyrian.api.products.Product) => {
  return `${item.name} (${item.type})`;
};

export const buildDropdownOptionsFromEnum = (enumObject: IKeyValueObject) => {
  return Object.values(enumObject).map((item: string) => ({ value: item, label: item }));
};

export const buildFilterOptionsFromEnum = (enumObject: IKeyValueObject) => {
  return Object.values(enumObject).map((item: string) => ({ text: item, value: item }));
};

export const buildStoreOptions = (store: any, labelKey: string) => {
  return store.items.map((item: any) => ({ value: item.id, label: item[labelKey] }));
};

export const productOptions = () => {
  return productStore.items.map((item: cyrian.api.products.Product) => ({
    value: item.id,
    label: productFormattedLabel(item),
  }));
};

export function formatBytes(bytes: number, decimals?: number) {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals || 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const size = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

  return `${size} ${sizes[i]}`;
}

const getValue = (value: any) => {
  if (typeof value === 'undefined' || value === null) {
    return '';
  }
  return value;
};

export function getValuesDifference(oldValues: any, newValues: any, skipProperties: string[] = []) {
  const changedProperties = Array.from(new Set([...Object.keys(newValues)]))
    .filter((key: string) => newValues[key] !== oldValues[key])
    .filter((key: string) => !skipProperties.includes(key));

  const originalItem = changedProperties
    .reduce((acc: any, key: string) => {
      return {
        ...acc,
        [key]: getValue(oldValues[key]),
      };
    }, {});

  const changedItem = changedProperties
    .reduce((acc: any, key: string) => {
      return {
        ...acc,
        [key]: getValue(newValues[key]),
      };
    }, {});

  return {
    originalItem,
    changedProperties,
    changedItem,
  };
}
