import { request } from '@/utils/request';
import { ApiResponse, WrappedItemsResult } from './types';

export default {
  getAll(params?: cyrian.api.currencies.GetParams): ApiResponse<WrappedItemsResult<cyrian.api.currencies.Currency[]>> {
    return request({
      method: 'get',
      url: '/currencies',
      params,
    });
  },
};
