import { faArrowRight, faCalendar, faMapMarkerAlt, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, List } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { LotEventType } from '@/types/enums';
import { getFormattedDate } from '@/utils/moment';
import { getTranslatedString } from '@/utils';

export const columns = [
  {
    title: <FormattedMessage id="lots.events.id" />,
    dataIndex: 'id',
  },
  {
    title: <FormattedMessage id="lots.events.type" />,
    dataIndex: 'type',
  },
  {
    title: <FormattedMessage id="lots.events.namespace" />,
    dataIndex: 'namespace',
  },
  {
    title: <FormattedMessage id="lots.events.comment" />,
    dataIndex: 'comment',
  },
  {
    title: <FormattedMessage id="lots.events.date" />,
    dataIndex: 'createdAt',
    sorter: true,
    render: getFormattedDate,
  },
];

export const getExpandedRow = ({ metadata, type }: cyrian.api.lots.Event) => {
  const UpdateRenderItem = (fieldName: string) => (
    <List.Item title={fieldName}>
      <Col span={6}>{fieldName}:</Col>
      <Col span={6}>{metadata[fieldName].old}</Col>
      <Col span={6}><FontAwesomeIcon icon={faArrowRight} /></Col>
      <Col span={6}>{metadata[fieldName].new}</Col>
    </List.Item>
  );
  const AnalysisRenderItem = (
    <List.Item>
      <Col span={8}><FontAwesomeIcon icon={faMapMarkerAlt} /> {metadata.address}</Col>
      <Col span={8}><FontAwesomeIcon icon={faCalendar} /> {getFormattedDate(metadata.date)}</Col>
      <Col span={8}><FontAwesomeIcon icon={faUserFriends} /> {metadata.counterparty}</Col>
    </List.Item>
  );
  const WeighingRenderItem = (
    <List.Item>
      <Col span={12}><FontAwesomeIcon icon={faUserFriends} /> {metadata.counterparty}</Col>
      <Col span={12}>Weight: {metadata.weight}</Col>
    </List.Item>
  );
  const BvRenderItem = (
    <List.Item>
      <Col span={8}>{metadata.oldStatus}</Col>
      <Col span={8}><FontAwesomeIcon icon={faArrowRight} /></Col>
      <Col span={8}>{metadata.newStatus}</Col>
    </List.Item>
  );
  const FumigationRenderItem = (
    <List.Item>
      <Col span={8}><FontAwesomeIcon icon={faMapMarkerAlt} /> {metadata.address}</Col>
      <Col span={8}><FontAwesomeIcon icon={faCalendar} /> Start - {getFormattedDate(metadata.startDate)}</Col>
      <Col span={8}><FontAwesomeIcon icon={faCalendar} /> End - {getFormattedDate(metadata.endDate)}</Col>
    </List.Item>
  );
  const ProposalRenderItem = (
    <List.Item>
      {Boolean(metadata.old && metadata.old.contract)
      && <Col span={5}><FontAwesomeIcon icon={faUserFriends} /> {metadata.old?.contract}</Col>}
      {Boolean(metadata.old && metadata.old.status)
      && <Col span={5}>Status: {metadata.old?.status}</Col>}
      <Col span={4}><FontAwesomeIcon icon={faArrowRight} /></Col>
      {Boolean(metadata.new && metadata.new.contract)
      && <Col span={5}><FontAwesomeIcon icon={faUserFriends} /> {metadata.new?.contract}</Col>}
      {Boolean(metadata.new && metadata.new.status)
      && <Col span={5}>{ getTranslatedString(`proposal.status.${metadata.new?.status}`) }</Col>}
    </List.Item>
  );

  switch (type) {
    case LotEventType.Analysis: {
      return AnalysisRenderItem;
    }
    case LotEventType.Weighing: {
      return WeighingRenderItem;
    }
    case LotEventType.BV: {
      return BvRenderItem;
    }
    case LotEventType.Fumigation: {
      return FumigationRenderItem;
    }
    case LotEventType.Proposal: {
      return ProposalRenderItem;
    }
    case LotEventType.Update: {
      return <List
        dataSource={Object.keys(metadata)}
        renderItem={UpdateRenderItem}
      />;
    }
    default:
      return JSON.stringify(metadata);
  }
};
