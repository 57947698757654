import { faFlask, faPlus, faSmog, faTrash, faClipboardCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UIView, UIViewInjectedProps } from '@uirouter/react';
import { Button, Modal, Space } from 'antd';
import { TableRowSelection } from 'antd/lib/table/interface';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { api } from '@/api';
import { Table } from '@/components/table';
import { PERMISSION_CREATE, PERMISSION_OBJ_LOT, PERMISSION_DELETE } from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';
import { lotStore } from '@/stores';
import { showError } from '@/utils/common';
import { useMassUpdateCashedStores } from '@/utils/store';

import { columns } from './setup';
import { getTranslatedString } from '@/utils';

const store = lotStore;
const { confirm } = Modal;

const CreateButton = withPermissions([[PERMISSION_OBJ_LOT, PERMISSION_CREATE]])
(({ onClick, i18nTitle, disabled, icon }: any) => {
  return (
    <Button onClick={onClick} type="primary" icon={icon} disabled={disabled}>
      &nbsp;&nbsp;<FormattedMessage id={i18nTitle} />
    </Button>
  );
});

const MassDeleteButton = withPermissions([[PERMISSION_OBJ_LOT, PERMISSION_DELETE]])
(({ onClick, i18nTitle, disabled }: any) => {
  return (
    <Button onClick={onClick} type="primary" danger={true} disabled={disabled} icon={<FontAwesomeIcon icon={faTrash} />}>
      &nbsp;&nbsp;<FormattedMessage id={i18nTitle} />
    </Button>
  );
});

export const Lots = observer(({ transition }: UIViewInjectedProps) => {
  const [selectedLots, setSelectedLots] = useState<number[]>([]);
  const lots = store.items;
  useMassUpdateCashedStores([lotStore]);

  const onMassAdd = () => {
    transition.router.stateService.go('base-layout.lots.mass-create');
  };

  const onMassAddPledge = () => {
    transition.router.stateService.go('base-layout.lots.mass-create-pledge', { selectedLots });
  };

  const onMassAddFumigation = () => {
    transition.router.stateService.go('base-layout.lots.mass-create-fumigation', { selectedLots });
  };

  const onMassAddBV = () => {
    transition.router.stateService.go('base-layout.lots.mass-create-bv', { selectedLots });
  };

  const onMassDelete = () => {
    const onOk = () => api.lots.deleteMany({ ids: selectedLots }).source
      .then(() => selectedLots.forEach((id: number) => lotStore.deleteItem(id)))
      .then(() => lotStore.refresh())
      .then(() => setSelectedLots([]))
      .catch(showError);

    confirm({
      title: getTranslatedString('lots.delete-warning-title'),
      content: getTranslatedString('lots.delete-warning-desc'),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk,
    });
  };

  const onRow = (record: cyrian.api.lots.Lot) => ({
    onClick: () => transition.router.stateService.go('base-layout.lots.view', { lotId: record.id }),
    className: 'table-row-cursor',
  });

  const onRowSelectionChange = (selectedRowKeys: number[], selectedLots: cyrian.api.lots.Lot[]) => {
    setSelectedLots(selectedRowKeys);
  };

  const rowSelection: TableRowSelection<cyrian.api.lots.Lot> = {
    preserveSelectedRowKeys: true,
    selectedRowKeys: selectedLots,
    onChange: onRowSelectionChange,
  };

  return (
    <>
      <Space>
        <CreateButton onClick={onMassAdd} i18nTitle="lots.mass-add-new" icon={<FontAwesomeIcon icon={faPlus}/> } />
        <CreateButton onClick={onMassAddBV} i18nTitle="lots.mass-add-bv" disabled={!selectedLots.length} icon={<FontAwesomeIcon icon={faClipboardCheck}/> } />
        <CreateButton onClick={onMassAddFumigation} i18nTitle="lots.mass-add-fumigation" disabled={!selectedLots.length} icon={<FontAwesomeIcon icon={faSmog} />} />
        <CreateButton onClick={onMassAddPledge} i18nTitle="lots.mass-add-pledges" disabled={!selectedLots.length} icon={<FontAwesomeIcon icon={faFlask} />}/>
        <MassDeleteButton onClick={onMassDelete} i18nTitle="lots.mass-remove" disabled={!selectedLots.length} />
      </Space>
      <Table
        loading={store.loading}
        rowKey="id"
        columns={columns}
        dataSource={[...lots]} store={store}
        onRow={onRow}
        rowSelection={rowSelection}
        scroll={{ x: 1800 }}
      />
      <UIView />
    </>
  );
});
