import { UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { api } from '@/api';
import { getAllowedFormFields } from '@/components/generic-form/utils';
import { DrawerFormWithNote } from '@/components/note-form';
import { DEFAULT_CURRENCY } from '@/constants';
import { PERMISSION_OBJ_COUNTERPARTY_CONTRACT } from '@/constants/permissions';
import { counterpartyContractStore, counterpartyStore, currencyStore, productStore } from '@/stores';
import { NoteObjectType } from '@/types/enums';
import { getTranslatedString } from '@/utils';
import { withoutRepeatValue } from '@/utils/controller';
import { useMassUpdateCashedStores } from '@/utils/store';
import { buildPrice } from '@/utils/formatters';
import { getFormFields } from './setup';

const entity = 'counterparty-contracts';
const store = counterpartyContractStore;

export const ContractForm = observer(({ transition }: UIViewInjectedProps) => {
  const isCreate = transition.router.globals.current.name === `base-layout.${entity}.create`;
  const { counterpartyContractId } = transition.router.globals.params;
  const foundItem = store.items.find(({ id }: any) => id === +counterpartyContractId);

  const { loading } = useMassUpdateCashedStores([counterpartyStore, productStore, currencyStore]);

  const onClose = () => {
    transition.router.stateService.go(`base-layout.${entity}`);
  };

  const initValues = !isCreate && foundItem
    ? {
      ...foundItem,
      counterpartyId: foundItem.counterparty.id,
      productId: foundItem.product.id,
      price: foundItem.price?.amount,
      priceCurrency: foundItem.price?.currency.id,
    }
    : {};

  const title = isCreate
    ? getTranslatedString('counterparty-contracts.create-new')
    : getTranslatedString('counterparty-contracts.contract-number', { 0: counterpartyContractId });

  const formFields = getFormFields();
  const allowedFields = getAllowedFormFields(formFields, isCreate, PERMISSION_OBJ_COUNTERPARTY_CONTRACT);

  const resourceController = withoutRepeatValue(initValues, {
    create: (values: any) => {
      values.price = buildPrice(
        values.price ? values.priceCurrency : undefined,
        values.price,
        DEFAULT_CURRENCY,
        undefined,
      );

      return api.counterpartyContracts.create(values).source
      .then(({ data }: any) => store.addItem(data));
    },
    update: (values: any) => {
      values.price = buildPrice(
        values.priceCurrency,
        values.price,
        foundItem.price?.currency.id || DEFAULT_CURRENCY,
        foundItem.price?.amount,
      );

      return api.counterpartyContracts.update(foundItem.id, values).source
      .then(({ data }: any) => store.updateItem(data));
    },
  });

  return (
    <DrawerFormWithNote
      objectType={NoteObjectType.CounterpartyContract}
      resourceId={counterpartyContractId}
      title={title}
      initValues={initValues}
      formFields={allowedFields}
      onClose={onClose}
      resourceController={resourceController}
      loaderCondition={loading || (!isCreate && !foundItem)}
    />
  );
});
