import { UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { api } from '@/api';
import { PERMISSION_OBJ_BV } from '@/constants/permissions';
import { getAllowedFormFields } from '@/components/generic-form/utils';
import { DrawerForm } from '@/components/drawer-form';
import { lotStore } from '@/stores';
import { getTranslatedString } from '@/utils';
import { useMassUpdateCashedStores } from '@/utils/store';
import { showError } from '@/utils/common';
import { BVStatus } from '@/types/enums';
import { getFormFields } from './setup';

export const BVForm = observer(({ transition }: UIViewInjectedProps) => {
  const { lotId } = transition.router.globals.params;
  const { selectedLots = [] } = transition.targetState().params();

  const resourceController = {
    create: async (values: any) => {
      const bvRequestData = {
        number: values.number,
        status: BVStatus.BVRequestMade,
      };

      try {
        const result = await api.bvs.create(bvRequestData).source;
        const bvId = result?.data?.id;

        if (selectedLots.length) {
          const updateResult = await api.lots.updateMany({ items: selectedLots.map(id => ({ id, bvId })) }).source;
          for (const item of (updateResult?.data?.items ?? [])) {
            lotStore.updateItem(item);
          }
        } else {
          console.log(lotId)
          const updateResult = await api.lots.update(lotId, { bvId }).source;
          lotStore.updateItem(updateResult?.data);
        }
      } catch(err) {
        showError(err);
      }
    },
  };

  const onClose = () => {
    transition.router.stateService.go('base-layout.lots');
  };

  const initValues = {};
  const title = getTranslatedString('lot.pledge.title', { 0: selectedLots.join(', ') || lotId });
  const formFields = getFormFields();
  const allowedFields = getAllowedFormFields(formFields, true, PERMISSION_OBJ_BV);

  return (
    <DrawerForm
      title={title}
      initValues={initValues}
      formFields={allowedFields}
      onClose={onClose}
      resourceController={resourceController}
      loaderCondition={false}
    />
  );
});
