import { faLock, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UISref } from '@uirouter/react';
import { Button, Divider, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';

import { samlProviders } from '@/api/auth';
import { AUTHORIZED_USER_INITIAL_STATE } from '@/constants';
import { authStore } from '@/stores';

import { getResponseError } from '../handlers';
import { AuthLayout } from './AuthLayout';

const Login = ({ onSuccessLogin }: any) => {
  const [state, setState] = useState({
    email: '',
    password: '',
    error: null,
  });
  const [providers, setProviders] = useState<string[]>([]);

  useEffect(() => {
    const load = async () => {
      const result = await samlProviders();
      setProviders(result);
    };
    load();
  }, []);

  const setStateValue = (key: string, value: string) => {
    setState(prev => ({ ...prev, [key]: value }));
  };

  const onSignInClick = async () => {
    try {
      await authStore.loginWithEmail(state.email, state.password);
      onSuccessLogin();
    } catch (err) {
      setStateValue('error', getResponseError(err));
    }
  };

  const onSignInWithGoogleClick = async (provider: string) => {
    await authStore.loginWithSaml(provider);
    onSuccessLogin();
  };
  const onEmailChange = event => setStateValue('email', event.target.value);
  const onPasswordChange = event => setStateValue('password', event.target.value);

  return (
    <AuthLayout>
      <Form
        name="login"
        requiredMark="optional"
      >
        <Form.Item
          name="email"
          rules={[{ required: true, message: 'Please input Email!' }]}
        >
          <Input
            prefix={<FontAwesomeIcon icon={faUser} className="site-form-item-icon" />}
            name="email"
            type="email"
            id="email"
            value={state.email}
            onChange={onEmailChange}
          />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input password!' }]}
        >
          <Input.Password
            prefix={<FontAwesomeIcon icon={faLock} className="site-form-item-icon" />}
            name="password"
            type="password"
            id="password"
            value={state.password}
            onChange={onPasswordChange}
          />
        </Form.Item>

        {
          state && state.error
            ? <Form.Item>
              <div className="auth-error-text">
                {state.error}
              </div>
            </Form.Item>
            : null
        }

        <Form.Item>
          <Button className="auth-button" onClick={onSignInClick} type="primary" htmlType="submit">
            Sign In
          </Button>
        </Form.Item>

        <Divider plain={true}>or</Divider>
        <Form.Item>
          {
            providers.map((provider: string) => {
              const onClick = () => onSignInWithGoogleClick(provider);

              return (
                <Button
                  style={{ marginBottom: '1rem' }}
                  key={provider}
                  className="auth-button"
                  htmlType="button"
                  icon={<span className="fa-google-wrapper"/>}
                  onClick={onClick}
                >
                  Sign In ({ provider })
                </Button>
              );
            })
          }
          <div className="auth-link">
            <UISref to={'auth-signup'}>
              <a>Register</a>
            </UISref>
          </div>
          <div className="auth-link auth-link-separator">&#124;</div>
          <div className="auth-link">
            <UISref to={'auth-login'}>
              <a>Simple Login</a>
            </UISref>
          </div>
        </Form.Item>
      </Form>
    </AuthLayout>
  );
};

export const AuthLoginForm = (props: any) => {
  const onSuccessLogin = () => props.transition.router.stateService.go(AUTHORIZED_USER_INITIAL_STATE);
  return (<Login onSuccessLogin={onSuccessLogin} />);
};
