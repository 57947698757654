import { request } from '@/utils/request';
import { ApiResponse, WrappedResult, WrappedItemsResult } from './types';

export default {
  getAll(params?: cyrian.api.formulas.GetParams): ApiResponse<WrappedResult<cyrian.api.formulas.Formula[]>> {
    return request({
      method: 'get',
      url: '/formulas',
      params,
    });
  },
  create(data: cyrian.api.formulas.FormulaParams): ApiResponse<WrappedResult<cyrian.api.formulas.Formula>> {
    return request({
      method: 'post',
      url: '/formulas',
      data,
    });
  },
  update(id: number, data: cyrian.api.formulas.FormulaParams): ApiResponse<WrappedResult<cyrian.api.formulas.Formula>> {
    return request({
      method: 'patch',
      url: `formulas/${id}`,
      data,
    });
  },
  delete(id: number): ApiResponse<WrappedResult<any>> {
    return request({
      method: 'delete',
      url: `/formulas/${id}`,
    });
  },
  getAvailableLots(id: number): ApiResponse<WrappedItemsResult<cyrian.api.formulas.Lot[]>> {
    return request({
      method: 'get',
      url: `/formulas/${id}/available-lots`,
    });
  },
  addLots(id: number, data: cyrian.api.formulas.LotsParam): ApiResponse<WrappedResult<cyrian.api.formulas.Formula>> {
    return request({
      method: 'post',
      url: `/formulas/${id}/add-lots`,
      data,
    });
  },
  removeLots(id: number, data: cyrian.api.formulas.LotsParam): ApiResponse<WrappedResult<cyrian.api.formulas.Formula>> {
    return request({
      method: 'post',
      url: `/formulas/${id}/remove-lots`,
    });
  },
  getAvailableContracts(id: number): ApiResponse<WrappedItemsResult<cyrian.api.formulas.Contract[]>> {
    return request({
      method: 'get',
      url: `/formulas/${id}/available-contract`,
    });
  },
  addContracts(id: number, data: cyrian.api.formulas.ContractsParam): ApiResponse<WrappedResult<cyrian.api.formulas.Formula>> {
    return request({
      method: 'post',
      url: `/formulas/${id}/add-contract`,
      data,
    });
  },
  removeContracts(id: number, data: cyrian.api.formulas.ContractsParam): ApiResponse<WrappedResult<cyrian.api.formulas.Formula>> {
    return request({
      method: 'post',
      url: `/formulas/${id}/remove-contract`,
    });
  },
  createPayment(id: number, data: cyrian.api.formulas.Payment): ApiResponse<WrappedResult<cyrian.api.formulas.Payment>> {
    return request({
      method: 'post',
      url: `formulas/${id}/payments`,
      data,
    });
  },
  updatePayment(id: number, paymentId: number, data: cyrian.api.formulas.Payment): ApiResponse<WrappedResult<cyrian.api.formulas.Payment>> {
    return request({
      method: 'patch',
      url: `formulas/${id}/payments/${paymentId}`,
      data,
    });
  },
  deletePayment(id: number, paymentId: number): ApiResponse<WrappedResult<any>> {
    return request({
      method: 'delete',
      url: `/formulas/${id}/payments/${paymentId}`,
    });
  },
  createTax(id: number, data: cyrian.api.formulas.TaxParams): ApiResponse<WrappedResult<cyrian.api.formulas.Tax>> {
    return request({
      method: 'post',
      url: `formulas/${id}/taxes`,
      data,
    });
  },
  updateTax(id: number, taxId: number, data: cyrian.api.formulas.TaxParams): ApiResponse<WrappedResult<cyrian.api.formulas.Tax>> {
    return request({
      method: 'patch',
      url: `formulas/${id}/taxes/${taxId}`,
      data,
    });
  },
  deleteTax(id: number, taxId: number): ApiResponse<WrappedResult<any>> {
    return request({
      method: 'delete',
      url: `/formulas/${id}/taxes/${taxId}`,
    });
  },
};
