import { UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';

import { api } from '@/api';
import { getAllowedFormFields } from '@/components/generic-form/utils';
import { DrawerFormWithNote } from '@/components/note-form';
import { PERMISSION_OBJ_BV } from '@/constants/permissions';
import { bvStore } from '@/stores';
import { NoteObjectType } from '@/types/enums';
import { getTranslatedString } from '@/utils';
import { withoutRepeatValue } from '@/utils/controller';

import { getFormFields } from './setup';

export const BVForm = observer(({ transition }: UIViewInjectedProps) => {
  const isCreate = transition.router.globals.current.name === 'base-layout.bvs.create';
  const { bvId } = transition.router.globals.params;
  const bv = bvStore.items.find(({ id }: any) => id === +bvId);

  const onClose = () => {
    transition.router.stateService.go('base-layout.bvs');
  };

  const initValues = !isCreate && bv
    ? {
      ...bv,
      acceptanceDate: bv.acceptanceDate && moment(bv.acceptanceDate),
      expirationDate: bv.expirationDate && moment(bv.expirationDate),
    }
    : {};

  const title = isCreate
    ? getTranslatedString('bvs.create-new')
    : getTranslatedString('bvs.bv-number', { 0: bvId });

  const formFields = getFormFields();
  const allowedFields = getAllowedFormFields(formFields, isCreate, PERMISSION_OBJ_BV);

  const getNormalizedValues = (values: any) => {
    return {
      ...values,
      ...(values.hasOwnProperty('acceptanceDate') && { acceptanceDate: values.acceptanceDate.format() }),
      ...(values.hasOwnProperty('expirationDate') && { expirationDate: values.expirationDate.format() }),
    };
  };

  const resourceController = withoutRepeatValue(initValues, {
    create: (values: any) => api.bvs.create(getNormalizedValues(values)).source
      .then(({ data }: any) => bvStore.addItem(data)),
    update: (values: any) => api.bvs.update(bv.id, getNormalizedValues(values)).source
      .then(({ data }: any) => bvStore.updateItem(data)),
  });

  return (
    <DrawerFormWithNote
      objectType={NoteObjectType.BV}
      resourceId={bvId}
      title={title}
      initValues={initValues}
      formFields={allowedFields}
      onClose={onClose}
      resourceController={resourceController}
      loaderCondition={!isCreate && !bv}
    />
  );
});
