import { UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';

import { api } from '@/api';
import { getAllowedFormFields } from '@/components/generic-form/utils';
import { DrawerFormWithNote } from '@/components/note-form';
import { DEFAULT_CURRENCY } from '@/constants';
import { PERMISSION_OBJ_CV_EXECUTION } from '@/constants/permissions';
import {
  campaignStore,
  counterpartyStore,
  currencyStore,
  cvExecutionStore,
  cvStore,
} from '@/stores';
import { NoteObjectType } from '@/types/enums';
import { getTranslatedString } from '@/utils';
import { withoutRepeatValue } from '@/utils/controller';
import { useMassUpdateCashedStores } from '@/utils/store';
import { buildPrice } from '@/utils/formatters';

import { normalizedData } from '../../../cvs/components/utils';
import { getFormFields } from './setup';

const entity = 'cv-executions';
const store = cvExecutionStore;

export const CVExecutionForm = observer(({ transition }: UIViewInjectedProps) => {
  const isCreate = transition.router.globals.current.name === `base-layout.${entity}.create`;
  const { cvExecutionId: itemId } = transition.router.globals.params;
  const foundItem = store.items.find(({ id }: any) => id === +itemId);

  const { loading } = useMassUpdateCashedStores([
    campaignStore,
    counterpartyStore,
    currencyStore,
    cvStore,
  ]);

  const onClose = () => {
    transition.router.stateService.go(`base-layout.${entity}`);
  };

  const initValues = !isCreate && foundItem
    ? {
      ...foundItem,
      cvId: foundItem.cv?.id, 
      campaignId: foundItem.campaign?.id,
      counterpartyId: foundItem.counterparty?.id,
      period: foundItem.period && moment(foundItem.period.from),
      price: foundItem.price?.amount,
      priceCurrency: foundItem.price?.currency.id || DEFAULT_CURRENCY,
    }
    : {};

  const title = isCreate
    ? getTranslatedString('cv-executions.create')
    : getTranslatedString('cv-executions.edit-number', { 0: itemId });

  const formFields = getFormFields();
  const allowedFields = getAllowedFormFields(formFields, isCreate, PERMISSION_OBJ_CV_EXECUTION);

  const resourceController = withoutRepeatValue(initValues, {
    create: (values: any) => {
      values.price = buildPrice(
        values.price ? values.priceCurrency : undefined,
        values.price,
        DEFAULT_CURRENCY,
        undefined,
      );

      return api.cvExecutions.create(normalizedData(values)).source
      .then(({ data }: any) => store.addItem(data));
    },
    update: (values: any) => {
      values.price = buildPrice(
        values.priceCurrency,
        values.price,
        foundItem.price?.currency.id || DEFAULT_CURRENCY,
        foundItem.price?.amount,
      );

      return api.cvExecutions.update(foundItem.id, normalizedData(values)).source
      .then(({ data }: any) => store.updateItem(data));
    },
  });

  return (
    <DrawerFormWithNote
      objectType={NoteObjectType.CVExecution}
      resourceId={itemId}
      title={title}
      initValues={initValues}
      formFields={allowedFields}
      onClose={onClose}
      resourceController={resourceController}
      loaderCondition={loading || (!isCreate && !foundItem)}
    />
  );
});
