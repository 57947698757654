import { UIViewInjectedProps, UIView } from '@uirouter/react';
import { Tabs } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { Drawer } from '@/components/drawer';
import { lotStore } from '@/stores';

import { Details } from './details';
import { Analyses } from './analyses';
import { Weighings } from './weighings';
import { Fumigations } from './fumigations';
import { Pledges } from './pledges';

const MAX_WIDTH = 1300;
const { TabPane } = Tabs;

export const LotView = observer(({ transition }: UIViewInjectedProps) => {
  const lotId = +transition.router.globals.params?.lotId;
  const lot = lotStore.items.find(({ id }: any) => id === lotId);
  const title = `Lot #${lot?.number ?? ''}`;

  const onClose = () => {
    transition.router.stateService.go('base-layout.lots');
  };

  const width = window.innerWidth - 250 > MAX_WIDTH ? MAX_WIDTH : window.innerWidth - 250;

  return (
    <>
      <Drawer onClose={onClose} visible={true} title={title} width={width}>
        <Tabs defaultActiveKey="details">
          <TabPane tab={'Details'} key="details">
            <Details lot={lot} />
          </TabPane>
          <TabPane tab={'Analyses'} key="analyses">
            <Analyses lotId={lotId} />
          </TabPane>
          <TabPane tab={'Weighing'} key="weighing">
            <Weighings lotId={lotId} />
          </TabPane>
          <TabPane tab={'Fumigation'} key="fumigation">
            <Fumigations lotId={lotId} />
          </TabPane>
          <TabPane tab={'Pledges'} key="pledges">
            <Pledges lotId={lotId} />
          </TabPane>
        </Tabs>
      </Drawer>
      <UIView />
    </>
  );
});
