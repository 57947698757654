import { UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { api } from '@/api';
import { getAllowedFormFields } from '@/components/generic-form/utils';
import { DrawerFormWithNote } from '@/components/note-form';
import { PERMISSION_OBJ_LOT_ANALYSIS } from '@/constants/permissions';
import { addressStore, counterpartyStore, lotStore } from '@/stores';
import { NoteObjectType } from '@/types/enums';
import { getTranslatedString } from '@/utils';
import { useMassUpdateCashedStores } from '@/utils/store';

import { getFormFields } from './setup';

export const AnalysisForm = observer(({ transition }: UIViewInjectedProps) => {
  const { lotId } = transition.router.globals.params;
  const lot = lotStore.items.find(({ id }: any) => id === +lotId);

  const { loading } = useMassUpdateCashedStores([counterpartyStore, addressStore]);

  const resourceController = {
    create: (values: any) => api.lots.createAnalysis(lotId, values).source
      .then(({ data }: any) => data),
  };

  const onClose = () => {
    transition.router.stateService.go('base-layout.lots');
  };

  const initValues = {};
  const title = getTranslatedString('lot.analysis.title', { 0: lotId });
  const formFields = getFormFields();
  const allowedFields = getAllowedFormFields(formFields, true, PERMISSION_OBJ_LOT_ANALYSIS);

  const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 },
    tailLayout: { wrapperCol: { offset: 10, span: 14 } },
  };

  return (
    <DrawerFormWithNote
      {...layout}
      objectType={NoteObjectType.LotAnalysis}
      title={title}
      initValues={initValues}
      formFields={allowedFields}
      onClose={onClose}
      resourceController={resourceController}
      loaderCondition={loading || !lot}
    />
  );
});
