import { buildStoreOptions } from "@/utils/common";
import { counterpartyStore, currencyStore } from '@/stores';
import { CounterpartyType } from '@/types/enums';

const bankOptions = (labelKey: string) => {
  return counterpartyStore.items
    .filter(({ type }: cyrian.api.counterparties.Counterparty) => type === CounterpartyType.Bank)
    .map((item: any) => ({ value: item.id, label: item[labelKey] }));
};

export const getFormFields = () => {
  return [
    {
      name: 'bankId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'formula.taxes.bank',
        options: bankOptions('name')
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'price',
      componentName: 'input-with-dropdown',
      params: {
        i18nLabel: 'formula.taxes.price',
        options: buildStoreOptions(currencyStore, 'code'),
      },
      rules: [
        { required: true }
      ],
    },
    {
      name: 'name',
      componentName: 'input',
      params: {
        i18nLabel: 'formula.taxes.name',
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'check',
      componentName: 'input',
      params: {
        i18nLabel: 'formula.taxes.check',
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'rate',
      componentName: 'input-number',
      params: {
        i18nLabel: 'formula.taxes.rate',
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'date',
      componentName: 'datepicker',
      params: {
        i18nLabel: 'formula.taxes.date',
        showTime: true,
      },
      rules: [
        { required: true },
      ],
    },
  ];
};
