import { Button, Col, InputNumber, Row, notification } from 'antd';
import { observer } from 'mobx-react-lite';

import { withStores } from '@/components/stores-provider';
import { settingsStore } from '@/stores';
import { getTranslatedString } from '@/utils';
import { api } from '@/api';
import { useRef } from 'react';

export const UserSettings = withStores('settingsStore')(
  observer((
    {
      settingsStore: {
        itemsPerPage,
      },
    },
  ) => {
    const itemsPerPageInput = useRef(null);

    const onClick = () => {
      settingsStore.changeItemsPerPage(itemsPerPageInput.current.value);
    };

    const onDownloadClick = () => {
      api.dump.getAll().source
        .then(data => {
          const name = 'dump.zip';
          const url = window.URL.createObjectURL(new File([data], name));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', name);
          link.click();
        })
        .catch((err: Error) => notification.error({ message: err.message, duration: 3 }));
    };

    return (
      <>
        <Row align="middle">
          <Col flex="0 0 100px">{getTranslatedString('user-settings.items-per-page')}:</Col>
          <Col flex="0 0 190px">
            <InputNumber ref={itemsPerPageInput} defaultValue={itemsPerPage} name="itemsPerPage" className="user-settings-input" min={1} />
          </Col>
          <Col flex="0 0 90px">
            <Button onClick={onClick} type="primary">Save</Button>
          </Col>
        </Row>
        <Row align="middle">
          <Col flex="0 0 100px">{getTranslatedString('user-settings.dump-data')}:</Col>
          <Col flex="0 0 90px">
            <Button onClick={onDownloadClick} type="primary">Download</Button>
          </Col>
        </Row>
      </>
    );
  },
));
