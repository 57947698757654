import { AddressForm } from './components/form';
import { Addresses } from './components/table';

import {
  PERMISSION_CREATE,
  PERMISSION_OBJ_ADDRESS,
  PERMISSION_READ,
  PERMISSION_UPDATE,
} from '@/constants/permissions';

export const states = [
  {
    name: 'base-layout.addresses',
    url: '/addresses',
    component: Addresses,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_ADDRESS, PERMISSION_READ],
      ],
    },
  },
  {
    name: 'base-layout.addresses.create',
    url: '/new',
    component: AddressForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_ADDRESS, PERMISSION_CREATE],
      ],
    },
  },
  {
    name: 'base-layout.addresses.edit',
    url: '/:addressId/edit',
    component: AddressForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_ADDRESS, PERMISSION_UPDATE],
      ],
    },
  },
];
