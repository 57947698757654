import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { notification, Col, List } from 'antd';
import { ListSize } from 'antd/lib/list';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { api } from '@/api';
import { WrappedResult } from '@/api/types';
import { formatBytes } from '@/utils/common';
import { getFormattedDate } from '@/utils/moment';

interface AttachedFilesProps {
  attachments: cyrian.api.attachments.Attachment[];
  listSize?: ListSize;
}

export const AttachedFiles = ({ attachments, listSize = 'default' }: AttachedFilesProps) => {
  const onDownload = (uuid: string) => {
    api.attachments.get(uuid).source
      .then(({ data }: WrappedResult<{ url: string }>) => {
        window.open(data.url);
      })
      .catch((err: Error) => notification.error({ message: err.message, duration: 3 }));
  };

  const ListHeader = <List.Item>
    <Col span={11}><FormattedMessage id="attachments.name" /></Col>
    <Col span={9}><FormattedMessage id="attachments.created" /></Col>
    <Col span={4}><FormattedMessage id="attachments.Size" /></Col>
  </List.Item>;

  const RenderItem = ({ name, key, size, createdAt }: cyrian.api.attachments.Attachment) => (
    <List.Item>
      <Col span={11}>
        <a onClick={() => onDownload(key)}><FontAwesomeIcon icon={faPaperclip} />{name}</a>
      </Col>
      <Col span={9}>
        {getFormattedDate(createdAt)}
      </Col>
      <Col span={4}>
        {formatBytes(size)}
      </Col>
    </List.Item>
  );

  return (
    <List
      size={listSize}
      header={ListHeader}
      style={{ marginTop: 10 }}
      dataSource={attachments}
      renderItem={RenderItem}
    />
  );
};
