import { action, makeAutoObservable, observable } from 'mobx';

import { api } from '@/api';

import { DEFAULT_ITEMS_PER_PAGE } from '@/constants';

export class SettingsStore {
  @observable itemsPerPage: number = DEFAULT_ITEMS_PER_PAGE;

  constructor() {
    makeAutoObservable(this);
  }

  @action setSettings = (settings) => {
    this.itemsPerPage = settings.items_per_page;
  }

  loadSettings = async () => {
    await api.settings.get().source
    .then(({ data }) => this.setSettings(data))
    .catch();
  }

  changeItemsPerPage(itemsPerPage) {
    api.settings.set({
      key: 'items_per_page',
      value: itemsPerPage,
    }).source.then((resp) => {
      if (resp.success) {
        this.setSettings({ items_per_page: itemsPerPage });
      }
    });
  }
}
