import { states as layoutStates } from './components/layout';
import { states as addressStates } from './pages/addresses';
import { states as adminStates } from './pages/admin';
import { states as authStates } from './pages/auth';
import { states as boardingStates } from './pages/boardings';
import { states as bvsStates } from './pages/bvs';
import { states as campaignsStates } from './pages/campaigns';
import { states as commonStates } from './pages/common';
import { states as containerStates } from './pages/containers';
import { states as counterpartiesStates } from './pages/counterparties';
import { states as counterpartyContractsStates } from './pages/counterparty-contracts';
import { states as contractLineItemsStates } from './pages/contract-line-items';
import { states as cvExecutionsStates } from './pages/cv-executions';
import { states as cvsStates } from './pages/cvs';
import { states as homeStates } from './pages/home';
import { states as lotsStates } from './pages/lots';
import { states as productsStates } from './pages/products';
import { states as profileStates } from './pages/profile';
import { states as proposalStates } from './pages/proposal';
import { states as transfersStates } from './pages/transfers';
import { states as transitOrdersStates } from './pages/transit-orders';
import { states as formulasStates } from './pages/formulas';

export const states = [
  ...layoutStates,
  ...adminStates,
  ...authStates,
  ...homeStates,
  ...profileStates,
  ...lotsStates,
  ...bvsStates,
  ...campaignsStates,
  ...counterpartiesStates,
  ...cvExecutionsStates,
  ...cvsStates,
  ...productsStates,
  ...addressStates,
  ...counterpartyContractsStates,
  ...contractLineItemsStates,
  ...commonStates,
  ...proposalStates,
  ...boardingStates,
  ...transitOrdersStates,
  ...containerStates,
  ...transfersStates,
  ...formulasStates,
];
