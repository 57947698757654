import { Space } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { api } from '@/api';
import {
  AddAction,
  AttachmentAction,
  DeleteAction,
  EditAction,
} from '@/components/table/actions';
import { ProductIcon } from '@/components/icons';
import { getColumnSearchFields } from '@/components/table/filters';
import {
  PERMISSION_DELETE,
  PERMISSION_OBJ_CONTRACT_LINE_ITEM,
  PERMISSION_UPDATE,
  PERMISSION_CREATE,
} from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';
import { contractLineItemStore } from '@/stores';
import { productFormattedLabel, showError } from '@/utils/common';
import { getFormattedPriceWithCurrency, getFormattedVolume } from '@/utils/formatters';
import { getFormattedDate, getFormattedRangeDate } from '@/utils/moment';

const PermissionedAddAction = withPermissions([[PERMISSION_OBJ_CONTRACT_LINE_ITEM, PERMISSION_CREATE]])(AddAction);
const PermissionedEditAction = withPermissions([[PERMISSION_OBJ_CONTRACT_LINE_ITEM, PERMISSION_UPDATE]])(EditAction);
const PermissionedAttachmentAction = withPermissions([[PERMISSION_OBJ_CONTRACT_LINE_ITEM, PERMISSION_UPDATE]])(AttachmentAction);
const PermissionedDeleteAction = withPermissions([[PERMISSION_OBJ_CONTRACT_LINE_ITEM, PERMISSION_DELETE]])(DeleteAction);

const Actions = (_: any, record: any) => {
  const onDelete = () => {
    api.contractLineItems.delete(record.id).source
      .then(() => contractLineItemStore.deleteItem(record.id))
      .catch(showError);
  };

  return (
    <Space>
      <PermissionedEditAction
        uiSref={{
          to: 'base-layout.contract-line-items.edit',
          params: { lineItemId: record.id },
        }}
      />
      <PermissionedAttachmentAction uiSref={{ to: 'base-layout.contract-line-items.attachments', params: { lineItemId: record.id } }} />
      <PermissionedDeleteAction onConfirm={onDelete} />
    </Space>
  );
};

export const columns = [
  {
    dataIndex: ['product', 'type'],
    sorter: false,
    width: 50,
    render: (_, record) => <ProductIcon type={record.product?.type} />,
  },
  {
    title: <FormattedMessage id="contract-line-items.client" />,
    dataIndex: ['contract', 'counterparty', 'name'],
    sorter: true,
  },
  {
    title: <FormattedMessage id="contract-line-items.exporter" />,
    dataIndex: ['exporter', 'name'],
    sorter: true,
  },
  {
    title: <FormattedMessage id="contract-line-items.final-contract" />,
    dataIndex: ['contract', 'clientContract'],
    sorter: true,
  },
  {
    title: <FormattedMessage id="contract-line-items.volume" />,
    dataIndex: 'volume',
    render: getFormattedVolume,
    sorter: true,
  },
  {
    title: <FormattedMessage id="contract-line-items.execution-period" />,
    dataIndex: 'contractPeriod',
    width: 150,
    render: getFormattedRangeDate,
  },
  {
    title: <FormattedMessage id="contract-line-items.boarding-period" />,
    dataIndex: 'boardingPeriod',
    width: 150,
    render: getFormattedRangeDate,
  },
  {
    title: <FormattedMessage id="contract-line-items.differential" />,
    dataIndex: 'differential',
    sorter: true,
  },
  {
    title: <FormattedMessage id="contract-line-items.material-fixing-date" />,
    dataIndex: 'materialFixingDate',
    render: getFormattedDate,
  },
  {
    title: <FormattedMessage id="contract-line-items.fixing-price-gbp" />,
    dataIndex: 'fixingPrice',
    render: getFormattedPriceWithCurrency,
  },
  {
    title: <FormattedMessage id="contract-line-items.currency-fixing-date" />,
    dataIndex: 'currencyFixingDate',
    render: getFormattedDate,
  },
  {
    title: <FormattedMessage id="contract-line-items.total-fob-price" />,
    dataIndex: 'totalFobPrice',
    render: getFormattedPriceWithCurrency,
  },
  {
    title: <FormattedMessage id="contract-line-items.price-quality" />,
    dataIndex: 'qualityPrice',
    render: getFormattedPriceWithCurrency,
  },
  {
    title: <FormattedMessage id="contract-line-items.cyrian-basic-price" />,
    dataIndex: 'cyrianBasicPrice',
    render: getFormattedPriceWithCurrency,
  },
  {
    title: <FormattedMessage id="contract-line-items.cyrian-discount" />,
    dataIndex: 'cyrianDiscount',
    render: getFormattedPriceWithCurrency,
  },
  {
    title: <FormattedMessage id="contract-line-items.graining" />,
    dataIndex: 'graining',
  },
  {
    title: <FormattedMessage id="contract-line-items.signature" />,
    dataIndex: 'signature',
  },
  {
    title: "",
    render: Actions,
    className: "actions",
  },
];
