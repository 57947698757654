import { blue } from '@ant-design/colors';
import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from 'antd';
import React from 'react';

export const getColumnSearchFields = () => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
    const onClear = () => {
      setSelectedKeys([]);
      confirm();
    };

    const suffix = selectedKeys[0]
      ? <FontAwesomeIcon icon={faTimesCircle} className="table-search-clear-btn" onClick={onClear}/>
      : <span />;

    return (
      <div style={{ padding: 8 }}>
        <Input.Search
          autoFocus={true}
          placeholder="Search..."
          style={{ width: 200 }}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={confirm}
          onSearch={confirm}
          suffix={suffix}
        />
      </div>
    );
  },
  filterIcon: filtered => <FontAwesomeIcon icon={faSearch} style={{ color: filtered ? blue.primary : undefined, marginTop: 15 }}/>,
});
