import { FormattedMessage } from 'react-intl';
import { Space } from 'antd';

import { api } from '@/api';
import { AttachmentAction, DeleteAction } from '@/components/table/actions';
import {
  PERMISSION_DELETE,
  PERMISSION_OBJ_LOT_PLEDGE,
  PERMISSION_UPDATE,
} from '@/constants/permissions';
import { lotPledgeStore } from '@/stores';
import { showError } from '@/utils/common';
import { withPermissions } from '@/hocs/permission';

const PermissionedDeleteAction = withPermissions([[PERMISSION_OBJ_LOT_PLEDGE, PERMISSION_DELETE]])(DeleteAction);
const PermissionedAttachmentAction = withPermissions([[PERMISSION_OBJ_LOT_PLEDGE, PERMISSION_UPDATE]])(AttachmentAction);

const Actions = ({ lotId, pledgeId }: { lotId: number; pledgeId: number }) => {
  const onDelete = () => {
    api.lots.deletePledge(lotId, pledgeId).source
      .then(() => lotPledgeStore.deleteItem(pledgeId))
      .catch(showError);
  };

  return (
    <Space>
      <PermissionedAttachmentAction uiSref={{ to: 'base-layout.lots.view.pledge-attachments', params: { lotId, pledgeId } }} />
      <PermissionedDeleteAction onConfirm={onDelete} />
    </Space>
  );
};

export const getColumns = (lotId: number) => {
  return [
    {
      title: 'id',
      dataIndex: 'id',
      sorter: true,
    },
    {
      title: <FormattedMessage id="lot.pledge.counterparty" />,
      dataIndex: ['counterparty', 'name'],
      sorter: true,
    },
    {
      title: <FormattedMessage id="lot.pledge.request-number" />,
      dataIndex: 'requestNumber',
      sorter: true,
    },
    {
      title: <FormattedMessage id="lot.pledge.ltd-number" />,
      dataIndex: 'ltdNumber',
      sorter: true,
    },
    {
      title: <FormattedMessage id="lot.pledge.das-dae" />,
      dataIndex: 'dasDae',
      sorter: true,
    },
    {
      title: '',
      render: (_, record: any) => <Actions lotId={lotId} pledgeId={record.id} />,
      className: 'actions',
    },
  ];
}
