import Text from 'antd/lib/typography/Text';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { api } from '@/api';
import { AttachedFiles } from '@/components/attachments/attached-files';
import { showError } from '@/utils/common';

function loadAttachments(
  apiGroup: string,
  id: number | undefined,
  type: cyrian.api.attachments.AttachmentType,
): Promise<cyrian.api.attachments.Attachment> {
  return id && api[apiGroup] && api[apiGroup].get
    ? api[apiGroup].get(id)
        .source
        .then(({ data }) => api.attachments.getAll(type, data?.id).source)
        .catch(showError)
    : Promise.resolve({ data: { count: 0, items: [] } });
}

function loadLotRelAttachments(lotId: number, apiMethod: string, type: string) {
  if (!api.lots[apiMethod]) {
    return Promise.resolve({ data: { count: 0, items: [] } });
  }
  return api.lots[apiMethod](lotId).source
    .then(data => (data?.items ?? []).map(({ id }) => id))
    .then(ids => api.attachments.getAll(type, ids).source)
    .catch(showError)
}

export const Attachments = ({ lot }: { lot: cyrian.api.lots.Lot }) => {
  const [attachments, setAttachments] = useState<cyrian.api.attachments.Attachment[]>([]);

  useEffect(() => {
    if (!lot) {
      return;
    }

    Promise.all([
      loadAttachments('lots', lot.id, 'Lot'),
      loadLotRelAttachments(lot.id, 'getAnalyses', 'LotAnalysis'),
      loadLotRelAttachments(lot.id, 'getFumigations', 'LotFumigation'),
      loadLotRelAttachments(lot.id, 'getPledges', 'LotPledge'),
      loadLotRelAttachments(lot.id, 'getWeighings', 'LotWeighing'),
      loadAttachments('cvs', lot.executionCv?.cv?.id, 'CV'),
      loadAttachments('bvs', lot.bv?.id, 'BV'),
    ])
      .then((data: any) => {
        const attachments = data.map(item => item?.data?.items ?? []).flatMap(x => x);
        setAttachments(attachments);
      });
  }, [lot]);

  return (
    <>
      <Text strong={true}><FormattedMessage id="attachments.attachments" /></Text>
      {Boolean(attachments.length)
      && <AttachedFiles attachments={attachments} listSize="small" />}
    </>
  );
};
