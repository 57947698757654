import { LocalStorageTokenProvider } from '@/utils/access-token-provider';

import { api } from '@/api';
import { AuthStore } from './auth';
import { CachedStorage, CollectionStore, DummyDataLoader, SimpleStoreStorage, StoreDataLoader, RelStoreDataLoader, RelCollectionStore } from './collection';
import { LocaleStore } from './locale';
import { SettingsStore } from './settings';

export const authStore = new AuthStore(new LocalStorageTokenProvider());
export const localeStore = new LocaleStore();
export const settingsStore = new SettingsStore();
export const lotStore = new CollectionStore<cyrian.api.lots.Lot,
  CommonGetParams>(new StoreDataLoader(api.lots.getAll), new CachedStorage());
export const lotAnalysisStore = new RelCollectionStore<cyrian.api.lots.LotAnalysis,
  CommonGetParams>(new RelStoreDataLoader(api.lots.getAnalyses), new CachedStorage());
export const lotWeighingStore = new RelCollectionStore<cyrian.api.lots.LotWeighing,
  CommonGetParams>(new RelStoreDataLoader(api.lots.getWeighings), new CachedStorage());
export const lotFumigationStore = new RelCollectionStore<cyrian.api.lots.LotFumigation,
  CommonGetParams>(new RelStoreDataLoader(api.lots.getFumigations), new CachedStorage());
export const lotPledgeStore = new RelCollectionStore<cyrian.api.lots.LotPledge,
  CommonGetParams>(new RelStoreDataLoader(api.lots.getPledges), new CachedStorage());

export const bvStore = new CollectionStore<cyrian.api.bvs.BV,
  cyrian.api.bvs.GetParams>(new StoreDataLoader(api.bvs.getAll), new CachedStorage());
export const cvStore = new CollectionStore<cyrian.api.cvs.CV,
  cyrian.api.cvs.GetParams>(new StoreDataLoader(api.cvs.getAll), new CachedStorage());
export const cvExecutionStore = new CollectionStore<cyrian.api.cvExecutions.CVExecution,
  cyrian.api.cvExecutions.GetParams>(new StoreDataLoader(api.cvExecutions.getAll), new CachedStorage());
export const campaignStore = new CollectionStore<cyrian.api.campaigns.Campaign,
  cyrian.api.campaigns.GetParams>(new StoreDataLoader(api.campaigns.getAll), new CachedStorage());
export const counterpartyStore = new CollectionStore<cyrian.api.counterparties.Counterparty,
  cyrian.api.counterparties.GetParams>(new StoreDataLoader(api.counterparties.getAll), new CachedStorage());
export const currencyStore = new CollectionStore<cyrian.api.currencies.Currency,
  cyrian.api.currencies.GetParams>(new StoreDataLoader(api.currencies.getAll), new CachedStorage());
export const productStore = new CollectionStore<cyrian.api.products.Product,
  cyrian.api.products.GetParams>(new StoreDataLoader(api.products.getAll), new CachedStorage());
export const addressStore = new CollectionStore<cyrian.api.addresses.Address,
  cyrian.api.addresses.GetParams>(new StoreDataLoader(api.addresses.getAll), new CachedStorage());
export const counterpartyContractStore = new CollectionStore<cyrian.api.counterpartyContracts.CounterpartyContract,
  cyrian.api.counterpartyContracts.GetParams>(new StoreDataLoader(api.counterpartyContracts.getAll), new CachedStorage());
export const lotEventStore = new CollectionStore<cyrian.api.lots.Event,
  cyrian.api.lots.GetEventParams>(new DummyDataLoader(), new SimpleStoreStorage());
export const boardingStore = new CollectionStore<cyrian.api.boardings.Boarding,
  cyrian.api.boardings.GetParams>(new StoreDataLoader(api.boardings.getAll), new CachedStorage());
export const transitOrderStore = new CollectionStore<cyrian.api.transitOrders.TransitOrder,
  cyrian.api.transitOrders.GetParams>(new StoreDataLoader(api.transitOrders.getAll), new CachedStorage());
export const containerStore = new CollectionStore<cyrian.api.containers.Container,
  cyrian.api.containers.GetParams>(new StoreDataLoader(api.containers.getAll), new CachedStorage());
export const transferStore = new CollectionStore<cyrian.api.transfers.Transfer,
  cyrian.api.containers.GetParams>(new StoreDataLoader(api.transfers.getAll), new CachedStorage());
export const contractLineItemStore = new CollectionStore<cyrian.api.contractLineItems.LineItem,
  cyrian.api.containers.GetParams>(new StoreDataLoader(api.contractLineItems.getAll), new CachedStorage());
export const formulaStore = new CollectionStore<cyrian.api.formulas.Formula,
  cyrian.api.formulas.GetParams>(new StoreDataLoader(api.formulas.getAll), new CachedStorage());
