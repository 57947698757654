import { UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { api } from '@/api';
import { PERMISSION_OBJ_LOT_FUMIGATION } from '@/constants/permissions';
import { getAllowedFormFields } from '@/components/generic-form/utils';
import { DrawerForm } from '@/components/drawer-form';
import { addressStore } from '@/stores';
import { getTranslatedString } from '@/utils';
import { useMassUpdateCashedStores } from '@/utils/store';
import { showError } from '@/utils/common';

import { getFormFields } from './setup';

export const FumigationForm = observer(({ transition }: UIViewInjectedProps) => {
  const { lotId } = transition.router.globals.params;
  const { selectedLots = [] } = transition.targetState().params();

  const { loading } = useMassUpdateCashedStores([addressStore]);

  const resourceController = {
    create: async (values: any) => {
      const createPledge = (lotId) => api.lots.createFumigation(lotId, values).source
        .then(({ data }: any) => data)
        .catch(showError);

      if (selectedLots.length) {
        for (const selectedLot of selectedLots) {
          await createPledge(selectedLot);
        }
      } else {
        await createPledge(lotId);
      }
    },
  };

  const onClose = () => {
    transition.router.stateService.go('base-layout.lots');
  };

  const initValues = {};
  const title = getTranslatedString('lot.fumigation.title', { 0: selectedLots.join(', ') || lotId });
  const formFields = getFormFields();
  const allowedFields = getAllowedFormFields(formFields, true, PERMISSION_OBJ_LOT_FUMIGATION);

  return (
    <DrawerForm
      title={title}
      initValues={initValues}
      formFields={allowedFields}
      onClose={onClose}
      resourceController={resourceController}
      loaderCondition={loading}
    />
  );
});
