import { faPen, faPlus } from '@fortawesome/free-solid-svg-icons';
import { faPalletAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Space, Tooltip } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { PERMISSION_OBJ_COUNTERPARTY_CONTRACT, PERMISSION_READ } from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';
import { productFormattedLabel } from '@/utils/common';
import { getFormattedDate } from '@/utils/moment';
import { SortOrder } from 'antd/lib/table/interface';

const PermissionedCreateProposalAction = withPermissions([[PERMISSION_OBJ_COUNTERPARTY_CONTRACT, PERMISSION_READ]])(
  ({ onClick }: { onClick: () => void }) => {
    return (
      <Tooltip title="Create Proposal">
        <Button type="primary" shape="circle" icon={<FontAwesomeIcon icon={faPlus} />} onClick={onClick} />
      </Tooltip>
    );
  },
);

const Actions = (record: cyrian.api.lots.Lot, onAddButton) => {
  return <>
    <PermissionedCreateProposalAction onClick={() => onAddButton(record.id)} />
  </>;
};

export const getColumns = (onAddButton: any) => [
  {
    title: 'No.',
    dataIndex: 'number',
    defaultSortOrder: 'ascend' as SortOrder,
    sorter: true,
    width: 100,
    render: (_, record) => <><FontAwesomeIcon icon={faPalletAlt} /> {record.number}</>,
  },
  {
    title: <FormattedMessage id="lots.product" />,
    dataIndex: ['product', 'name'],
    sorter: true,
    render: (_, record) => productFormattedLabel(record.product),
  },
  {
    title: <FormattedMessage id="lots.campaign" />,
    dataIndex: ['campaign', 'name'],
    sorter: true,
  },
  {
    title: <FormattedMessage id="lots.weight" />,
    dataIndex: 'weight',
    sorter: true,
  },
  {
    title: <FormattedMessage id="proposal.customer" />,
    dataIndex: ['proposalCounterparty', 'name'],
    sorter: true,
  },
  {
    title: <FormattedMessage id="lots.proposal-status" />,
    dataIndex: 'proposalStatus',
    sorter: true,
  },
  {
    title: <FormattedMessage id="proposal.contract-final" />,
    dataIndex: ['proposalContract', 'clientContract'],
  },
  {
    title: <FormattedMessage id="table.actions" />,
    render: (_: any, record: cyrian.api.lots.Lot) => Actions(record, onAddButton),
    className: "actions",
  },
];

const PermissionedEditAction = withPermissions([[PERMISSION_OBJ_COUNTERPARTY_CONTRACT, PERMISSION_READ]])(
  ({ onClick }: { onClick: () => void }) => {
    return (
      <Tooltip title="Update Proposal">
        <Button type="primary" shape="circle" icon={<FontAwesomeIcon icon={faPen} />} onClick={onClick} />
      </Tooltip>
    );
  },
);

const ExpandedRowActions = (record: any, onEditButton: any) => {
  return (
    <Space>
      <PermissionedEditAction onClick={() => onEditButton(record)} />
    </Space>
  );
};

export const getExpandedRowColumns = (onEditButton: any) => [
  {
    title: 'id',
    dataIndex: 'id',
  },
  {
    title: <FormattedMessage id="lots.proposal-status" />,
    dataIndex: 'status',
  },
  {
    title: <FormattedMessage id="proposal.customer" />,
    dataIndex: ['counterparty', 'name'],
  },
  {
    title: <FormattedMessage id="boardings.contract" />,
    dataIndex: ['contract', 'clientContract'],
  },
  {
    title: <FormattedMessage id="proposal.code" />,
    dataIndex: 'code',
  },
  {
    title: <FormattedMessage id="bvs.acceptance-date" />,
    dataIndex: 'acceptanceDate',
    render: getFormattedDate,
  },
  {
    title: <FormattedMessage id="table.actions" />,
    render: (_: any, record: cyrian.api.lots.Lot) => ExpandedRowActions(record, onEditButton),
    className: "actions",
  },
];
