import { UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';

import { api } from '@/api';
import { WrappedItemsResult, WrappedResult } from '@/api/types';
import { DrawerForm } from '@/components/drawer-form';
import { transferStore } from '@/stores';
import { getTranslatedString } from '@/utils';
import { showError } from '@/utils/common';

import { getFormFields } from './setup';

const entity = 'transfers';
const store = transferStore;

export const LotsSelector = observer(({ transition }: UIViewInjectedProps) => {
  const { transferId: itemId } = transition.router.globals.params;
  const foundItem = store.items.find(({ id }: cyrian.api.transfers.Transfer) => id === +itemId);
  const foundItemLotsIds = useMemo(() => foundItem?.lots.map(({ id }) => id) || [], [foundItem]);

  const [lots, setLots] = useState<cyrian.api.transfers.Lot[]>([]);
  const [targetLots, setTargetLots] = useState<number[]>(foundItemLotsIds);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!foundItem) {
      return;
    }
    setLoading(true);
    api.transfers.getAvailableLots(itemId).source
      .then(({ data }: WrappedItemsResult<cyrian.api.transfers.Lot[]>) => {
        setTargetLots(foundItemLotsIds);
        setLots([...data.items, ...foundItem?.lots || []]);
      })
      .then(() => setLoading(false));
  }, [foundItem]);

  const onClose = () => {
    transition.router.stateService.go(`base-layout.${entity}`);
  };

  const title = getTranslatedString('transfers.transfer-number', { 0: itemId });
  const formFields = getFormFields(lots, targetLots, setTargetLots);

  const onSubmit = (values: any) => {
    const { lots: finalLots } = values;
    const addDiff = finalLots.filter(lotId => !foundItemLotsIds.includes(lotId));
    const removeDiff = foundItemLotsIds.filter(lotId => !finalLots.includes(lotId));
    const promises = [];

    if (addDiff.length) {
      promises.push(
        api.transfers.addLots(itemId, { lotIds: addDiff }).source
          .then(({ data }: WrappedResult<cyrian.api.transfers.Transfer>) => store.updateItem(data))
          .catch(showError),
      );
    }

    if (removeDiff.length) {
      promises.push(
        api.transfers.removeLots(itemId, { lotIds: removeDiff }).source
          .then(({ data }: WrappedResult<cyrian.api.transfers.Transfer>) => store.updateItem(data))
          .catch(showError),
      );
    }

    return Promise.all(promises);
  };

  const resourceController = {
    create: onSubmit,
  };

  const layout = {
    labelCol: { span: 0 },
    wrapperCol: { span: 24 },
    tailLayout: { wrapperCol: { offset: 0, span: 24 } },
  };

  return (
    <DrawerForm
      {...layout}
      title={title}
      initValues={{}}
      formFields={formFields}
      onClose={onClose}
      resourceController={resourceController}
      loaderCondition={loading || (!foundItem)}
    />
  );
});
