import { proposalState } from '@/pages/proposal/state';

import { getFormFields as getCreateFormFields } from '@/pages/proposal/components/create/setup';
import { getTranslatedString } from '@/utils';

export const getFormFields = (
  initValues: any,
  customerContractOptions: any[],
  onCustomerChange: (id: number) => void,
) => {
  const { status: proposalStatus = '' } = initValues;

  const options = proposalStatus && proposalState[proposalStatus]
    .map((status: string) => ({
      value: status,
      label: getTranslatedString(`proposal.status.${status}`),
    }))
    .concat([{
      value: proposalStatus,
      label: getTranslatedString(`proposal.status.${proposalStatus}`),
    }]);

  return [
    ...getCreateFormFields(customerContractOptions, onCustomerChange),
    {
      name: 'acceptanceDate',
      componentName: 'datepicker',
      params: {
        i18nLabel: 'bvs.acceptance-date',
        showTime: true,
      },
      rules: [{ required: true }],
    },
    {
      name: 'status',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'proposal.status',
        options,
      },
      rules: [{ required: true }],
    },
  ];
};
