import React from 'react';
import { observer } from 'mobx-react-lite';

import { api } from '@/api';
import { Table } from '@/components/table';
import { useMassUpdateCashedRelStores } from '@/utils/store';

import { lotAnalysisStore } from '@/stores';
import { getColumns } from './setup';

const store = lotAnalysisStore;

export const Analyses = observer(({ lotId, lot }: any) => {
  const items = store.items;

  useMassUpdateCashedRelStores([store], lotId);
  const columns = getColumns(lotId);

  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={[...items]}
      store={store}
      loading={store.loading}
      scroll={{ x: 950 }}
      relId={lotId}
    />
  );
});
