import { UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';

import { api } from '@/api';
import { WrappedResult } from '@/api/types';
import { DrawerForm } from '@/components/drawer-form';
import { DEFAULT_CURRENCY } from '@/constants';
import { formulaStore, counterpartyStore, currencyStore } from '@/stores';
import { getTranslatedString } from '@/utils';
import { withoutRepeatValue } from '@/utils/controller';
import { useMassUpdateCashedStores } from '@/utils/store';
import { buildPrice } from '@/utils/formatters';

import { getFormFields } from './setup';

const entity = 'formulas';
const store = formulaStore;

export const TaxForm = observer(({ transition }: UIViewInjectedProps) => {
  const isCreate = transition.router.globals.current.name === 'base-layout.formulas.view.taxes-create';
  const { formulaId, taxId } = transition.router.globals.params;
  const foundFormula = store.items.find(({ id }: any) => id === +formulaId);
  const foundItem = (foundFormula?.taxes || []).find(({ id }: any) => id === +taxId);

  useMassUpdateCashedStores([counterpartyStore, currencyStore]);
  const onClose = () => {
    transition.router.stateService.go('base-layout.formulas.view');
  };

  const initValues = !isCreate && foundItem
    ? {
      ...foundItem,
      bankId: foundItem?.bank?.id,
      price: foundItem.price?.amount,
      priceCurrency: foundItem.price?.currency.id || DEFAULT_CURRENCY,
      date: foundItem.date && moment(foundItem.date),
    }
    : {};

  const title = isCreate
    ? getTranslatedString('formula.taxes.create-new')
    : getTranslatedString('formula.taxes.tax-number', { 0: formulaId });

  const formFields = getFormFields();

  const resourceController = withoutRepeatValue(initValues, {
    create: (values: any) => {
      values.price = buildPrice(
        values.price ? values.priceCurrency : undefined,
        values.price,
        DEFAULT_CURRENCY,
        undefined,
      );

      return api[entity].createTax(formulaId, values).source
        .then(({ data }: WrappedResult<cyrian.api.formulas.Tax>) => {
          const taxes = [...foundFormula.taxes, data];
          store.updateItem({ ...foundFormula, taxes });

          return data;
        });
    },
    update: (values: any) => {
      values.price = buildPrice(
        values.priceCurrency,
        values.price,
        foundItem.price?.currency.id || DEFAULT_CURRENCY,
        foundItem.price?.amount,
      );

      return api[entity].updateTax(formulaId, taxId, values).source
        .then(({ data }: WrappedResult<cyrian.api.formulas.Tax>) => {
          const taxes = foundFormula.taxes
            .map((tax: cyrian.api.formulas.Tax) => tax.id === data.id ? data : tax);
          store.updateItem({ ...foundFormula, taxes });

          return data;
        });
    },
  });

  return (
    <DrawerForm
      resourceId={taxId}
      title={title}
      initValues={initValues}
      formFields={formFields}
      onClose={onClose}
      resourceController={resourceController}
      loaderCondition={!isCreate && !foundItem}
    />
  );
});
