import { UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { api } from '@/api';
import { getAllowedFormFields } from '@/components/generic-form/utils';
import { DrawerFormWithNote } from '@/components/note-form';
import { PERMISSION_OBJ_ADDRESS } from '@/constants/permissions';
import { addressStore } from '@/stores';
import { NoteObjectType } from '@/types/enums';
import { getTranslatedString } from '@/utils';
import { withoutRepeatValue } from '@/utils/controller';

import { getFormFields } from './setup';

const entity = 'addresses';
const store = addressStore;

export const AddressForm = observer(({ transition }: UIViewInjectedProps) => {
  const isCreate = transition.router.globals.current.name === `base-layout.${entity}.create`;
  const { addressId } = transition.router.globals.params;
  const foundItem = store.items.find(({ id }: any) => id === +addressId);

  const onClose = () => {
    transition.router.stateService.go(`base-layout.${entity}`);
  };

  const initValues = !isCreate && foundItem
    ? { ...foundItem }
    : {};

  const title = isCreate
    ? getTranslatedString('addresses.create-new')
    : getTranslatedString('addresses.address-number', { 0: addressId });

  const formFields = getFormFields();
  const allowedFields = getAllowedFormFields(formFields, isCreate, PERMISSION_OBJ_ADDRESS);

  const resourceController = withoutRepeatValue(initValues, {
    create: (values: any) => api[entity].create(values).source
      .then(({ data }: any) => store.addItem(data)),
    update: (values: any) => api[entity].update(foundItem.id, values).source
      .then(({ data }: any) => store.updateItem(data)),
  });

  return (
    <DrawerFormWithNote
      objectType={NoteObjectType.Address}
      resourceId={addressId}
      title={title}
      initValues={initValues}
      formFields={allowedFields}
      onClose={onClose}
      resourceController={resourceController}
      loaderCondition={!isCreate && !foundItem}
    />
  );
});
