import { CampaignForm } from './components/form';
import { Campaigns } from './components/table';

import {
  PERMISSION_CREATE,
  PERMISSION_OBJ_CAMPAIGN,
  PERMISSION_READ,
  PERMISSION_UPDATE,
} from '@/constants/permissions';

export const states = [
  {
    name: 'base-layout.campaigns',
    url: '/campaigns',
    component: Campaigns,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_CAMPAIGN, PERMISSION_READ],
      ],
    },
  },
  {
    name: 'base-layout.campaigns.create',
    url: '/new',
    component: CampaignForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_CAMPAIGN, PERMISSION_CREATE],
      ],
    },
  },
  {
    name: 'base-layout.campaigns.edit',
    url: '/:campaignId/edit',
    component: CampaignForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_CAMPAIGN, PERMISSION_UPDATE],
      ],
    },
  },
];
