import { Col, Input, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { withStores } from '@/components/stores-provider';
import { localeStore } from '@/stores';
import { getTranslatedString } from '@/utils';
import { UserSettings } from './settings';

export const Profile = withStores('authStore')(
  observer((
    {
      authStore: {
        profile: {
          email,
          name = '',
          role,
        },
      },
    }: any,
  ) => {
    const languages = localeStore.availableLanguages
    .map((language) => {
      const onClick = () => {
        localeStore.change(language);
      };

      return (
        <a key={language} onClick={onClick}>{
          language === localeStore.language
            ? <strong>{language}</strong>
            : language
        } </a>
      );
    });

    return (
      <>
        <Col>
        <h3 className="profile">{getTranslatedString('profile.title')}</h3>
        <Row align="middle" className="profile-row">
          <Col flex="0 0 100px">{getTranslatedString('profile.email')}:</Col>
          <Col flex="0 0 160px">
            <Input defaultValue={email} name="email" className="user-settings-input" disabled />
          </Col>
        </Row>
        <Row align="middle" className="profile-row">
          <Col flex="0 0 100px">{getTranslatedString('profile.name')}:</Col>
          <Col flex="0 0 160px">
            <Input defaultValue={name} name="name" className="user-settings-input" disabled />
          </Col>
        </Row>
        <Row align="middle" className="profile-row">
          <Col flex="0 0 100px">{getTranslatedString('profile.role')}:</Col>
          <Col flex="0 0 160px">
            <Input defaultValue={role} name="role" className="user-settings-input" disabled />
          </Col>
        </Row>
        <h3 className="profile">{getTranslatedString('user-settings.title')}</h3>
        <UserSettings />
        <div className="lang">
          {languages}
        </div>
        </Col>
      </>
    );
  }),
);
