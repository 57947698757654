import { Form } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { Dispatch, SetStateAction } from 'react';

import { api } from '@/api';
import { WrappedResult } from '@/api/types';
import { DrawerForm } from '@/components/drawer-form';
import { getAllowedFormFields } from '@/components/generic-form/utils';
import { PERMISSION_OBJ_PROPOSAL } from '@/constants/permissions';
import { useContracts } from '@/pages/proposal/components/create/hooks';
import { counterpartyContractStore, counterpartyStore, lotStore } from '@/stores';
import { getTranslatedString } from '@/utils';
import { withoutRepeatValue } from '@/utils/controller';
import { useMassUpdateCashedStores } from '@/utils/store';

import { getFormFields } from './setup';

interface CreateFormProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  setNestedData: Dispatch<SetStateAction<object>>;
  selectedLotId: number;
}

export const CreateForm = observer(({ visible, setVisible, setNestedData, selectedLotId }: CreateFormProps) => {
  const [form] = Form.useForm();
  const { loading } = useMassUpdateCashedStores([counterpartyStore, counterpartyContractStore]);
  const { customerContracts, onCustomerChange } = useContracts(form);

  const onClose = () => {
    setVisible(false);
    form.resetFields();
  };

  const initValues = {};
  const title = getTranslatedString('proposal.create', { 0: selectedLotId });

  const formFields = getFormFields(customerContracts, onCustomerChange);
  const allowedFields = getAllowedFormFields(formFields, true, PERMISSION_OBJ_PROPOSAL, ['selectedLots']);

  const resourceController = withoutRepeatValue(initValues, {
    create: (values: any) => {
      return api.lots.createProposal(selectedLotId, values).source
        .then(({ data }: WrappedResult<cyrian.api.lots.Proposal>) => {
          setNestedData(state => ({
            ...state,
            [selectedLotId]: [...state[selectedLotId] || [], data],
          }));
          return lotStore.refresh();
        });
    },
  });

  return (
    <DrawerForm
      visible={visible}
      form={form}
      title={title}
      initValues={initValues}
      formFields={allowedFields}
      onClose={onClose}
      resourceController={resourceController}
      loaderCondition={loading || !selectedLotId}
      useDisableSubmit={false}
    />
  );
});
