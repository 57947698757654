import { UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';

import { api } from '@/api';
import { WrappedResult } from '@/api/types';
import { getAllowedFormFields } from '@/components/generic-form/utils';
import { DrawerFormWithNote } from '@/components/note-form';
import { DEFAULT_CURRENCY } from '@/constants';
import { PERMISSION_OBJ_CONTRACT_LINE_ITEM } from '@/constants/permissions';
import { counterpartyContractStore, contractLineItemStore, counterpartyStore, currencyStore, productStore } from '@/stores';
import { NoteObjectType } from '@/types/enums';
import { getTranslatedString } from '@/utils';
import { withoutRepeatValue } from '@/utils/controller';
import { useMassUpdateCashedStores } from '@/utils/store';
import { buildPrice } from '@/utils/formatters';

import { getFormFields } from './setup';

const entity = 'contract-line-items';
const store = contractLineItemStore;

const normalizedData = (values: any) => {
  const format = (range: any[]) => {
    return {
      from: {
        value: range,
        include: true,
      },
      to: {
        value: moment(range).add(3, 'M'),
        include: true,
      },
    };
  };

  return {
    ...values,
    ...(values.contractPeriod && { contractPeriod: format(values.contractPeriod) }),
    ...(values.boardingPeriod && { boardingPeriod: format(values.boardingPeriod) }),
  };
};

export const ContractLineItemForm = observer(({ transition }: UIViewInjectedProps) => {
  const isCreate = transition.router.globals.current.name === 'base-layout.contract-line-items.create';
  const { lineItemId } = transition.router.globals.params;
  const foundItem = store.items.find(({ id }: any) => id === +lineItemId);
  const { loading } = useMassUpdateCashedStores([counterpartyStore, productStore, currencyStore, counterpartyContractStore, contractLineItemStore]);
  const onClose = () => {
    transition.router.stateService.go(`base-layout.${entity}`);
  };

  const initValues = !isCreate && foundItem
    ? {
      ...foundItem,
      exporterId: foundItem.exporter?.id,
      contractId: foundItem.contract?.id,
      productId: foundItem.product?.id,
      contractPeriod: foundItem.contractPeriod && moment(foundItem.contractPeriod.from),
      boardingPeriod: foundItem.boardingPeriod && moment(foundItem.boardingPeriod.from),
      materialFixingDate: foundItem.materialFixingDate && moment(foundItem.materialFixingDate),
      currencyFixingDate: foundItem.currencyFixingDate && moment(foundItem.currencyFixingDate),
      fixingPrice: foundItem.fixingPrice?.amount,
      fixingPriceCurrency: foundItem.fixingPrice?.currency.id,
      totalFobPrice: foundItem.totalFobPrice?.amount,
      totalFobPriceCurrency: foundItem.totalFobPrice?.currency.id,
      qualityPrice: foundItem.qualityPrice?.amount,
      qualityPriceCurrency: foundItem.qualityPrice?.currency.id,
      cyrianBasicPrice: foundItem.cyrianBasicPrice?.amount,
      cyrianBasicPriceCurrency: foundItem.cyrianBasicPrice?.currency.id,
      cyrianDiscount: foundItem.cyrianDiscount?.amount,
      cyrianDiscountCurrency: foundItem.cyrianDiscount?.currency.id,
    }
    : {};

  const title = isCreate
    ? getTranslatedString('contract-line-items.create-new')
    : getTranslatedString('contract-line-items.line-item-number', { 0: lineItemId });

  const formFields = getFormFields();
  const allowedFields = getAllowedFormFields(formFields, isCreate, PERMISSION_OBJ_CONTRACT_LINE_ITEM);

  const resourceController = withoutRepeatValue(initValues, {
    create: (values: any) => {
      values.fixingPrice = buildPrice(
        values.fixingPrice ? values.fixingPriceCurrency : undefined,
        values.fixingPrice,
        DEFAULT_CURRENCY,
        undefined,
      );
      values.totalFobPrice = buildPrice(
        values.totalFobPrice ? values.totalFobPriceCurrency : undefined,
        values.totalFobPrice,
        DEFAULT_CURRENCY,
        undefined,
      );
      values.qualityPrice = buildPrice(
        values.qualityPrice ? values.qualityPriceCurrency : undefined,
        values.qualityPrice,
        DEFAULT_CURRENCY,
        undefined,
      );
      values.cyrianBasicPrice = buildPrice(
        values.cyrianBasicPrice ? values.cyrianBasicPriceCurrency : undefined,
        values.cyrianBasicPrice,
        DEFAULT_CURRENCY,
        undefined,
      );
      values.cyrianDiscount = buildPrice(
        values.cyrianDiscount ? values.cyrianDiscountCurrency : undefined,
        values.cyrianDiscount,
        DEFAULT_CURRENCY,
        undefined,
      );

      return api.contractLineItems.create(normalizedData(values)).source
        .then(({ data }: WrappedResult<cyrian.api.contractLineItems.LineItem>) => {
          store.addItem(data)
        });
    },
    update: (values: any) => {
      values.fixingPrice = buildPrice(
        values.fixingPriceCurrency,
        values.fixingPrice,
        foundItem.fixingPrice?.currency.id || DEFAULT_CURRENCY,
        foundItem.fixingPrice?.amount,
      );
      values.totalFobPrice = buildPrice(
        values.totalFobPriceCurrency,
        values.totalFobPrice,
        foundItem.totalFobPrice?.currency.id || DEFAULT_CURRENCY,
        foundItem.totalFobPrice?.amount,
      );
      values.qualityPrice = buildPrice(
        values.qualityPriceCurrency,
        values.qualityPrice,
        foundItem.qualityPrice?.currency.id || DEFAULT_CURRENCY,
        foundItem.qualityPrice?.amount,
      );
      values.cyrianBasicPrice = buildPrice(
        values.cyrianBasicPriceCurrency,
        values.cyrianBasicPrice,
        foundItem.cyrianBasicPrice?.currency.id || DEFAULT_CURRENCY,
        foundItem.cyrianBasicPrice?.amount,
      );
      values.cyrianDiscount = buildPrice(
        values.cyrianDiscountCurrency,
        values.cyrianDiscount,
        foundItem.cyrianDiscount?.currency.id || DEFAULT_CURRENCY,
        foundItem.cyrianDiscount?.amount,
      );

      return api.contractLineItems.update(foundItem.id, normalizedData(values)).source
        .then(({ data }: WrappedResult<cyrian.api.contractLineItems.LineItem>) => {
          store.updateItem(data)
        });
    },
  });

  return (
    <DrawerFormWithNote
      objectType={NoteObjectType.ContractLineItem}
      resourceId={lineItemId}
      title={title}
      initValues={initValues}
      formFields={allowedFields}
      onClose={onClose}
      resourceController={resourceController}
      loaderCondition={loading || (!isCreate && !foundItem)}
    />
  );
});
