import { UIViewInjectedProps } from '@uirouter/react';

import { NoteObjectType } from '@/types/enums';
import { Notes } from '@/components/notes';
import { PERMISSION_CREATE, PERMISSION_OBJ_BV, PERMISSION_READ, PERMISSION_UPDATE, } from '@/constants/permissions';

import { BVAttachments } from './components/attachments';
import { BVForm } from './components/form';
import { BVs } from './components/table';

export const states = [
  {
    name: 'base-layout.bvs',
    url: '/bvs',
    component: BVs,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_BV, PERMISSION_READ],
      ],
    },
  },
  {
    name: 'base-layout.bvs.create',
    url: '/new',
    component: BVForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_BV, PERMISSION_CREATE],
      ],
    },
  },
  {
    name: 'base-layout.bvs.edit',
    url: '/:bvId/edit',
    component: BVForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_BV, PERMISSION_UPDATE],
      ],
    },
  },
  {
    name: 'base-layout.bvs.attachments',
    url: '/:bvId/attachments',
    component: BVAttachments,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_BV, PERMISSION_UPDATE],
      ],
    },
  },
  {
    name: 'base-layout.bvs.notes',
    url: '/:bvId/notes',
    component: ({transition}: UIViewInjectedProps) => Notes(
      {
        transition,
        paramId: 'bvId',
        objectType: NoteObjectType.BV,
        closeEntity: 'bvs',
      }
    ),
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_BV, PERMISSION_UPDATE],
      ],
    },
  },
];
