import { Space } from 'antd';
import React, { MouseEvent } from 'react';
import { FormattedMessage } from 'react-intl';

import { api } from '@/api';
import {
  DeleteAction,
  EditAction, SelectorAction,
} from '@/components/table/actions';
import {
  PERMISSION_UPDATE,
  PERMISSION_OBJ_FORMULA,
  PERMISSION_DELETE,
} from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';
import { formulaStore } from '@/stores';
import { showError, productFormattedLabel } from '@/utils/common';
import { getFormattedDate } from '@/utils/moment';

const PermissionedEditAction = withPermissions([[PERMISSION_OBJ_FORMULA, PERMISSION_UPDATE]])(EditAction);
const PermissionedDeleteAction = withPermissions([[PERMISSION_OBJ_FORMULA, PERMISSION_DELETE]])(DeleteAction);
const PermissionedSelectorAction = withPermissions([[PERMISSION_OBJ_FORMULA, PERMISSION_UPDATE]])(SelectorAction);

const Actions = (_: any, record: any) => {
  const onDelete = (e: MouseEvent) => {
    e.stopPropagation();
    api.formulas.delete(record.id).source
      .then(() => formulaStore.deleteItem(record.id))
      .catch(showError);
  };

  return (
    <Space>
      <PermissionedEditAction uiSref={{ to: 'base-layout.formulas.edit', params: { formulaId: record.id } }} />
      <PermissionedSelectorAction uiSref={{ to: 'base-layout.formulas.lots', params: { formulaId: record.id } }}
                                  title="Lots" />
      <PermissionedSelectorAction uiSref={{ to: 'base-layout.formulas.contracts', params: { formulaId: record.id } }}
                                  title="Contracts" />
      <PermissionedDeleteAction onConfirm={onDelete} />
    </Space>
  );
};

export const columns = [
  {
    title: 'id',
    dataIndex: 'id',
    sorter: true,
  },
  {
    title: <FormattedMessage id="formulas.exporter" />,
    dataIndex: ['exporter', 'name'],
    sorter: true,
  },
  {
    title: <FormattedMessage id="formulas.freight-forwarder" />,
    dataIndex: ['freightForwarder', 'name'],
    sorter: true,
  },
  {
    title: <FormattedMessage id="formulas.product" />,
    dataIndex: ['product', 'name'],
    sorter: true,
    render: (_, record) => productFormattedLabel(record.product),
  },
  {
    title: <FormattedMessage id="formulas.campaign" />,
    dataIndex: ['campaign', 'name'],
    sorter: true,
  },
  {
    title: <FormattedMessage id="formulas.destination" />,
    dataIndex: ['destination', 'name'],
    sorter: true,
  },
  {
    title: <FormattedMessage id="formulas.departure-port" />,
    dataIndex: ['departurePort', 'name'],
    sorter: true,
  },
  {
    title: <FormattedMessage id="formulas.recipient" />,
    dataIndex: ['recipient', 'name'],
    sorter: true,
  },
  {
    title: <FormattedMessage id="formulas.execution-cv" />,
    dataIndex: ['executionCv', 'number'],
    sorter: true,
  },
  {
    title: <FormattedMessage id="formulas.authorization-number" />,
    dataIndex: 'authorizationNumber',
    sorter: true,
  },
  {
    title: <FormattedMessage id="formulas.harvest" />,
    dataIndex: 'harvest',
    sorter: true,
  },
  {
    title: <FormattedMessage id="formulas.grade" />,
    dataIndex: 'grade',
    sorter: true,
  },
  {
    title: <FormattedMessage id="formulas.quality" />,
    dataIndex: 'quality',
    sorter: true,
  },
  {
    title: <FormattedMessage id="formulas.net-weight" />,
    dataIndex: 'netWeight',
    sorter: true,
  },
  {
    title: <FormattedMessage id="formulas.gross-weight" />,
    dataIndex: 'grossWeight',
    sorter: true,
  },
  {
    title: <FormattedMessage id="formulas.packaging" />,
    dataIndex: 'packaging',
    sorter: true,
  },
  {
    title: <FormattedMessage id="formulas.packaging-count" />,
    dataIndex: 'packagingCount',
    sorter: true,
  },
  {
    title: <FormattedMessage id="formulas.customs-nomenclature" />,
    dataIndex: 'customsNomenclature',
    sorter: true,
  },
  {
    title: <FormattedMessage id="formulas.ship" />,
    dataIndex: 'ship',
    sorter: true,
  },
  {
    title: <FormattedMessage id="formulas.fO1Date" />,
    dataIndex: 'fO1Date',
    sorter: true,
    render: getFormattedDate,
  },
  {
    title: "",
    render: Actions,
    className: "actions",
  },
];
