import { showError } from '@/utils/common';
import { action, makeObservable, observable } from 'mobx';

import { ApiResponse, WrappedAxiosItemsResult, WrappedItemsResult } from '@/api/types';
import { AxiosPromise } from 'axios';

interface IStorage<T> {
  data: T[];
  count: number;

  get(): T[];
  set(data): void;
  append(item): void;
  update(item): void;
  delete(id): void;
}

export class SimpleStoreStorage<T extends { id: number }> implements IStorage<T> {
  @observable data: T[] | null = null;
  @observable count: number;

  constructor() {
    makeObservable(this);
  }

  get() {
    return this.data;
  }

  @action
  set(data) {
    this.data = data;
  }

  @action
  append(item) {
    this.data.push(item);
  }

  @action
  update(item: T) {
    const index = this.data.findIndex(({ id }: T) => id === item.id);

    if (index !== -1) {
      this.data[index] = item;
    }
  }

  @action
  delete(itemId: number) {
    this.data = this.data.filter(({ id }: T) => id !== itemId);
  }
}

// tslint:disable-next-line:max-classes-per-file
export class CachedStorage<T extends { id: number }> extends SimpleStoreStorage<T> {
  private readonly factorInSeconds: number = 1000;
  private lastUpdate: number = 0;

  // todo change default life time when will fix the store (10 min)
  constructor(private readonly lifetimeSeconds: number = 10) {
    super();
  }

  expired(): boolean {
    return (Date.now() - this.lastUpdate) > (this.lifetimeSeconds * this.factorInSeconds);
  }

  get() {
    if (this.expired() || !this.data) {
      this.lastUpdate = Date.now();
      return null;
    }
    return this.data;
  }
}

interface IStoreDataLoader<P> {
  loading: boolean;
  load(params?: P);
  refresh();
}

// tslint:disable-next-line:max-classes-per-file
export class DummyDataLoader<P> implements IStoreDataLoader<P> {
  @observable loading: boolean = false;

  constructor() {
    makeObservable(this);
  }

  load(params?: P) {
    return Promise.resolve([]);
  }

  refresh() {
    return Promise.resolve([]);
  }
}

// tslint:disable-next-line:max-classes-per-file
export class StoreDataLoader<T, P> implements IStoreDataLoader<P> {
  @observable loading: boolean = false;
  params: P;

  constructor(private readonly loaderFn: (params: P) => ApiResponse<WrappedAxiosItemsResult<T[]>>) {
    makeObservable(this);
  }

  load(params?: P): AxiosPromise<WrappedAxiosItemsResult<T[]>> {
    this.params = params;
    return this.loaderFn(params).source;
  }

  refresh(): AxiosPromise<WrappedAxiosItemsResult<T[]>> {
    return this.loaderFn(this.params).source;
  }
}

interface IStoreRelDataLoader<P> {
  load(id: number, params?: P);
  loading: boolean;
  refresh();
}

// tslint:disable-next-line:max-classes-per-file
export class RelStoreDataLoader<T, P> implements IStoreRelDataLoader<P> {
  @observable loading: boolean = false;
  params: P;
  private id: number;

  constructor(private readonly loaderFn: (id: number, params: P) => ApiResponse<WrappedAxiosItemsResult<T[]>>) {
    makeObservable(this);
  }

  load(id: number, params?: P): AxiosPromise<WrappedAxiosItemsResult<T[]>> {
    this.params = params;
    this.id = id;
    return this.loaderFn(id, params).source;
  }

  refresh(): AxiosPromise<WrappedAxiosItemsResult<T[]>> {
    return this.loaderFn(this.id, this.params).source;
  }
}

// tslint:disable-next-line:max-classes-per-file
export class CollectionStore<T extends { id: number }, P> {
  constructor(
    private readonly dataLoader: IStoreDataLoader<P>,
    private readonly storage: IStorage<T>,
  ) {
  }

  set loading(loading: boolean) {
    this.dataLoader.loading = loading;
  }

  get loading(): boolean {
    return this.dataLoader.loading;
  }

  get items(): T[] {
    return this.storage.data || [];
  }

  get count(): number {
    return this.storage.count;
  }

  fillStore(items: T[], count?: number): T[] {
    this.storage.set(items);
    this.storage.count = count || items.length;

    return items;
  }

  addItem(item: T): T {
    this.storage.append(item);
    return item;
  }

  updateItem(item: T): T {
    this.storage.update(item);
    return item;
  }

  deleteItem(itemId: number): void {
    this.storage.delete(itemId);
  }

  forceLoad(params?: P): Promise<T[]> {
    this.loading = true;
    return this.dataLoader.load(params)
      .then(({ data }: WrappedItemsResult<T[]>) => this.fillStore(data.items, data.count))
      .catch(showError)
      .finally(() => this.loading = false);
  }

  ensureData(params?: P): Promise<T[]> {
    const result = this.storage.get();
    if (result === null) {
      return this.forceLoad(params);
    }
    return Promise.resolve(result);
  }

  asyncItems(params?: P): Promise<T[]> {
    return this.ensureData(params);
  }

  refresh(): Promise<T[]> {
    this.loading = true;
    return this.dataLoader.refresh()
      .then(({ data }: WrappedItemsResult<T[]>) => this.fillStore(data.items, data.count))
      .catch(showError)
      .finally(() => this.loading = false);
  }
}

// tslint:disable-next-line:max-classes-per-file
export class RelCollectionStore<T extends { id: number }, P> {
  constructor(
    private readonly dataLoader: IStoreRelDataLoader<P>,
    private readonly storage: IStorage<T>,
  ) {
  }

  set loading(loading: boolean) {
    this.dataLoader.loading = loading;
  }

  get loading(): boolean {
    return this.dataLoader.loading;
  }

  get items(): T[] {
    return this.storage.data || [];
  }

  get count(): number {
    return this.storage.count;
  }

  fillStore(items: T[], count?: number): T[] {
    this.storage.set(items);
    this.storage.count = count || items.length;

    return items;
  }

  addItem(item: T): T {
    this.storage.append(item);
    return item;
  }

  updateItem(item: T): T {
    this.storage.update(item);
    return item;
  }

  deleteItem(itemId: number): void {
    this.storage.delete(itemId);
  }

  forceLoad(id: number, params?: P): Promise<T[]> {
    this.loading = true;
    return this.dataLoader.load(id, params)
      .then(({ data }: WrappedItemsResult<T[]>) => this.fillStore(data.items, data.count))
      .catch(showError)
      .finally(() => this.loading = false);
  }

  ensureData(id: number, params?: P): Promise<T[]> {
    const result = this.storage.get();
    if (result === null) {
      return this.forceLoad(id, params);
    }
    return Promise.resolve(result);
  }

  asyncItems(id: number, params?: P): Promise<T[]> {
    return this.ensureData(id, params);
  }

  refresh(): Promise<T[]> {
    this.loading = true;
    return this.dataLoader.refresh()
      .then(({ data }: WrappedItemsResult<T[]>) => this.fillStore(data.items, data.count))
      .catch(showError)
      .finally(() => this.loading = false);
  }
}