import { getTranslatedString } from '@/utils';
import { Dispatch, SetStateAction } from 'react';

export const getFormFields = (
  contractProductId: number,
  contracts: cyrian.api.formulas.Contract[],
  targetContracts: number[],
  warning: boolean,
  setTargetContracts: Dispatch<SetStateAction<number[]>>,
  setWarning: Dispatch<SetStateAction<boolean>>,
) => {
  const onChange = (targetKeys: number[]) => {
    const warning = contracts.filter(contract => targetKeys.includes(contract.id))
      .some((contract: any) => contract.product?.id !== contractProductId);
    setWarning(warning);
    setTargetContracts(targetKeys);
  };

  return [
    {
      name: 'contracts',
      componentName: 'transfer',
      params: {
        style: { justifyContent: 'center' },
        rowKey: (({ id }) => id),
        dataSource: contracts,
        titles: ['Source', 'Target'],
        targetKeys: targetContracts,
        listStyle: {
          height: window.innerHeight * 0.5,
        },
        onChange,
        render: item => item.clientContract,
      },
    },
    // {
    //   name: 'message',
    //   componentName: 'labeled-span',
    //   params: {
    //     style: { padding: '0 25px' },
    //     value: getTranslatedString('boardings.warning-product-not-contract'),
    //     i18nLabel: 'boardings.warning',
    //   },
    //   conditionalRender: () => warning,
    // },
  ];
};
