import React, { useEffect } from 'react';
import { Col, Divider, Row, Timeline } from 'antd';
import Text from 'antd/lib/typography/Text';
import { FormattedMessage } from 'react-intl';

import { api } from '@/api';
import { Table } from '@/components/table';
import { campaignStore, counterpartyStore, lotEventStore, lotStore } from '@/stores';
import { getFormFields as bvFormFields } from '@/pages/bvs/components/form/setup';
import { getFormFields as cvFormFields } from '@/pages/cvs/components/form/setup';
import { useMassUpdateCashedStores } from '@/utils/store';
import { showError } from '@/utils/common';

import { Attachments } from './attachments';
import { CardReadOnlyForm } from './card-form';
import { columns, getExpandedRow } from './setup';

const store = lotEventStore;

export const Details = ({ lot }: any) => {
  const events = store.items;

  useMassUpdateCashedStores([campaignStore, counterpartyStore]);

  useEffect(() => {
    fillStore({});
  }, []);

  const fillStore = (params: cyrian.api.lots.GetEventParams) => {
    store.loading = true;
    if (params && !params.hasOwnProperty('sort')) {
      params.sort = 'createdAt:-1';
    }
    return api.lots.events(lot?.id, params).source
      .then(({ data }) => store.fillStore(data.items, data.count))
      .catch(showError)
      .finally(() => {
        store.loading = false;
      });
  };

  return (
    <>
      <Row>
        <Col span={8}>
          <CardReadOnlyForm i18nTitle="lots.bv" initialValues={lot?.bv} formFields={bvFormFields()} />
        </Col>
        <Col span={7} offset={1}>
          <CardReadOnlyForm
            i18nTitle="lots.execution-cv"
            initialValues={lot?.executionCv}
            formFields={cvFormFields()}
          />
        </Col>
        <Col span={7} offset={1}>
          <CardReadOnlyForm i18nTitle="lots.unblock-cv" initialValues={lot?.executionCv?.cv} formFields={cvFormFields()} />
        </Col>
      </Row>
      <Divider />
      <div style={{ marginBottom: 10 }}>
        <Text strong={true}><FormattedMessage id="lots.events" /></Text>
      </div>
      <Table
        size="small"
        rowKey="id"
        columns={columns}
        dataSource={[...events]}
        store={store}
        fillStore={fillStore}
        loading={store.loading}
        expandable={{ expandedRowRender: (record: cyrian.api.lots.Event) => getExpandedRow(record) }}
      />
      <Divider />
      <Row>
        <Col span={12}>
          <div style={{ marginBottom: 40 }}>
            <Text strong={true}>Mocked Timeline:</Text>
          </div>
          <Timeline>
            <Timeline.Item>N° Lot</Timeline.Item>
            <Timeline.Item>Magasin Usine</Timeline.Item>
            <Timeline.Item color="gray">Transféré lieu XXX</Timeline.Item>
            <Timeline.Item>Bâchage demandé</Timeline.Item>
            <Timeline.Item>Mise à quai</Timeline.Item>
            <Timeline.Item>Debachage demandé</Timeline.Item>
            <Timeline.Item>Transféré vers empotage</Timeline.Item>
            <Timeline.Item>Empoté ID Container</Timeline.Item>
            <Timeline.Item>Embarqué ID OT</Timeline.Item>
            <Timeline.Item color="red">Date limite BV</Timeline.Item>
          </Timeline>
        </Col>
        <Col span={12}>
          <Attachments lot={lot} />
        </Col>
      </Row>
    </>
  );
}