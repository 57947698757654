import { ContainerType } from '@/types/enums';
import { buildDropdownOptionsFromEnum } from '@/utils/common';

export const getFormFields = () => {
  return [
    {
      name: 'name',
      componentName: 'input',
      params: {
        i18nLabel: 'containers.name',
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'type',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'containers.type',
        options: buildDropdownOptionsFromEnum(ContainerType),
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'sacsType',
      componentName: 'input',
      params: {
        i18nLabel: 'containers.sacs-type',
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'dressingMaterial',
      componentName: 'input',
      params: {
        i18nLabel: 'containers.dressing-material',
      },
      rules: [
        { required: true },
      ],
    },
  ];
};
