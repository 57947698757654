import { Space } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { api } from '@/api';
import {
  AddAction,
  DeleteAction,
  EditAction,
  SelectorAction,
} from '@/components/table/actions';
import { ProductIcon } from '@/components/icons';
import { getColumnSearchFields } from '@/components/table/filters';
import {
  PERMISSION_DELETE,
  PERMISSION_OBJ_COUNTERPARTY_CONTRACT,
  PERMISSION_UPDATE,
} from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';
import { counterpartyContractStore } from '@/stores';
import { productFormattedLabel, showError } from '@/utils/common';
import { getFormattedPriceWithCurrency, getFormattedVolume } from '@/utils/formatters';
import { getFormattedDate, getFormattedRangeDate } from '@/utils/moment';

const PermissionedAddAction = withPermissions([[PERMISSION_OBJ_COUNTERPARTY_CONTRACT, PERMISSION_UPDATE]])(AddAction);
const PermissionedEditAction = withPermissions([[PERMISSION_OBJ_COUNTERPARTY_CONTRACT, PERMISSION_UPDATE]])(EditAction);
const PermissionedSelectorAction = withPermissions([[PERMISSION_OBJ_COUNTERPARTY_CONTRACT, PERMISSION_UPDATE]])(SelectorAction);
const PermissionedDeleteAction = withPermissions([[PERMISSION_OBJ_COUNTERPARTY_CONTRACT, PERMISSION_DELETE]])(DeleteAction);

const Actions = (_: any, record: any) => {
  const onDelete = () => {
    api.counterpartyContracts.delete(record.id).source
      .then(() => counterpartyContractStore.deleteItem(record.id))
      .catch(showError);
  };

  return (
    <Space>
      <PermissionedEditAction
        uiSref={{
          to: 'base-layout.counterparty-contracts.edit',
          params: { counterpartyContractId: record.id },
        }}
      />
      <PermissionedSelectorAction
        title="CVs"
        uiSref={{
          to: 'base-layout.counterparty-contracts.cv-executions',
          params: { counterpartyContractId: record.id },
        }}
      />
      <PermissionedDeleteAction onConfirm={onDelete} />
    </Space>
  );
};

export const columns = [
  {
    title: 'id',
    dataIndex: 'id',
    width: 50,
    sorter: true,
  },
  {
    title: <FormattedMessage id="counterparty-contracts.client" />,
    dataIndex: ['counterparty', 'name'],
    sorter: true,
  },
  {
    title: <FormattedMessage id="counterparty-contracts.client-contract" />,
    dataIndex: 'clientContract',
    ...getColumnSearchFields(),
  },
  {
    title: <FormattedMessage id="counterparty-contracts.volume" />,
    dataIndex: 'volume',
    width: 100,
    sorter: true,
    render: getFormattedVolume,
  },
  {
    title: <FormattedMessage id="contract-product.product" />,
    dataIndex: ['product', 'type'],
    width: 100,
    render: (_, record) => <ProductIcon type={record?.product?.type} />,
  },
  {
    title: <FormattedMessage id="counterparty-contracts.quality" />,
    dataIndex: 'quality',
    width: 120,
    sorter: true,
  },
  {
    title: <FormattedMessage id="counterparty-contracts.grain" />,
    dataIndex: 'gr',
    width: 50,
    sorter: true,
  },
  {
    title: <FormattedMessage id="counterparty-contracts.shipping-contract" />,
    dataIndex: 'shipownerContract',
    ...getColumnSearchFields(),
  },
  {
    title: <FormattedMessage id="counterparty-contracts.price" />,
    dataIndex: 'price',
    width: 120,
    sorter: true,
    render: getFormattedPriceWithCurrency,
  },
  {
    title: <FormattedMessage id="counterparty-contracts.statistics" />,
    children: [
      {
        title: <FormattedMessage id="counterparty-contracts.pending" />,
        dataIndex: ['statistics', 'pending'],
        className: 'statistic-grid',
        width: 80,
      },
      {
        title: <FormattedMessage id="counterparty-contracts.accepted" />,
        dataIndex: ['statistics', 'accepted'],
        className: 'statistic-grid',
        width: 80,
      },
      {
        title: <FormattedMessage id="counterparty-contracts.received" />,
        dataIndex: ['statistics', 'received'],
        className: 'statistic-grid',
        width: 80,
      },
      {
        title: <FormattedMessage id="counterparty-contracts.proposed" />,
        dataIndex: ['statistics', 'toBeProposed'],
        className: 'statistic-grid',
        width: 80,
      },
      {
        title: <FormattedMessage id="counterparty-contracts.sampling" />,
        dataIndex: ['statistics', 'sampling'],
        className: 'statistic-grid',
        width: 80,
      },
      {
        title: <FormattedMessage id="counterparty-contracts.total" />,
        dataIndex: ['statistics', 'total'],
        className: 'statistic-grid',
        render: getFormattedVolume,
        width: 80,
      },
    ],
  },
  {
    title: "",
    render: Actions,
    className: "actions",
  },
];
