import { UIViewInjectedProps } from '@uirouter/react';
import React from 'react';

import { Attachments } from '@/components/attachments';
import { AttachmentType } from '@/types/enums';

export const CVAttachments = ({ transition }: UIViewInjectedProps) => {
  const params = {
    transition,
    apiEntity: 'CV',
    paramId: 'cvId',
    attachmentType: AttachmentType.CV,
    closeRoute: 'base-layout.cvs',
  };

  return <Attachments<cyrian.api.cvs.CVWithAttachment> {...params} />;
};
