import { Switch } from '@/components/form-fields/switch';
import { TextArea } from '@/components/form-fields/textarea';
import { Transfer } from '@/components/form-fields/transfer';
import { DateRangePicker } from './date-range-picker';
import { DatePicker } from './datepicker';
import { Dropdown } from './dropdown';
import { Input } from './input';
import { InputNumber } from './input-number';
import { InputWithDropdown } from './input-with-dropdown';
import { LabeledSpan } from './labeled-span';

const fieldsFactory = (fieldType?: string): any => {
  switch (fieldType) {
    case 'input':
      return Input;
    case 'dropdown':
      return Dropdown;
    case 'input-number':
      return InputNumber;
    case 'input-with-dropdown':
      return InputWithDropdown;
    case 'datepicker':
      return DatePicker;
    case 'labeled-span':
      return LabeledSpan;
    case 'transfer':
      return Transfer;
    case 'date-range-picker':
      return DateRangePicker;
    case 'switch':
      return Switch;
    case 'textarea':
      return TextArea;
    default:
      throw new Error(`Unknown field type ${fieldType}`);
  }
};

export {
  fieldsFactory,

  Input,
  Dropdown,
  InputNumber,
  InputWithDropdown,
  DatePicker,
  LabeledSpan,
  Transfer,
  DateRangePicker,
  Switch,
  TextArea,
};
