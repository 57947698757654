import { UIViewInjectedProps, UIView } from '@uirouter/react';
import { Table, Button, Tabs } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Drawer } from '@/components/drawer';
import { formulaStore } from '@/stores';
import { useMassUpdateCashedStores } from '@/utils/store';
import { getTranslatedString } from '@/utils';
import { withPermissions } from '@/hocs/permission';
import { PERMISSION_CREATE, PERMISSION_OBJ_FORMULA_PAYMENT, PERMISSION_OBJ_FORMULA_TAX } from '@/constants/permissions';

import { paymentColumns, taxColumns } from './setup';

const store = formulaStore;
const MAX_WIDTH = 1300;

const { TabPane } = Tabs;

const CreatePaymentButton = withPermissions([[PERMISSION_OBJ_FORMULA_PAYMENT, PERMISSION_CREATE]])(
  ({
     onClick,
     i18nTitle
   }: any) => {
    return (
      <Button onClick={onClick} type="primary" icon={<FontAwesomeIcon icon={faPlus} />}>
        <FormattedMessage id="formula.payment.add-new" />
      </Button>
    );
  }
);

const CreateTaxButton = withPermissions([[PERMISSION_OBJ_FORMULA_TAX, PERMISSION_CREATE]])(
  ({
     onClick,
     i18nTitle
   }: any) => {
    return (
      <Button onClick={onClick} type="primary" icon={<FontAwesomeIcon icon={faPlus} />}>
        <FormattedMessage id="formula.taxes.add-new" />
      </Button>
    );
  }
);

export const FormulaView = observer(({ transition }: UIViewInjectedProps) => {
  useMassUpdateCashedStores([formulaStore]);

  const { formulaId } = transition.router.globals.params;
  const title = getTranslatedString('formulas.formula-number', { 0: formulaId });
  const foundFormula = store.items.find(({ id }) => id === +formulaId);
  const payments = foundFormula?.payments || [];
  const taxes = foundFormula?.taxes || [];

  const onClose = () => {
    transition.router.stateService.go('base-layout.formulas');
  };

  const width = window.innerWidth - 400 > MAX_WIDTH ? MAX_WIDTH : window.innerWidth - 400;

  const onAdd = (entity: string) => {
    transition.router.stateService.go(`base-layout.formulas.view.${entity}-create`, { formulaId });
  };

  return (
    <Drawer onClose={onClose} visible={true} width={width} title={title}>
      <Tabs defaultActiveKey="1">
        <TabPane tab={getTranslatedString('formula.payment.payments')} key="1">
          <CreatePaymentButton onClick={() => onAdd('payments')} />
          <Table
            rowKey="id"
            loading={store.loading}
            columns={paymentColumns(+formulaId)}
            dataSource={[...payments]}
            pagination={{
              total: payments.length,
              showSizeChanger: true,
            }}
          />
        </TabPane>

        <TabPane tab={getTranslatedString('formula.taxes.taxes')} key="2">
          <CreateTaxButton onClick={() => onAdd('taxes')} />
          <Table
            rowKey="id"
            loading={store.loading}
            columns={taxColumns(+formulaId)}
            dataSource={[...taxes]}
            pagination={{
              total: taxes.length,
              showSizeChanger: true,
            }}
          />
        </TabPane>
      </Tabs>
      <UIView />
    </Drawer>
  );
});
