import addresses from './addresses';
import attachments from './attachments';
import boardings from './boardings';
import bvs from './bvs';
import campaigns from './campaigns';
import containers from './containers';
import counterparties from './counterparties';
import counterpartyContracts from './counterparty-contracts';
import contractLineItems from './contract-line-items';
import currencies from './currencies';
import cvExecutions from './cv-executions';
import cvs from './cvs';
import dump from './dump';
import lots from './lots';
import notes from './notes';
import products from './products';
import roles from './roles';
import search from './search';
import settings from './settings';
import transfers from './transfers';
import transitOrders from './transit-orders';
import formulas from './formulas';

export const api: { [key: string]: any } = {
  lots,
  bvs,
  cvExecutions,
  cvs,
  campaigns,
  counterparties,
  currencies,
  dump,
  products,
  addresses,
  attachments,
  counterpartyContracts,
  contractLineItems,
  boardings,
  transitOrders,
  roles,
  search,
  settings,
  containers,
  transfers,
  notes,
  formulas,
};
