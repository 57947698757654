export const getFormattedPrice = (value) => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const getFormattedVolume = (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const getFormattedPriceWithCurrency = (text: any, row) => {
  if (text) return `${text.amount} ${text.currency.code}`;
};

export const buildPrice = (
  currencyId: number | undefined,
  amount: string | undefined,
  defaultCurrencyId: number,
  defaultAmount: number | undefined,
) => {
  if (currencyId === undefined && amount === undefined ) {
    return undefined;
  }

  if (!currencyId && amount) {
    return {
      currencyId: defaultCurrencyId,
      amount: +amount,
    };
  }

  if (currencyId) {
    if (amount === '') {
      return null;
    }

    return {
      currencyId,
      amount: amount ? +amount : defaultAmount,
    };
  }

  return null;
};