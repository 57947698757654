import { ProductForm } from './components/form';
import { Products } from './components/table';

import {
  PERMISSION_CREATE,
  PERMISSION_OBJ_PRODUCT,
  PERMISSION_READ,
  PERMISSION_UPDATE,
} from '@/constants/permissions';

export const states = [
  {
    name: 'base-layout.products',
    url: '/products',
    component: Products,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_PRODUCT, PERMISSION_READ],
      ],
    },
  },
  {
    name: 'base-layout.products.create',
    url: '/new',
    component: ProductForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_PRODUCT, PERMISSION_CREATE],
      ],
    },
  },
  {
    name: 'base-layout.products.edit',
    url: '/:productId/edit',
    component: ProductForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_PRODUCT, PERMISSION_UPDATE],
      ],
    },
  },
];
