import 'antd/dist/antd.css';
import jwtDecode from 'jwt-decode';
import React from 'react';
import ReactDOM from 'react-dom';
import { LOCAL_STORAGE_KEY__ACCESS_TOKEN } from '@/constants';
import App from './App';
import './assets/scss/styles.scss';
import reportWebVitals from './reportWebVitals';

(() => {
  let expirationTimeToken = 0;
  try {
    const token = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN);
    if (token) {
      const decoded = jwtDecode(token) as any;
      expirationTimeToken = decoded.exp * 1000;
    }
  } catch (err) {
    // ignore the problem
  }

  if (expirationTimeToken < Date.now()) {
    localStorage.removeItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN);
  }
})();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
