import { counterpartyStore } from '@/stores';
import { buildStoreOptions } from '@/utils/common';

export const getFormFields = () => [
  {
    name: 'counterpartyId',
    componentName: 'dropdown',
    params: {
      i18nLabel: 'lot.pledge.counterparty',
      options: buildStoreOptions(counterpartyStore, 'name'),
    },
    rules: [{ required: true }],
  },
  {
    name: 'requestNumber',
    componentName: 'input',
    params: {
      i18nLabel: 'lot.pledge.request-number',
      showTime: true,
    },
    rules: [
      { required: true },
      { max: 30 },
    ],
  },
  {
    name: 'ltdNumber',
    componentName: 'input',
    params: {
      i18nLabel: 'lot.pledge.ltd-number',
      showTime: true,
    },
    rules: [
      { required: true },
      { max: 30 },
    ],
  },
  {
    name: 'dasDae',
    componentName: 'input',
    params: {
      i18nLabel: 'lot.pledge.das-dae',
      showTime: true,
    },
    rules: [
      { required: true },
      { max: 30 },
    ],
  },
];
