import { faSeedling } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ConfigProvider } from 'antd';
import enEn from 'antd/lib/locale/en_US';
import frFR from 'antd/lib/locale/fr_FR';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { locales } from '@/i18n';
import { localeStore } from '@/stores';

import { injectIntl, IntlProvider } from 'react-intl';

import { preserveIntl } from '@/utils/intl';

const IntlInjector = injectIntl(({ children, intl }: any) => {
  preserveIntl(intl);
  return children;
});

export const IntlWrapper = observer(({ children }) => {
  const { language } = localeStore;

  if (!language) {
    return null;
  }

  const antdLocale = language === 'fr' ? frFR : enEn;

  const antdRenderEmpty = () => (
    <div className="ant-empty-normal">
      <FontAwesomeIcon icon={faSeedling} />
      <p>No Data</p>
    </div>
  );

  return (
    <IntlProvider messages={locales[language]} locale={language} defaultLocale="en">
      <IntlInjector>
        <ConfigProvider locale={antdLocale} renderEmpty={antdRenderEmpty} componentSize="middle">
          {children}
        </ConfigProvider>
      </IntlInjector>
    </IntlProvider>
  );
});
