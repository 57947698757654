import {
  PERMISSION_OBJ_FORMULA,
  PERMISSION_READ,
  PERMISSION_CREATE,
  PERMISSION_UPDATE,
  PERMISSION_OBJ_BOARDING,
  PERMISSION_OBJ_FORMULA_PAYMENT,
  PERMISSION_OBJ_FORMULA_TAX,
} from '@/constants/permissions';

import { Form } from './components/form';
import { Formulas } from './components/table';
import { LotsTransfer } from './components/lot-transfer';
import { ContractTransfer } from './components/contract-transfer';
import { FormulaView } from './components/view';
import { PaymentForm } from './components/view/payment-form';
import { TaxForm } from './components/view/tax-form';

export const states = [
  {
    name: 'base-layout.formulas',
    url: '/formulas',
    component: Formulas,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_FORMULA, PERMISSION_READ],
      ],
    },
  },
  {
    name: 'base-layout.formulas.create',
    url: '/new',
    component: Form,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_FORMULA, PERMISSION_CREATE],
      ],
    },
  },
  {
    name: 'base-layout.formulas.edit',
    url: '/:formulaId/edit',
    component: Form,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_FORMULA, PERMISSION_UPDATE],
      ],
    },
  },
  {
    name: 'base-layout.formulas.view',
    url: '/:formulaId/view',
    component: FormulaView,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_FORMULA, PERMISSION_READ],
      ],
    },
  },
  {
    name: 'base-layout.formulas.view.payments-create',
    url: '/payments/new',
    component: PaymentForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_FORMULA_PAYMENT, PERMISSION_CREATE],
      ],
    },
  },
  {
    name: 'base-layout.formulas.view.payments-edit',
    url: '/payments/:paymentId/edit',
    component: PaymentForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_FORMULA_PAYMENT, PERMISSION_UPDATE],
      ],
    },
  },
  {
    name: 'base-layout.formulas.view.taxes-create',
    url: '/taxes/new',
    component: TaxForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_FORMULA_TAX, PERMISSION_CREATE],
      ],
    },
  },
  {
    name: 'base-layout.formulas.view.taxes-edit',
    url: '/taxes/:taxId/edit',
    component: TaxForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_FORMULA_TAX, PERMISSION_UPDATE],
      ],
    },
  },
  {
    name: 'base-layout.formulas.lots',
    url: '/:formulaId/lots',
    component: LotsTransfer,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_BOARDING, PERMISSION_UPDATE],
      ],
    },
  },
  {
    name: 'base-layout.formulas.contracts',
    url: '/:formulaId/contracts',
    component: ContractTransfer,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_BOARDING, PERMISSION_UPDATE],
      ],
    },
  },
];
