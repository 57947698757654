import { AddressType } from '@/types/enums';
import { buildDropdownOptionsFromEnum } from '@/utils/common';

export const getFormFields = () => {
  return [
    {
      name: 'name',
      componentName: 'input',
      params: {
        i18nLabel: 'addresses.name',
      },
      rules: [
        { required: true },
        { max: 50 },
      ],
    },
    {
      name: 'type',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'addresses.type',
        options: buildDropdownOptionsFromEnum(AddressType),
      },
      rules: [{ required: true }],
    },
    {
      name: 'country',
      componentName: 'input',
      params: {
        i18nLabel: 'addresses.country',
      },
      rules: [
        { required: true },
        { max: 50 },
      ],
    },
    {
      name: 'city',
      componentName: 'input',
      params: {
        i18nLabel: 'addresses.city',
      },
      rules: [
        { required: true },
        { max: 50 },
      ],
    },
    {
      name: 'street',
      componentName: 'input',
      params: {
        i18nLabel: 'addresses.street',
      },
      rules: [
        { required: true },
        { max: 50 },
      ],
    },
    {
      name: 'street2',
      componentName: 'input',
      params: {
        i18nLabel: 'addresses.street2',
      },
      rules: [
        { required: true },
        { max: 50 },
      ],
    },
  ];
};
