import { PERMISSION_OBJ_COUNTERPARTY_CONTRACT, PERMISSION_READ } from '@/constants/permissions';

import { Lots } from './components/table';

export const states = [
  {
    name: 'base-layout.proposal',
    url: '/proposal',
    component: Lots,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_COUNTERPARTY_CONTRACT, PERMISSION_READ],
      ],
    },
  },
];
