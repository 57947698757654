import { UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { api } from '@/api';
import { WrappedResult } from '@/api/types';
import { DrawerForm } from '@/components/drawer-form';
import { formulaStore, } from '@/stores';
import { getTranslatedString } from '@/utils';
import { withoutRepeatValue } from '@/utils/controller';

import { getFormFields } from './setup';

const entity = 'formulas';
const store = formulaStore;

export const PaymentForm = observer(({ transition }: UIViewInjectedProps) => {
  const isCreate = transition.router.globals.current.name === 'base-layout.formulas.view.payments-create';
  const { formulaId, paymentId } = transition.router.globals.params;
  const foundFormula = store.items.find(({ id }: any) => id === +formulaId);
  const foundItem = (foundFormula?.payments || []).find(({ id }: any) => id === +paymentId);

  const onClose = () => {
    transition.router.stateService.go('base-layout.formulas.view');
  };

  const initValues = !isCreate && foundItem
    ? { ...foundItem }
    : {};

  const title = isCreate
    ? getTranslatedString('formula.payment.create-new')
    : getTranslatedString('formula.payment.payment-number', { 0: formulaId });

  const formFields = getFormFields();

  const resourceController = withoutRepeatValue(initValues, {
    create: (values: any) => api[entity].createPayment(formulaId, values).source
      .then(({ data }: WrappedResult<cyrian.api.formulas.Payment>) => {
        const payments = [...foundFormula.payments, data];
        store.updateItem({ ...foundFormula, payments });

        return data;
      }),
    update: (values: any) => api[entity].updatePayment(formulaId, paymentId, values).source
      .then(({ data }: WrappedResult<cyrian.api.formulas.Payment>) => {
        const payments = foundFormula.payments
          .map((payment: cyrian.api.formulas.Payment) => payment.id === data.id ? data : payment);
        store.updateItem({ ...foundFormula, payments });

        return data;
      }),
  });

  return (
    <DrawerForm
      resourceId={paymentId}
      title={title}
      initValues={initValues}
      formFields={formFields}
      onClose={onClose}
      resourceController={resourceController}
      loaderCondition={!isCreate && !foundItem}
    />
  );
});
