export const getFormFields = () => {
  return [
    {
      name: 'type',
      componentName: 'input',
      params: {
        i18nLabel: 'formula.payment.type',
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'weight',
      componentName: 'input-number',
      params: {
        i18nLabel: 'formula.payment.weight',
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'rate',
      componentName: 'input-number',
      params: {
        i18nLabel: 'formula.payment.rate',
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'support',
      componentName: 'input-number',
      params: {
        i18nLabel: 'formula.payment.support',
      },
      rules: [
        { required: true },
      ],
    },
  ];
};
