import { Card, Col, Layout, Row } from 'antd';
import React from 'react';

interface AuthLayoutProps {
  children: React.ReactNode;
}

export const AuthLayout = ({ children }: AuthLayoutProps) => {
  const sizes = {
    xs: 24,
    sm: 12,
    lg: 7,
  };

  return (
    <Layout>
      <Row className="auth-layout" justify="center" align="middle">
        <Col {...sizes}>
          <Card>
            <h1 className="auth-logo-text">Cyrian</h1>
            {children}
          </Card>
        </Col>
      </Row>
    </Layout>
  );
};
