import { UIViewInjectedProps } from '@uirouter/react';
import React from 'react';

import { Attachments } from '@/components/attachments';
import { AttachmentType } from '@/types/enums';

export const FumigationAttachments = ({ transition }: UIViewInjectedProps) => {
  const params = {
    transition,
    apiEntity: 'LotFumigation',
    paramId: 'fumigationId',
    attachmentType: AttachmentType.LotFumigation,
    closeRoute: 'base-layout.lots.view',
  };

  return <Attachments<cyrian.api.lots.LotFumigation & { attachments: any[]; }> {...params} />;
};
