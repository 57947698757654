import { Table as AntdTable, TableProps as AntdTableProps } from 'antd';
import React, { useMemo } from 'react';

import { getGenericFilters, toQueryFormat } from '@/components/table/utils';
import { authStore, settingsStore } from '@/stores';
import { CollectionStore, RelCollectionStore } from '@/stores/collection';

interface TableProps extends AntdTableProps<any> {
  store?: RelCollectionStore<any, any> | CollectionStore<any, any>;
  fillStore?: (params: any) => Promise<any>;
  checkPermissions?: boolean;
  relId?: number; 
}

export const Table = (props: TableProps) => {
  const { columns, store, fillStore, checkPermissions, relId } = props;
  let finalColumns = [...columns];

  const genericFilters = useMemo(() => getGenericFilters(columns), []);

  if (checkPermissions) {
    finalColumns = finalColumns
      .filter(({ permissions }: any) => {
        if (!permissions) {
          return true;
        }

        return authStore.hasPermissions(permissions);
      });
  }

  const onChangeTable = (...args) => {
    const params = toQueryFormat.apply(null, [genericFilters, ...args]);
    const fillStoreArgs = relId ? [relId, params] : [params];
    fillStore ? fillStore.apply(null, fillStoreArgs) : store.forceLoad.apply(store, fillStoreArgs);
  };

  const onChange = props.onChange || onChangeTable;
  const pagination = props.pagination !== undefined
    ? props.pagination
    : {
      total: store.count,
      showSizeChanger: true,
      onShowSizeChange: (current, size) => {
        settingsStore.changeItemsPerPage(size);
      },
      pageSize: settingsStore.itemsPerPage,
    };

  if (pagination && !pagination.pageSize) {
    pagination.pageSize = settingsStore.itemsPerPage;
  }

  return (
    <AntdTable {...props} columns={finalColumns} onChange={onChange} pagination={pagination} />
  );
};
