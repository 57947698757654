import { faClipboardCheck, faFlask, faSmog } from '@fortawesome/free-solid-svg-icons';
import { faWeight, faSackDollar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Space } from 'antd';
import React, { MouseEvent } from 'react';
import { FormattedMessage } from 'react-intl';

import { api } from '@/api';
import {
  AddAction,
  AttachmentAction,
  DeleteAction,
  EditAction,
} from '@/components/table/actions';
import { ProductIcon } from '@/components/icons';
import {
  PERMISSION_CREATE,
  PERMISSION_DELETE,
  PERMISSION_OBJ_BV,
  PERMISSION_OBJ_LOT,
  PERMISSION_OBJ_LOT_ANALYSIS,
  PERMISSION_OBJ_LOT_FUMIGATION,
  PERMISSION_OBJ_LOT_PLEDGE,
  PERMISSION_OBJ_LOT_WEIGHING,
  PERMISSION_UPDATE,
} from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';
import { lotStore } from '@/stores';
import { showError } from '@/utils/common';
import { getFormattedVolume } from '@/utils/formatters';
import { getFormattedDate } from '@/utils/moment';
import { ColumnType } from 'antd/lib/table';

const PermissionedEditAction = withPermissions([[PERMISSION_OBJ_LOT, PERMISSION_UPDATE]])(EditAction);
const PermissionedDeleteAction = withPermissions([[PERMISSION_OBJ_LOT, PERMISSION_DELETE]])(DeleteAction);
const PermissionedCreateWeighingAction = withPermissions([[PERMISSION_OBJ_LOT_WEIGHING, PERMISSION_CREATE]])(AddAction);
const PermissionedCreateAnalysisAction = withPermissions([[PERMISSION_OBJ_LOT_ANALYSIS, PERMISSION_CREATE]])(AddAction);
const PermissionedCreatePledgeAction = withPermissions([[PERMISSION_OBJ_LOT_PLEDGE, PERMISSION_CREATE]])(AddAction);
const PermissionedCreateFumigationAction = withPermissions([[PERMISSION_OBJ_LOT_FUMIGATION, PERMISSION_CREATE]])(AddAction);
const PermissionedCreateBVAction = withPermissions([[PERMISSION_OBJ_BV, PERMISSION_CREATE]])(AddAction);
const PermissionedAttachmentAction = withPermissions([[PERMISSION_OBJ_LOT, PERMISSION_UPDATE]])(AttachmentAction);

const Actions = (_: any, record: any) => {
  const onDelete = (e: MouseEvent) => {
    e.stopPropagation();
    api.lots.delete(record.id).source
      .then(() => lotStore.deleteItem(record.id))
      .catch(showError);
  };

  return (
    <Space>
      <PermissionedEditAction uiSref={{ to: 'base-layout.lots.edit', params: { lotId: record.id } }} />
      <PermissionedCreateWeighingAction
        uiSref={{ to: 'base-layout.lots.weight', params: { lotId: record.id } }}
        icon={<FontAwesomeIcon icon={faWeight} />}
        tooltipTitle="lot.weighing"
      />
      <PermissionedCreateAnalysisAction
        uiSref={{ to: 'base-layout.lots.analysis', params: { lotId: record.id } }}
        icon={<FontAwesomeIcon icon={faFlask} />}
        tooltipTitle="lot.analysis"
      />
      <PermissionedCreateBVAction
        uiSref={{ to: 'base-layout.lots.bv', params: { lotId: record.id } }}
        icon={<FontAwesomeIcon icon={faClipboardCheck} />}
        tooltipTitle="lot.bv"
      />
      <PermissionedCreateFumigationAction
        uiSref={{ to: 'base-layout.lots.fumigation', params: { lotId: record.id } }}
        icon={<FontAwesomeIcon icon={faSmog} />}
        tooltipTitle="lot.fumigation"
      />
      <PermissionedCreatePledgeAction
        uiSref={{ to: 'base-layout.lots.pledge', params: { lotId: record.id } }}
        icon={<FontAwesomeIcon icon={faSackDollar} />}
        tooltipTitle="lot.pledge"
      />
      <PermissionedAttachmentAction uiSref={{ to: 'base-layout.lots.attachments', params: { lotId: record.id } }} />
      <PermissionedDeleteAction onConfirm={onDelete} />
    </Space>
  );
};

export const columns: ColumnType<any>[] = [
  {
    dataIndex: ['product', 'name'],
    sorter: false,
    width: 50,
    render: (_, record) => <ProductIcon type={record.product.type} />,
  },
  {
    title: <FormattedMessage id="lots.location" />,
    dataIndex: ['location', 'name'],
    sorter: true,
  },
  {
    title: 'No.',
    dataIndex: 'number',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.number - b.number,
  },
  {
    title: <FormattedMessage id="lots.quality" />,
    dataIndex: 'quality',
    sorter: true,
  },
  {
    title: <FormattedMessage id="lots.bv-expiration-date" />,
    dataIndex: ['bv', 'expirationDate'],
    sorter: true,
  },
  {
    title: <FormattedMessage id="lots.owner" />,
    dataIndex: ['owner', 'name'],
    sorter: true,
  },
  {
    title: <FormattedMessage id="lots.final-client" />,
    dataIndex: ['proposal_counterparty', 'name'],
    sorter: true,
  },
  {
    title: <FormattedMessage id="lots.production-date" />,
    dataIndex: 'productionDate',
    sorter: true,
    render: getFormattedDate,
  },
  {
    title: <FormattedMessage id="lots.campaign" />,
    dataIndex: ['campaign', 'name'],
    sorter: true,
  },
  {
    title: <FormattedMessage id="lots.weight" />,
    dataIndex: 'weight',
    sorter: true,
  },
  {
    title: <FormattedMessage id="lots.bv" />,
    dataIndex: ['bv', 'status'],
    sorter: true,
  },
  {
    title: <FormattedMessage id="lots.unblock-cv" />,
    dataIndex: ['executionCv', 'cv', 'number'],
    sorter: true,
  },
  {
    title: <FormattedMessage id="lots.execution-cv" />,
    dataIndex: ['executionCv', 'number'],
    sorter: true,
  },
  {
    title: <FormattedMessage id="lots.production-status" />,
    dataIndex: 'productionStatus',
    sorter: true,
    render: (_, record) => <FormattedMessage id={`lots.production-status.${record?.productionStatus}`} />,
  },

  {
    title: <FormattedMessage id="lots.sacks-quantity" />,
    dataIndex: 'sacksQuantity',
    sorter: true,
    render: getFormattedVolume,
  },
  {
    title: <FormattedMessage id="lots.factory" />,
    dataIndex: ['factory', 'name'],
    sorter: true,
  },
  {
    title: "",
    render: Actions,
    className: "actions",
  },
];
