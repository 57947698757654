import { getTranslatedString } from '@/utils';
import { Dispatch, SetStateAction } from 'react';

export const getFormFields = (
  cvExecutions: cyrian.api.counterpartyContracts.CvExecution[],
  targetCvExecutions: number[],
  setTargetExecutions: Dispatch<SetStateAction<number[]>>,
) => {
  const onChange = (targetKeys: number[]) => {
    setTargetExecutions(targetKeys);
  };
  return [
    {
      name: 'cvExecutions',
      componentName: 'transfer',
      params: {
        style: { justifyContent: 'center' },
        rowKey: (({ id }) => id),
        dataSource: cvExecutions,
        titles: ['Source', 'Target'],
        targetKeys: targetCvExecutions,
        listStyle: {
          height: window.innerHeight * 0.5,
        },
        onChange,
        render: item => item.number,
      },
    }
  ];
};
