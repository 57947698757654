import { ContractLineItemForm } from './components/form';
import { ContractLineItemAttachments } from './components/attachments';
import { ContractLineItems } from './components/table';

import {
  PERMISSION_CREATE,
  PERMISSION_OBJ_CONTRACT_LINE_ITEM,
  PERMISSION_READ,
  PERMISSION_UPDATE,
} from '@/constants/permissions';

export const states = [
  {
    name: 'base-layout.contract-line-items',
    url: '/contract-line-items',
    component: ContractLineItems,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_CONTRACT_LINE_ITEM, PERMISSION_READ],
      ],
    },
  },
  {
    name: 'base-layout.contract-line-items.create',
    url: '/new',
    component: ContractLineItemForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_CONTRACT_LINE_ITEM, PERMISSION_CREATE],
      ],
    },
  },
  {
    name: 'base-layout.contract-line-items.edit',
    url: '/:lineItemId/edit',
    component: ContractLineItemForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_CONTRACT_LINE_ITEM, PERMISSION_UPDATE],
      ],
    },
  },
  {
    name: 'base-layout.contract-line-items.attachments',
    url: '/:lineItemId/attachments',
    component: ContractLineItemAttachments,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_CONTRACT_LINE_ITEM, PERMISSION_UPDATE],
      ],
    },
  },
];
