import { Button, Form } from 'antd';
import React, { useState } from 'react';

import { Drawer } from '@/components/drawer';
import { FormBuilderOptionItem, GenericForm, GenericFormProps } from '@/components/generic-form';
import { Loader } from '@/components/loader';
import { VoidFnType } from '@/types/common';

export interface DrawerFormProps extends GenericFormProps {
  loaderCondition: boolean;
  title: string;
  initValues: any;
  formFields: FormBuilderOptionItem[];
  tailLayout?: object;
  onClose: VoidFnType;
  onSuccessFinish?: VoidFnType;
  onFinish?: (values: any) => void;
  visible?: boolean;
  useDisableSubmit?: boolean;
}

export const DrawerForm = (
  {
    title,
    initValues,
    formFields,
    onClose,
    onFinish,
    onSuccessFinish,
    loaderCondition,
    visible = true,
    useDisableSubmit = true,
    ...props
  }: DrawerFormProps,
) => {
  const [formRef] = Form.useForm();
  const form = props.form || formRef;
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(true);

  const onStartSubmit = () => {
    setLoadingSubmit(true);
  };

  const onFinishSubmit = () => {
    setLoadingSubmit(false);
    setDisableSubmit(true);
  };

  const onFieldsChange = (_, all: any[]) => {
    const disable = all.some(({ errors }: any) => Boolean(errors.length));
    if (disableSubmit !== disable) {
      setDisableSubmit(disable);
    }
  };

  const DrawerFooter = ({ onClose }: { onClose: VoidFunction }) => {
    const disabled = useDisableSubmit ? disableSubmit : false;
    return <div className="drawer-footer">
      <Button className="drawer-footer-button" onClick={onClose}>
        Cancel
      </Button>
      <Button onClick={form.submit} type="primary" loading={loadingSubmit} disabled={disabled}>
        Submit
      </Button>
    </div>;
  };

  const Footer = (onClose: VoidFnType) => <DrawerFooter onClose={onClose} />;

  return (
    <Drawer
      onClose={onClose}
      visible={visible}
      title={title}
      footer={Footer}
    >
      {
        (loaderCondition)
          ? <Loader />
          : <GenericForm
            {...props}
            formRef={form}
            initialValues={initValues}
            formFields={formFields}
            onFinish={onFinish}
            onSuccessFinish={onSuccessFinish || onClose}
            onStartSubmit={onStartSubmit}
            onFinishSubmit={onFinishSubmit}
            onFieldsChange={onFieldsChange}
          />
      }
    </Drawer>
  );
};
