import { request } from '@/utils/request';
import { ApiResponse, WrappedItemsResult, WrappedResult } from './types';

interface Params {
  sort?: string;
  skip?: number;
  limit?: number;
}

export default {
  getAll(params?: Params): ApiResponse<WrappedResult<cyrian.api.lots.Lot[]>> {
    return request({
      method: 'get',
      url: '/lots',
      params,
    });
  },
  get(id: number): ApiResponse<WrappedResult<cyrian.api.lots.Lot>> {
    return request({
      method: 'get',
      url: `/lots/${id}`,
    });
  },
  create(data: cyrian.api.lots.LotParams): ApiResponse<WrappedResult<cyrian.api.lots.Lot>> {
    return request({
      method: 'post',
      url: '/lots',
      data,
    });
  },
  createMany(data: cyrian.api.lots.LotBulkCreate): ApiResponse<WrappedResult<cyrian.api.lots.Lot[]>> {
    return request({
      method: 'post',
      url: '/lots/bulk/create',
      data,
    });
  },
  update(lotId: number, data: cyrian.api.lots.LotParams): ApiResponse<WrappedResult<cyrian.api.lots.Lot>> {
    return request({
      method: 'patch',
      url: `/lots/${lotId}`,
      data,
    });
  },
  events(lotId: number, params: cyrian.api.lots.GetEventParams): ApiResponse<WrappedResult<cyrian.api.lots.Event[]>> {
    return request({
      method: 'get',
      url: `/lots/${lotId}/events`,
      params,
    });
  },
  delete(lotId: number): ApiResponse<WrappedResult<any>> {
    return request({
      method: 'delete',
      url: `/lots/${lotId}`,
    });
  },
  deleteMany(data: { ids: number[] }): ApiResponse<WrappedResult<any>> {
    return request({
      method: 'post',
      url: '/lots/bulk/delete',
      data,
    });
  },
  updateMany(data: cyrian.api.lots.LotParams[]): ApiResponse<WrappedResult<cyrian.api.lots.Lot[]>> {
    return request({
      method: 'post',
      url: `/lots/bulk/update`,
      data,
    });
  },

  getAnalyses(lotId: number, params: cyrian.api.lots.LotAnalysisParams): ApiResponse<WrappedResult<cyrian.api.lots.LotAnalysis[]>> {
    return request({
      method: 'get',
      url: `/lots/${lotId}/analyses`,
      params,
    });
  },
  createAnalysis(lotId: number, data: cyrian.api.lots.LotAnalysisParams): ApiResponse<WrappedResult<cyrian.api.lots.LotAnalysis>> {
    return request({
      method: 'post',
      url: `/lots/${lotId}/analyses`,
      data,
    });
  },
  deleteAnalysis(lotId: number, id: number): ApiResponse<WrappedResult<any>> {
    return request({
      method: 'delete',
      url: `/lots/${lotId}/analyses/${id}`,
    });
  },

  getWeighings(lotId: number, params: cyrian.api.lots.LotWeighingParams): ApiResponse<WrappedResult<cyrian.api.lots.LotWeighing[]>> {
    return request({
      method: 'get',
      url: `/lots/${lotId}/weighings`,
      params,
    });
  },
  createWeighing(lotId: number, data: cyrian.api.lots.LotWeighingParams): ApiResponse<WrappedResult<cyrian.api.lots.LotWeighing>> {
    return request({
      method: 'post',
      url: `/lots/${lotId}/weighings`,
      data,
    });
  },
  deleteWeighing(lotId: number, id: number): ApiResponse<WrappedResult<any>> {
    return request({
      method: 'delete',
      url: `/lots/${lotId}/weighings/${id}`,
    });
  },

  getFumigations(lotId: number, params: cyrian.api.lots.LotFumigationParams): ApiResponse<WrappedResult<cyrian.api.lots.LotFumigation[]>> {
    return request({
      method: 'get',
      url: `/lots/${lotId}/fumigations`,
      params,
    });
  },
  createFumigation(lotId: number, data: cyrian.api.lots.LotFumigationParams): ApiResponse<WrappedResult<cyrian.api.lots.LotFumigation>> {
    return request({
      method: 'post',
      url: `/lots/${lotId}/fumigations`,
      data,
    });
  },
  deleteFumigation(lotId: number, id: number): ApiResponse<WrappedResult<any>> {
    return request({
      method: 'delete',
      url: `/lots/${lotId}/fumigations/${id}`,
    });
  },

  getPledges(lotId: number, params: cyrian.api.lots.LotPledgeParams): ApiResponse<WrappedResult<cyrian.api.lots.LotPledge[]>> {
    return request({
      method: 'get',
      url: `/lots/${lotId}/pledges`,
      params,
    });
  },
  createPledge(lotId: number, data: cyrian.api.lots.LotPledgeParams): ApiResponse<WrappedResult<cyrian.api.lots.LotPledge>> {
    return request({
      method: 'post',
      url: `/lots/${lotId}/pledges`,
      data,
    });
  },
  deletePledge(lotId: number, id: number): ApiResponse<WrappedResult<any>> {
    return request({
      method: 'delete',
      url: `/lots/${lotId}/pledges/${id}`,
    });
  },

  getProposals(id: number, params: cyrian.api.lots.GetEventParams): ApiResponse<WrappedResult<cyrian.api.lots.Proposal[]>> {
    return request({
      method: 'get',
      url: `/lots/${id}/proposals`,
      params,
    });
  },
  createProposal(id: number, data: cyrian.api.lots.ProposalCreateParams): ApiResponse<WrappedItemsResult<cyrian.api.lots.Proposal>> {
    return request({
      method: 'post',
      url: `/lots/${id}/proposals`,
      data,
    });
  },
  getProposal(id: number, proposalId: number): ApiResponse<WrappedResult<cyrian.api.lots.Proposal>> {
    return request({
      method: 'get',
      url: `/lots/${id}/proposals/${proposalId}`,
    });
  },
  updateProposal(id: number, proposalId: number, data: cyrian.api.lots.ProposalUpdateParams): ApiResponse<WrappedResult<cyrian.api.lots.Proposal>> {
    return request({
      method: 'patch',
      url: `/lots/${id}/proposals/${proposalId}`,
      data,
    });
  },
  checkNumbers(data: { numbers: number[] }): ApiResponse<WrappedResult<{ invalid: number[] }>> {
    return request({
      method: 'post',
      url: '/lots/check-numbers',
      data,
    });
  },
};
