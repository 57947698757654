import { Space } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { api } from '@/api';
import {
  DeleteAction,
  EditAction,
} from '@/components/table/actions';
import { getColumnSearchFields } from '@/components/table/filters';
import { AddressIcon } from '@/components/icons';
import {
  PERMISSION_READ,
  PERMISSION_DELETE,
  PERMISSION_OBJ_ADDRESS,
  PERMISSION_UPDATE,
} from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';
import { addressStore } from '@/stores';
import { AddressType } from '@/types/enums';
import { buildFilterOptionsFromEnum, showError } from '@/utils/common';

const PermissionedEditAction = withPermissions([[PERMISSION_OBJ_ADDRESS, PERMISSION_UPDATE]])(EditAction);
const PermissionedDeleteAction = withPermissions([[PERMISSION_OBJ_ADDRESS, PERMISSION_DELETE]])(DeleteAction);

const Actions = (_: any, record: any) => {
  const onDelete = () => {
    api.addresses.delete(record.id).source
      .then(() => addressStore.deleteItem(record.id))
      .catch(showError);
  };

  return (
    <Space>
      <PermissionedEditAction uiSref={{ to: 'base-layout.addresses.edit', params: { addressId: record.id } }} />
      <PermissionedDeleteAction onConfirm={onDelete} />
    </Space>
  );
};

export const columns = [
  {
    dataIndex: 'type',
    sorter: false,
    width: 50,
    render: (_, record) => <AddressIcon type={record?.type} />,
    permissions: [[`${PERMISSION_OBJ_ADDRESS}.type`, PERMISSION_READ]],
  },
  {
    title: <FormattedMessage id="addresses.name" />,
    dataIndex: 'name',
    ...getColumnSearchFields(),
    permissions: [[`${PERMISSION_OBJ_ADDRESS}.name`, 4]],
  },
  {
    title: <FormattedMessage id="addresses.type" />,
    dataIndex: 'type',
    filterMultiple: false,
    filters: buildFilterOptionsFromEnum(AddressType),
    permissions: [[`${PERMISSION_OBJ_ADDRESS}.type`, PERMISSION_READ]],
  },
  {
    title: <FormattedMessage id="addresses.country" />,
    dataIndex: 'country',
    sorter: true,
    permissions: [[`${PERMISSION_OBJ_ADDRESS}.country`, PERMISSION_READ]],
  },
  {
    title: <FormattedMessage id="addresses.city" />,
    dataIndex: 'city',
    sorter: true,
    permissions: [[`${PERMISSION_OBJ_ADDRESS}.city`, PERMISSION_READ]],
  },
  {
    title: <FormattedMessage id="addresses.street" />,
    dataIndex: 'street',
    sorter: true,
    permissions: [[`${PERMISSION_OBJ_ADDRESS}.street`, PERMISSION_READ]],
  },
  {
    title: <FormattedMessage id="addresses.street2" />,
    dataIndex: 'street2',
    sorter: true,
    permissions: [[`${PERMISSION_OBJ_ADDRESS}.street2`, PERMISSION_READ]],
  },
  {
    title: "",
    render: Actions,
    className: "actions",
  },
];
