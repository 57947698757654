import {
  PERMISSION_CREATE,
  PERMISSION_OBJ_BOARDING,
  PERMISSION_READ,
  PERMISSION_UPDATE,
} from '@/constants/permissions';

import { BoardingForm } from './components/form';
import { LotsSelector } from './components/selector';
import { Boardings } from './components/table';

export const states = [
  {
    name: 'base-layout.boardings',
    url: '/boardings',
    component: Boardings,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_BOARDING, PERMISSION_READ],
      ],
    },
  },
  {
    name: 'base-layout.boardings.create',
    url: '/new',
    component: BoardingForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_BOARDING, PERMISSION_CREATE],
      ],
    },
  },
  {
    name: 'base-layout.boardings.edit',
    url: '/:boardingId/edit',
    component: BoardingForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_BOARDING, PERMISSION_UPDATE],
      ],
    },
  },
  {
    name: 'base-layout.boardings.lots',
    url: '/:boardingId/lots',
    component: LotsSelector,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_BOARDING, PERMISSION_UPDATE],
      ],
    },
  },
];
