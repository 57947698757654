import { Space } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { api } from '@/api';
import {
  AttachmentAction,
  DeleteAction,
  EditAction,
} from '@/components/table/actions';
import {
  PERMISSION_DELETE,
  PERMISSION_OBJ_CV_EXECUTION,
  PERMISSION_UPDATE,
} from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';
import { cvExecutionStore } from '@/stores';
import { showError } from '@/utils/common';
import { getFormattedDate } from '@/utils/moment';
import { getFormattedPriceWithCurrency, getFormattedVolume } from '@/utils/formatters';
import { getFormattedRangeDate } from '@/utils/moment';
import { FixedType } from 'rc-table/lib/interface';

const PermissionedEditAction = withPermissions([[PERMISSION_OBJ_CV_EXECUTION, PERMISSION_UPDATE]])(EditAction);
const PermissionedAttachmentAction = withPermissions([[PERMISSION_OBJ_CV_EXECUTION, PERMISSION_UPDATE]])(AttachmentAction);
const PermissionedDeleteAction = withPermissions([[PERMISSION_OBJ_CV_EXECUTION, PERMISSION_DELETE]])(DeleteAction);

const Actions = (_: any, record: any) => {
  const onDelete = () => {
    api.cvExecutions.delete(record.id).source
      .then(() => cvExecutionStore.deleteItem(record.id))
      .catch(showError);
  };

  return (
    <Space>
      <PermissionedEditAction
        uiSref={{
          to: 'base-layout.cv-executions.edit',
          params: { cvExecutionId: record.id },
        }} />
      <PermissionedAttachmentAction uiSref={{ to: 'base-layout.cv-executions.attachments', params: { cvExecutionId: record.id } }} />
      <PermissionedDeleteAction onConfirm={onDelete} />
    </Space>
  );
};

export const columns = [
  {
    title: <FormattedMessage id="cv-executions.unlocking-date" />,
    dataIndex: ['cv', 'unlockingDate'],
    width: 155,
    sorter: true,
    render: getFormattedDate,
  },
  {
    title: <FormattedMessage id="cv-executions.cv-unlocking" />,
    dataIndex: ['cv', 'number'],
    width: 150,
    sorter: true,
  },
  {
    title: <FormattedMessage id="cv-executions.cv-execution" />,
    dataIndex: 'number',
    width: 150,
    sorter: true,
  },
  {
    title: <FormattedMessage id="cv-executions.unlocking-period" />,
    dataIndex: ['cv', 'period'],
    width: 150,
    render: getFormattedRangeDate,
  },
  {
    title: <FormattedMessage id="cv-executions.execution-period" />,
    dataIndex: 'period',
    width: 150,
    render: getFormattedRangeDate,
  },
  {
    title: <FormattedMessage id="cv-executions.volume" />,
    dataIndex: 'volume',
    width: 100,
    sorter: true,
    render: getFormattedVolume,
  },
  {
    title: <FormattedMessage id="cv-executions.counterparty" />,
    dataIndex: ['counterparty', 'name'],
    sorter: true,
  },
  {
    title: <FormattedMessage id="cv-executions.campaign" />,
    dataIndex: ['campaign', 'name'],
    sorter: true,
  },
  {
    title: <FormattedMessage id="cv-executions.price" />,
    dataIndex: 'price',
    sorter: true,
    render: getFormattedPriceWithCurrency,
  },
  {
    title: '',
    render: Actions,
    className: 'actions',
  },
];
