import { Space } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { api } from '@/api';
import {
  DeleteAction,
  EditAction,
  ReportAction,
} from '@/components/table/actions';
import {
  PERMISSION_DELETE,
  PERMISSION_OBJ_TRANSIT_ORDER,
  PERMISSION_UPDATE,
} from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';
import { transitOrderStore } from '@/stores';
import { showError } from '@/utils/common';
import { getFormattedDate } from '@/utils/moment';

const PermissionedEditAction = withPermissions([[PERMISSION_OBJ_TRANSIT_ORDER, PERMISSION_UPDATE]])(EditAction);
const PermissionedSelectorAction = withPermissions([[PERMISSION_OBJ_TRANSIT_ORDER, PERMISSION_UPDATE]])(EditAction);
const PermissionedDeleteAction = withPermissions([[PERMISSION_OBJ_TRANSIT_ORDER, PERMISSION_DELETE]])(DeleteAction);

const Actions = (_: any, record: any) => {
  const onDelete = () => {
    api.transitOrders.delete(record.id).source
      .then(() => transitOrderStore.deleteItem(record.id))
      .catch(showError);
  };

  const onReportClick = () => {
    api.transitOrders.report(record.id).source
      .then((data) => {
        const url = URL.createObjectURL(data);
        window.open(url);
      })
      .catch(showError);
  };

  return (
    <Space>
      <PermissionedEditAction uiSref={{ to: 'base-layout.transit-orders.edit', params: { transitOrderId: record.id } }} />
      <PermissionedSelectorAction uiSref={{ to: 'base-layout.transit-orders.lots', params: { transitOrderId: record.id } }}
                                  title="Lots" />
      <PermissionedDeleteAction onConfirm={onDelete} />
      <ReportAction onClick={onReportClick} />
    </Space>
  );
};

export const columns = [
  {
    title: 'id',
    dataIndex: 'id',
    sorter: true,
  },
  {
    title: <FormattedMessage id={'transit-orders.boarding'} />,
    dataIndex: ['boarding', 'number'],
  },
  {
    title: <FormattedMessage id={'transit-orders.order-number'} />,
    dataIndex: 'orderNumber',
    sorter: true,
  },
  {
    title: <FormattedMessage id={'transit-orders.booking-number'} />,
    dataIndex: 'bookingNumber',
    sorter: true,
  },
  {
    title: <FormattedMessage id={'transit-orders.bill-of-lading-number'} />,
    dataIndex: 'billOfLadingNumber',
    sorter: true,
  },
  {
    title: <FormattedMessage id={'transit-orders.ship-name'} />,
    dataIndex: 'shipName',
    sorter: true,
  },
  {
    title: <FormattedMessage id={'transit-orders.eta-date'} />,
    dataIndex: 'etaDate',
    render: getFormattedDate,
  },
  {
    title: <FormattedMessage id={'transit-orders.available-bv-volume'} />,
    dataIndex: ['statistics', 'availableBvVolumeForFormula'],
  },
  {
    title: <FormattedMessage id={'transit-orders.formulas-launched'} />,
    dataIndex: ['statistics', 'formulasLaunched'],
  },
  {
    title: <FormattedMessage id={'transit-orders.net-weight'} />,
    dataIndex: ['statistics', 'netWeight'],
  },
  {
    title: <FormattedMessage id={'transit-orders.gross-weight-diff'} />,
    dataIndex: ['statistics', 'netWeightGrossWeightDiff'],
  },
  {
    title: <FormattedMessage id={'transit-orders.theoretical-weight'} />,
    dataIndex: ['statistics', 'theoreticalWeight'],
  },
  {
    title: "",
    render: Actions,
    className: "actions",
  },
];
