import { ContractForm } from './components/form';
import { Contracts } from './components/table';
import { CvExecutionsSelector } from './components/selector';

import {
  PERMISSION_CREATE,
  PERMISSION_OBJ_COUNTERPARTY_CONTRACT,
  PERMISSION_READ,
  PERMISSION_UPDATE,
} from '@/constants/permissions';

export const states = [
  {
    name: 'base-layout.counterparty-contracts',
    url: '/counterparty-contracts',
    component: Contracts,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_COUNTERPARTY_CONTRACT, PERMISSION_READ],
      ],
    },
  },
  {
    name: 'base-layout.counterparty-contracts.create',
    url: '/new',
    component: ContractForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_COUNTERPARTY_CONTRACT, PERMISSION_CREATE],
      ],
    },
  },
  {
    name: 'base-layout.counterparty-contracts.edit',
    url: '/:counterpartyContractId/edit',
    component: ContractForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_COUNTERPARTY_CONTRACT, PERMISSION_UPDATE],
      ],
    },
  },
  {
    name: 'base-layout.counterparty-contracts.cv-executions',
    url: '/:counterpartyContractId/cv-executions',
    component: CvExecutionsSelector,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_COUNTERPARTY_CONTRACT, PERMISSION_UPDATE],
      ],
    },
  },
];
