import { UISref } from '@uirouter/react';
import { Button, Form } from 'antd';
import React, { useEffect, useState } from 'react';

import { samlProviders } from '@/api/auth';
import { AUTHORIZED_USER_INITIAL_STATE } from '@/constants';
import { authStore } from '@/stores';

import { AuthLayout } from './AuthLayout';

const SamlLogin = ({ onSuccessLogin }: any) => {
  const [providers, setProviders] = useState<string[]>([]);

  useEffect(() => {
    const load = async () => {
      const result = await samlProviders();
      setProviders(result);
    };
    load();
  }, []);

  const onSignInWithGoogleClick = async (provider: string) => {
    await authStore.loginWithSaml(provider);
    onSuccessLogin();
  };

  return (
    <AuthLayout>
      <Form
        name="login"
        requiredMark="optional"
      >
        <Form.Item>
          {
            providers.filter((provider: string) => provider === 'default').map((provider) => {
              const onClick = () => onSignInWithGoogleClick(provider);

              return (
                <Button
                  style={{ marginBottom: '1rem' }}
                  key={provider}
                  className="auth-button"
                  htmlType="button"
                  icon={<span className="fa-google-wrapper"/>}
                  onClick={onClick}
                >
                  Sign In
                </Button>
              );
            })
          }
          <div className="auth-link">
            <UISref to={'auth-login-advanced'}>
              <a>Advanced Login</a>
            </UISref>
          </div>
        </Form.Item>
      </Form>
    </AuthLayout>
  );
};

export const AuthSamlLoginForm = (props: any) => {
  const onSuccessLogin = () => props.transition.router.stateService.go(AUTHORIZED_USER_INITIAL_STATE);
  return (<SamlLogin onSuccessLogin={onSuccessLogin} />);
};
