import { ProposalStatus } from '@/types/enums';

export const proposalState: Record<string, string[]> = {
  [ProposalStatus.Pending]: [
    ProposalStatus.Accepted,
    ProposalStatus.Rejected,
    ProposalStatus.Withdrawn,
  ],
  [ProposalStatus.Accepted]: [],
  [ProposalStatus.Rejected]: [ProposalStatus.Withdrawn],
  [ProposalStatus.Withdrawn]: [],
};
