export const getFormFields = () => [
  {
    name: 'number',
    componentName: 'input',
    params: {
      i18nLabel: 'bvs.number',
    },
    rules: [
      { required: true },
      { max: 20 },
    ],
  },
];
