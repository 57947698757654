export const getFormFields = () => {
  return [
    {
      name: 'name',
      componentName: 'input',
      params: {
        i18nLabel: 'campaigns.name',
      },
      rules: [
        { required: true },
        { max: 255 },
      ],
    },
    {
      name: 'year',
      componentName: 'input',
      params: {
        i18nLabel: 'campaigns.year',
      },
      rules: [
        { required: true },
        { max: 4 },
      ],
    },
  ];
};
