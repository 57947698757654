import { Dispatch, SetStateAction } from 'react';

export const getFormFields = (
  lots: cyrian.api.boardings.Lot[],
  targetLots: number[],
  setTargetLots: Dispatch<SetStateAction<number[]>>,
) => {
  const onChange = (targetKeys: number[]) => {
    setTargetLots(targetKeys);
  };

  return [
    {
      name: 'lots',
      componentName: 'transfer',
      params: {
        style: { justifyContent: 'center' },
        rowKey: (({ id }) => id),
        dataSource: lots,
        titles: ['Source', 'Target'],
        targetKeys: targetLots,
        listStyle: {
          height: window.innerHeight * 0.5,
        },
        onChange,
        render: item => item.number,
      },
    },
  ];
};
