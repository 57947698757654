import { Card } from 'antd';
import React from 'react';

import { FormBuilderOptionItem, GenericForm } from '@/components/generic-form';
import { getTranslatedString } from '@/utils';
import { getFormattedPriceWithCurrency } from '@/utils/formatters'
import { getFormattedDate, getFormattedRangeDate } from '@/utils/moment';

const getDisabledFields = (fields: FormBuilderOptionItem[]) => fields.map((item: FormBuilderOptionItem) => ({
  ...item,
  params: { ...item.params, readOnly: true, style: { marginBottom: 5 } },
}));

export const CardReadOnlyForm = ({ i18nTitle, initialValues, formFields }) => {
  const getNormalizedBvValues = (values: any) => {
    return values
      ? {
        ...values,
        ...(
          values.hasOwnProperty('acceptanceDate')
          && values.acceptanceDate
          && { acceptanceDate: getFormattedDate(values.acceptanceDate) }
        ),
        ...(
          values.hasOwnProperty('expirationDate')
          && values.expirationDate
          && { expirationDate: getFormattedDate(values.expirationDate) }
        ),
        period: values?.period && getFormattedRangeDate(values?.period),
        price: values?.price && getFormattedPriceWithCurrency(values?.price, null),
      }
      : {};
  };

  const layout = {
    labelCol: {},
    wrapperCol: {},
  };

  return <Card
    className="card-shadow"
    title={getTranslatedString(i18nTitle)}
    bordered={false}
    size="small"
  >
    <GenericForm
      {...layout}
      initialValues={getNormalizedBvValues(initialValues)}
      formFields={getDisabledFields(formFields)}
    />
  </Card>;
};
