import React from 'react';

import { api } from '@/api';
import { DrawerForm, DrawerFormProps } from '@/components/drawer-form';
import { NoteObjectType } from '@/types/enums';

interface DrawerFormWithNoteProps extends DrawerFormProps {
  objectType: NoteObjectType;
}

const noteFormField = {
  name: 'note',
  componentName: 'textarea',
  params: {
    i18nLabel: 'common.note',
  },
};

export const DrawerFormWithNote = ({ objectType, ...props }: DrawerFormWithNoteProps) => {
  const createNote = (data: { id: number }, text: string) => text
    ? api.notes.create({ objectId: data?.id, objectType, text }).source
    : Promise.resolve();

  const resourceController = {
    create: (values: any) => props.resourceController.create(values)
      .then((data: { id: number }) => createNote(data, values.note)),
    update: (values: any) => props.resourceController.update(values)
      .then((data: { id: number }) => createNote(data, values.note)),
  };

  return <DrawerForm
    {...props}
    formFields={[...props.formFields, noteFormField]}
    resourceController={resourceController}
  />;
};
