import { UIViewInjectedProps } from '@uirouter/react';
import React from 'react';

import { Attachments } from '@/components/attachments';
import { AttachmentType } from '@/types/enums';

export const TransferAttachments = ({ transition }: UIViewInjectedProps) => {
  const params = {
    transition,
    apiEntity: 'Transfer',
    paramId: 'transferId',
    attachmentType: AttachmentType.Transfer,
    closeRoute: 'base-layout.transfers'
  };

  return <Attachments<cyrian.api.transfers.TransferWithAttachment> {...params} />;
};
