import React from 'react';
import {
  faAnchor,
  faIndustryAlt,
  faMapMarker,
  faShip,
  faWarehouseAlt,
  faBuilding,
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface AddressIconProps {
  type: cyrian.api.addresses.Type;
}

export const AddressIcon = ({ type }: AddressIconProps) => {
  switch (type) {
    case 'Factory':
      return <FontAwesomeIcon
        icon={faIndustryAlt}
        transform="right-12"
        style={{
          '--fa-primary-opacity': 0.5,
          '--fa-secondary-opacity': 0.8,
          '--fa-primary-color': 'red',
          '--fa-secondary-color': 'green',
        } as React.CSSProperties} />;
    case 'Boat':
      return <FontAwesomeIcon
        className="fa-1x"
        icon={faShip}
        transform="right-10"
        style={{ color: 'grey' }} />;
    case 'Store':
      return <FontAwesomeIcon
        icon={faWarehouseAlt}
        transform="right-10"
        style={{
          '--fa-primary-opacity': 0.5,
          '--fa-secondary-opacity': 0.8,
          '--fa-primary-color': 'grey',
          '--fa-secondary-color': 'brown',
        } as React.CSSProperties}
      />;
    case 'Harbor':
      return (
        <span style={{ fontSize: '1rem' }}>
          <FontAwesomeIcon icon={faMapMarker} className="fa-stack-2x" style={{ color: 'grey' }}/>
          <FontAwesomeIcon icon={faAnchor} className="fa-stack-1x" transform="shrink-2 up-4" style={{ color: 'white' }}/>
        </span>
      );
    default:
      return (
        <FontAwesomeIcon
          icon={faBuilding}
          transform="right-14"
          style={{
            '--fa-primary-color': 'grey',
            '--fa-secondary-color': 'grey'
          } as React.CSSProperties}/>
      );
  }
}
