import { counterpartyStore, currencyStore } from '@/stores';
import { ProductQuality } from '@/types/enums';
import { buildDropdownOptionsFromEnum, buildStoreOptions, productOptions } from '@/utils/common';
import { getFormattedVolume } from '@/utils/formatters';
import { getParsedVolume } from '@/utils/parsers';

export const getFormFields = () => {
  return [
    {
      name: 'counterpartyId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'counterparty-contracts.client',
        options: buildStoreOptions(counterpartyStore, 'name'),
      },
      rules: [{ required: true }],
    },
    {
      name: 'productId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'contract-product.product',
        options: productOptions(),
      },
      rules: [{ required: true }],
    },
    {
      name: 'clientContract',
      componentName: 'input',
      params: {
        i18nLabel: 'counterparty-contracts.client-contract',
      },
      rules: [
        { required: true },
        { max: 50 },
      ],
    },
    {
      name: 'shipownerContract',
      componentName: 'input',
      params: {
        i18nLabel: 'counterparty-contracts.shipping-contract',
      },
      rules: [
        { required: true },
        { max: 50 },
      ],
    },
    {
      name: 'quality',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'contract-product.quality',
        options: buildDropdownOptionsFromEnum(ProductQuality),
      },
      rules: [{ required: true }],
    },
    {
      name: 'gr',
      componentName: 'input-number',
      params: {
        i18nLabel: 'contract-product.gr',
        min: 0,

      },
      rules: [{ required: true }],
    },
    {
      name: 'volume',
      componentName: 'input-number',
      params: {
        i18nLabel: 'counterparty-contracts.volume',
        min: 0,
        formatter: getFormattedVolume,
        parser: getParsedVolume,
      },
      rules: [{ required: true }],
    },
    {
      name: 'price',
      componentName: 'input-with-dropdown',
      params: {
        i18nLabel: 'counterparty-contracts.price',
        min: 0,
        options: buildStoreOptions(currencyStore, 'code'),
      },
      rules: [{ required: false }],
    },
  ];
};
