import { UIViewInjectedProps } from '@uirouter/react';
import React from 'react';

import { Attachments } from '@/components/attachments';
import { AttachmentType } from '@/types/enums';

export const WeighingAttachments = ({ transition }: UIViewInjectedProps) => {
  const params = {
    transition,
    apiEntity: 'LotWeighing',
    paramId: 'weighingId',
    attachmentType: AttachmentType.LotWeighing,
    closeRoute: 'base-layout.lots.view',
  };

  return <Attachments<cyrian.api.lots.LotWeighing & { attachments: any[]; }> {...params} />;
};
