import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UIView } from '@uirouter/react';
import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Table } from '@/components/table';
import {
  PERMISSION_CREATE,
  PERMISSION_OBJ_ADDRESS,
  PERMISSION_READ,
} from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';
import { addressStore } from '@/stores';
import { useMassUpdateCashedStores } from '@/utils/store';

import { columns } from './setup';

const entity = 'addresses';
const store = addressStore;

const CreateButton = withPermissions([
  [PERMISSION_OBJ_ADDRESS, PERMISSION_CREATE],
])(({ onClick }: any) => {
  return (
    <Button onClick={onClick} type="primary" icon={<FontAwesomeIcon icon={faPlus} />}>
      &nbsp;&nbsp;<FormattedMessage id="addresses.add-new" />
    </Button>
  );
});

export const Addresses = observer(({ transition }: any) => {
  const items = store.items;

  useMassUpdateCashedStores([addressStore]);

  const onAdd = () => {
    transition.router.stateService.go(`base-layout.${entity}.create`);
  };

  return (
    <>
      <CreateButton onClick={onAdd} />
      <Table
        rowKey="id"
        columns={columns}
        dataSource={[...items]}
        store={store}
        checkPermissions={true}
      />
      <UIView />
    </>
  );
});
