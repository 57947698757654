import { Form } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { Dispatch, SetStateAction } from 'react';

import { api } from '@/api';
import { DrawerForm } from '@/components/drawer-form';
import { getAllowedFormFields } from '@/components/generic-form/utils';
import { PERMISSION_OBJ_PROPOSAL } from '@/constants/permissions';
import { useContracts } from '@/pages/proposal/components/create/hooks';
import { counterpartyContractStore, counterpartyStore, lotStore } from '@/stores';
import { getTranslatedString } from '@/utils';
import { showError } from '@/utils/common';
import { withoutRepeatValue } from '@/utils/controller';
import { useMassUpdateCashedStores } from '@/utils/store';

import { getFormFields } from './setup';

interface MassCreateFormProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  setNestedData: Dispatch<SetStateAction<object>>;
  selectedLots: cyrian.api.lots.Lot[];
}

export const MassCreateForm = observer(
  ({
     visible,
     setVisible,
     setNestedData,
     selectedLots,
   }: MassCreateFormProps) => {
    const [form] = Form.useForm();
    const { loading } = useMassUpdateCashedStores([counterpartyStore, counterpartyContractStore]);
    const { customerContracts, onCustomerChange } = useContracts(form);

    const onClose = () => {
      setVisible(false);
      form.resetFields();
    };

    const initValues = {
      selectedLots: selectedLots.map(({ number }) => number).join(', '),
    };

    const title = getTranslatedString('proposal.add-many');
    const formFields = getFormFields(customerContracts, onCustomerChange, selectedLots);
    const allowedFields = getAllowedFormFields(formFields, true, PERMISSION_OBJ_PROPOSAL);

    const resourceController = withoutRepeatValue(initValues, {
      create: async (values: any) => {
        const createProposal = async (selectedLot: cyrian.api.lots.Lot) => {
          try {
            const { data } = await api.lots.createProposal(selectedLot.id, values).source;
            setNestedData(state => ({
              ...state,
              [selectedLot.id]: [...state[selectedLot.id] || [], data],
            }));
          } catch (e) {
            showError(e);
          }
        };

        delete values.selectedLots;

        for (const selectedLot of selectedLots) {
          await createProposal(selectedLot);
        }

        return lotStore.refresh();
      },
    });

    return (
      <DrawerForm
        visible={visible}
        form={form}
        title={title}
        initValues={initValues}
        formFields={allowedFields}
        onClose={onClose}
        resourceController={resourceController}
        loaderCondition={loading || !selectedLots.length}
        useDisableSubmit={false}
      />
    );
  });
