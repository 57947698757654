import { AUTHORIZED_USER_INITIAL_STATE } from '@/constants';
import { authStore } from '@/stores';

export const authHook = {
  criteria: {
    to: (state: any) => ['auth-login', 'auth-login-advanced', 'auth-signup'].includes(state.name),
  },
  callback: async (transition: any) => {
    const $state = transition?.router?.stateService;

    if (!authStore.isAuthorized()) {
      await authStore.checkSession();

      if (authStore.loggedIn) {
        return $state.target(AUTHORIZED_USER_INITIAL_STATE, undefined, { location: true });
      }
      return true;
    }

    return true;
  },
};
