import { faFunnelDollar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Space } from 'antd';
import { TableRowSelection } from 'antd/lib/table/interface';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { api } from '@/api';
import { Table } from '@/components/table';
import { PERMISSION_OBJ_COUNTERPARTY_CONTRACT, PERMISSION_READ } from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';
import { MassCreateForm } from '@/pages/proposal/components/create/multiple';
import { UpdateStatusForm } from '@/pages/proposal/components/update';
import { lotStore } from '@/stores';
import { showError } from '@/utils/common';
import { useMassUpdateCashedStores } from '@/utils/store';

import { CreateForm } from '../create/';
import { getColumns, getExpandedRowColumns } from './setup';

const store = lotStore;

const CreateButton = withPermissions([[PERMISSION_OBJ_COUNTERPARTY_CONTRACT, PERMISSION_READ]])(({ onClick, disabled }: any) => {
  return (
    <Button onClick={onClick} type="primary" icon={<FontAwesomeIcon icon={faFunnelDollar} />} disabled={disabled}>
      &nbsp;&nbsp;<FormattedMessage id="proposal.add-many" />
    </Button>
  );
});

export const Lots = observer(() => {
  const lots = store.items;

  const [nestedData, setNestedData] = useState<object>({});
  const [isLoading, setIsLoading] = useState<object>({});
  const [selectedProposal, setSelectedProposal] = useState<cyrian.api.lots.Proposal & { lotId: number } | null>(null);
  const [selectedLotId, setSelectedLotId] = useState<number>(0);
  const [updateProposalFormVisible, setUpdateProposalFormVisible] = useState<boolean>(false);
  const [createProposalFormVisible, setCreateProposalFormVisible] = useState<boolean>(false);
  const [massCreateProposalFormVisible, setMassCreateProposalFormVisible] = useState<boolean>(false);
  const [selectedLots, setSelectedLots] = useState<cyrian.api.lots.Lot[]>([]);

  useMassUpdateCashedStores([lotStore]);

  const onAddButton = (id: number) => {
    setCreateProposalFormVisible(true);
    setSelectedLotId(id);
  };

  const onEditButton = (record: cyrian.api.lots.Proposal & { lotId: number }) => {
    setUpdateProposalFormVisible(true);
    setSelectedProposal(record);
  };

  const expandedRowRender = (record: cyrian.api.lots.Proposal) => {
    const data = nestedData[record.id] && nestedData[record.id]
      .map((proposal: any) => ({ ...proposal, lotId: record.id }));

    return (
      <Table
        rowKey="id"
        loading={isLoading[record.id] && !data}
        columns={getExpandedRowColumns(onEditButton)}
        dataSource={data}
        pagination={false}
      />
    );
  };

  const handleExpand = (expanded: boolean, record: cyrian.api.lots.Lot) => {
    if (!expanded) {
      return;
    }

    setIsLoading({
      [record.id]: true,
    });

    api.lots.getProposals(record.id).source
      .then(({ data }) => {
        setNestedData(state => ({
          ...state,
          [record.id]: data.items,
        }));
      })
      .catch(showError)
      .finally(() => {
        setIsLoading({
          [record.id]: false,
        });
      });
  };

  const onRowSelectionChange = (selectedRowKeys: number[], selectedLots: cyrian.api.lots.Lot[]) => {
    setSelectedLots(selectedLots);
  };

  const rowSelection: TableRowSelection<cyrian.api.lots.Lot> = {
    preserveSelectedRowKeys: true,
    onChange: onRowSelectionChange,
  };

  return (
    <>
      <Space style={{ paddingBottom: 10 }}>
        <CreateButton
          onClick={() => setMassCreateProposalFormVisible(true)}
          disabled={!selectedLots.length}
        />
      </Space>
      <Table
        rowKey="id"
        columns={getColumns(onAddButton)}
        dataSource={[...lots]}
        store={store}
        expandedRowRender={expandedRowRender}
        onExpand={handleExpand}
        loading={store.loading}
        rowSelection={rowSelection}
      />
      <MassCreateForm
        visible={massCreateProposalFormVisible}
        setVisible={setMassCreateProposalFormVisible}
        selectedLots={selectedLots}
        setNestedData={setNestedData}
      />
      <CreateForm
        visible={createProposalFormVisible}
        setVisible={setCreateProposalFormVisible}
        selectedLotId={selectedLotId}
        setNestedData={setNestedData}
      />
      <UpdateStatusForm
        key={selectedProposal?.id + selectedProposal?.status}
        visible={updateProposalFormVisible}
        setVisible={setUpdateProposalFormVisible}
        selectedProposal={selectedProposal}
        handleExpand={handleExpand}
      />
    </>
  );
});
