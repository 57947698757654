import { request } from '@/utils/request';
import { ApiResponse, WrappedItemsResult, WrappedResult } from './types';

export default {
  getAll(params?: cyrian.api.counterpartyContracts.GetParams): ApiResponse<WrappedResult<cyrian.api.counterpartyContracts.CounterpartyContract[]>> {
    return request({
      method: 'get',
      url: '/counterparty-contracts',
      params,
    });
  },
  create(data: cyrian.api.counterpartyContracts.CounterpartyContractParams): ApiResponse<WrappedResult<cyrian.api.counterpartyContracts.CounterpartyContract>> {
    return request({
      method: 'post',
      url: '/counterparty-contracts',
      data,
    });
  },
  update(id: number, data: cyrian.api.counterpartyContracts.CounterpartyContractParams): ApiResponse<WrappedResult<cyrian.api.counterpartyContracts.CounterpartyContract>> {
    return request({
      method: 'patch',
      url: `/counterparty-contracts/${id}`,
      data,
    });
  },
  delete(id: number): ApiResponse<WrappedResult<any>> {
    return request({
      method: 'delete',
      url: `/counterparty-contracts/${id}`,
    });
  },
  createLineItem(id: number, data: cyrian.api.counterpartyContracts.LineItemParams): ApiResponse<WrappedResult<cyrian.api.counterpartyContracts.LineItem>> {
    return request({
      method: 'post',
      url: `/counterparty-contracts/${id}/line-items`,
      data,
    });
  },
  updateLineItem(id: number, lineItemId: number, data: cyrian.api.counterpartyContracts.LineItemParams): ApiResponse<WrappedResult<cyrian.api.counterpartyContracts.LineItem>> {
    return request({
      method: 'patch',
      url: `/counterparty-contracts/${id}/line-items/${lineItemId}`,
      data,
    });
  },
  deleteLineItem(id: number, lineItemId: number): ApiResponse<WrappedResult<any>> {
    return request({
      method: 'delete',
      url: `/counterparty-contracts/${id}/line-items/${lineItemId}`,
    });
  },
  getAvailableCvExecutions(id: number): ApiResponse<WrappedItemsResult<cyrian.api.counterpartyContracts.CvExecution[]>> {
    return request({
      method: 'get',
      url:`/counterparty-contracts/${id}/available-cv-executions`,
    });
  },
  addCvExecutions(id: number, data: cyrian.api.counterpartyContracts.CvExecutionsParam): ApiResponse<WrappedResult<cyrian.api.counterpartyContracts.CounterpartyContract>> {
    return request({
      method: 'post',
      url:`/counterparty-contracts/${id}/add-cv-executions`,
      data,
    });
  },
  removeCvExecutions(id: number, data: cyrian.api.counterpartyContracts.CvExecutionsParam): ApiResponse<WrappedResult<cyrian.api.counterpartyContracts.CounterpartyContract>> {
    return request({
      method: 'post',
      url:`/counterparty-contracts/${id}/remove-cv-executions`,
      data,
    });
  },
};
