import React from 'react';

import { getTranslatedString } from '@/utils';

export const Admin = () => {
  return (
    <>
      {getTranslatedString('admin.title')}
    </>
  );
};
