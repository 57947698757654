import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { UIView } from '@uirouter/react';
import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Table } from '@/components/table';
import {
  PERMISSION_CREATE,
  PERMISSION_OBJ_FORMULA
} from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';
import { formulaStore } from '@/stores';
import { useMassUpdateCashedStores } from '@/utils/store';

import { columns } from './setup';

const entity = 'formulas';
const store = formulaStore;

const CreateButton = withPermissions([[PERMISSION_OBJ_FORMULA, PERMISSION_CREATE]])(({ onClick, i18nTitle }: any) => {
  return (
    <Button onClick={onClick} type="primary" icon={<FontAwesomeIcon icon={faPlus} />}>
      <FormattedMessage id="formulas.add-new" />
    </Button>
  );
});

export const Formulas = observer(({ transition }: any) => {
  const items = store.items;

  useMassUpdateCashedStores([formulaStore]);

  const onAdd = () => {
    transition.router.stateService.go('base-layout.formulas.create');
  };

  const onRow = (record: cyrian.api.formulas.Formula) => ({
    onClick: () => transition.router.stateService.go('base-layout.formulas.view', { formulaId: record.id }),
    className: 'table-row-cursor',
  });

  return (
    <>
      <CreateButton onClick={onAdd} />
      <Table
        loading={store.loading}
        rowKey="id"
        columns={columns}
        dataSource={[...items]}
        store={store}
        onRow={onRow}
        scroll={{ x: 2500 }}
      />
      <UIView />
    </>
  );
});
