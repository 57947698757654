import moment from 'moment';

export const normalizedData = (values: any) => {
  const format = (range: any[]) => {
    return {
      from: {
        value: range,
        include: true,
      },
      to: {
        value: moment(range).add(3, 'M'),
        include: true,
      },
    };
  };

  return {
    ...values,
    ...(values.hasOwnProperty('period') && { period: format(values.period) }),
    ...(values.hasOwnProperty('unlockingDate') && { unlockingDate: values.unlockingDate.format() }),
  };
};
