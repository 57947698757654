import { faBars, faIndent, faOutdent, faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UISref } from '@uirouter/react';
import { Avatar, Button, Col, Dropdown, Layout as AntdLayout, Menu, Row } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Search } from '@/components/header/search';
import { PERMISSION_OBJ_ROLE, PERMISSION_READ } from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';
import { VoidFnType } from '@/types/common';

import { RoleSwitcher } from '../role-switcher';
import logo from '@/assets/images/cyrian-logo.png';

interface HeaderProps {
  collapsed: boolean;
  toggle: VoidFnType;
}

const menu = (
  <Menu>
    <Menu.Item>
      <UISref to="base-layout.profile">
        <a className="header-profile-link">
          <FontAwesomeIcon icon={faUser} />
          <FormattedMessage id={'profile.header'} />
        </a>
      </UISref>
    </Menu.Item>

    <Menu.Item>
      <UISref to={'auth-logout'}>
        <a className="header-profile-link">
          <FontAwesomeIcon icon={faSignOutAlt} />
          <FormattedMessage id={'profile.logout'} />
        </a>
      </UISref>
    </Menu.Item>
  </Menu>
);

const PermissionedRoleSwitcher = withPermissions([[PERMISSION_OBJ_ROLE, PERMISSION_READ]])(() => (
  <Col flex="0 0 250px">
    <RoleSwitcher />
  </Col>
));

export const Header = ({ collapsed, toggle, ...rest }: HeaderProps) => {
  return (
    <AntdLayout.Header className="header">
      <Row align="middle" wrap={false}>
        <Col flex="0 0 200px">
          <Row align="middle">
            <div className="trigger" onClick={toggle}>
              <FontAwesomeIcon icon={faBars} style={{ color: '#333333' }} />
            </div>
            <div className="logo">
              <img src={logo} alt="Cyrian logo"></img>
            </div>
          </Row>
        </Col>

        <Col flex="0 0 70px">
          <UISref to="base-layout.home">
            <a><h2 className="header-title">Cyrian</h2></a>
          </UISref>
        </Col>

        <Col flex="1 1 450px">
          <Search />
        </Col>

        <PermissionedRoleSwitcher />

        <Col flex="0 0 56px">
          <Dropdown overlay={menu}>
            <Avatar size={'large'} icon={<FontAwesomeIcon icon={faUser} />} />
          </Dropdown>
        </Col>
      </Row>
    </AntdLayout.Header>
  );
};
