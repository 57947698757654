import { counterpartyStore } from '@/stores';
import { CounterpartyType } from '@/types/enums';

const buildOptions = (store: any, labelKey: string) => {
  return store.items
    .filter(({ type }: cyrian.api.counterparties.Counterparty) => type === CounterpartyType.Customer)
    .map((item: cyrian.api.counterparties.Counterparty) => ({ value: item.id, label: item[labelKey] }));
};

export const getFormFields = (
  customerContractOptions: any[],
  onCustomerChange: (id: number) => void,
  selectedLots: cyrian.api.lots.Lot[] = [],
) => [
  {
    name: 'selectedLots',
    componentName: 'input',
    params: {
      i18nLabel: 'proposal.selected-lots',
      readOnly: true,
    },
    conditionalRender: () => Boolean(selectedLots.length),
  },
  {
    name: 'counterpartyId',
    componentName: 'dropdown',
    params: {
      i18nLabel: 'proposal.customer',
      options: buildOptions(counterpartyStore, 'name'),
      onChange: onCustomerChange,
    },
    rules: [{ required: true }],
  },
  {
    name: 'contractId',
    componentName: 'dropdown',
    params: {
      i18nLabel: 'boardings.contract',
      options: customerContractOptions,
    },
    rules: [{ required: true }],
  },
  {
    name: 'code',
    componentName: 'input',
    params: {
      i18nLabel: 'proposal.code',
    },
    rules: [{ required: true }],
  },
];
