import { addressStore, boardingStore, containerStore, counterpartyContractStore, counterpartyStore } from '@/stores';
import { buildStoreOptions, productOptions } from '@/utils/common';

export const getFormFields = () => {
  return [
    {
      name: 'boardingId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'transit-orders.boarding',
        options: buildStoreOptions(boardingStore, 'number'),
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'freightForwarderId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'transit-orders.freight-forwarder',
        options: buildStoreOptions(counterpartyStore, 'name'),
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'productId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'transit-orders.product',
        options: productOptions(),
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'containerId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'transit-orders.container',
        options: buildStoreOptions(containerStore, 'name'),
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'departureAddressId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'transit-orders.departure-address',
        options: buildStoreOptions(addressStore, 'name'),
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'destinationAddressId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'transit-orders.destination-address',
        options: buildStoreOptions(addressStore, 'name'),
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'exporterId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'transit-orders.exporter',
        options: buildStoreOptions(counterpartyStore, 'name'),
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'internalClientId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'transit-orders.internal-client',
        options: buildStoreOptions(counterpartyStore, 'name'),
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'finalClientId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'transit-orders.final-client',
        options: buildStoreOptions(counterpartyStore, 'name'),
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'consigneeId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'transit-orders.consignee',
        options: buildStoreOptions(counterpartyStore, 'name'),
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'finalConsigneeId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'transit-orders.final-consignee',
        options: buildStoreOptions(counterpartyStore, 'name'),
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'freightPayerId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'transit-orders.freight-payer',
        options: buildStoreOptions(counterpartyStore, 'name'),
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'internalClientContractId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'transit-orders.internal-client-contract',
        options: buildStoreOptions(counterpartyContractStore, 'clientContract'),
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'finalClientContractId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'transit-orders.final-client-contract',
        options: buildStoreOptions(counterpartyContractStore, 'clientContract'),
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'serviceContractId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'transit-orders.service-contract',
        options: buildStoreOptions(counterpartyContractStore, 'clientContract'),
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'orderNumber',
      componentName: 'input',
      params: {
        i18nLabel: 'transit-orders.order-number',
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'bookingNumber',
      componentName: 'input',
      params: {
        i18nLabel: 'transit-orders.booking-number',
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'billOfLadingNumber',
      componentName: 'input',
      params: {
        i18nLabel: 'transit-orders.bill-of-lading-number',
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'shipName',
      componentName: 'input',
      params: {
        i18nLabel: 'transit-orders.ship-name',
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'netWeight',
      componentName: 'input-number',
      params: {
        i18nLabel: 'transit-orders.net-weight',
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'etaDate',
      componentName: 'datepicker',
      params: {
        i18nLabel: 'transit-orders.eta-date',
        showTime: true,
      },
      rules: [
        { required: true },
      ],
    },
  ];
};
