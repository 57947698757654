import { request } from '@/utils/request';
import { ApiResponse, WrappedItemsResult, WrappedResult } from './types';

export default {
  getAll(params?: cyrian.api.transitOrders.GetParams): ApiResponse<WrappedResult<cyrian.api.transitOrders.TransitOrder[]>> {
    return request({
      method: 'get',
      url: '/transit-orders',
      params,
    });
  },
  create(data: cyrian.api.transitOrders.TransitOrderParams): ApiResponse<WrappedResult<cyrian.api.transitOrders.TransitOrder>> {
    return request({
      method: 'post',
      url: '/transit-orders',
      data,
    });
  },
  update(id: number, data: cyrian.api.transitOrders.TransitOrderParams): ApiResponse<WrappedResult<cyrian.api.transitOrders.TransitOrder>> {
    return request({
      method: 'patch',
      url: `transit-orders/${id}`,
      data,
    });
  },
  delete(id: number): ApiResponse<WrappedResult<any>> {
    return request({
      method: 'delete',
      url: `/transit-orders/${id}`,
    });
  },
  getAvailableLots(id: number): ApiResponse<WrappedItemsResult<cyrian.api.transitOrders.Lot[]>> {
    return request({
      method: 'get',
      url:`/transit-orders/${id}/available-lots`,
    });
  },
  addLots(id: number, data: cyrian.api.transitOrders.LotsParam): ApiResponse<WrappedResult<cyrian.api.transitOrders.TransitOrder>> {
    return request({
      method: 'post',
      url:`/transit-orders/${id}/add-lots`,
      data,
    });
  },
  removeLots(id: number, data: cyrian.api.transitOrders.LotsParam): ApiResponse<WrappedResult<cyrian.api.transitOrders.TransitOrder>> {
    return request({
      method: 'post',
      url:`/transit-orders/${id}/remove-lots`,
      data,
    });
  },
  report(id: number) {
    return request({
      method: 'get',
      url: `/transit-orders/${id}/report`,
      config: {
        responseType: 'blob',
      },
    });
  },
};
