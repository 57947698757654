import { faLock, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UISref } from '@uirouter/react';
import { Button, Form, Input } from 'antd';
import React, { useState } from 'react';

import { authStore } from '@/stores';

import { getResponseError } from '../handlers';
import { AuthLayout } from './AuthLayout';

export const AuthRegisterForm = ({ transition }: any) => {
  const [state, setState] = useState({
    email: '',
    password: '',
    repeatPassword: '',
    error: null,
  });

  const setStateValue = (key: string, value: string) => {
    setState(prev => ({ ...prev, [key]: value }));
  };

  const onSignUpClick = async () => {
    try {
      await authStore.register(state.email, state.password, state.repeatPassword);
      transition.router.stateService.go('auth-login');
    } catch (err) {
      setStateValue('error', getResponseError(err));
    }
  };
  const onEmailChange = event => setStateValue('email', event.target.value);
  const onPasswordChange = event => setStateValue('password', event.target.value);
  const onRepeatPasswordChange = event => setStateValue('repeatPassword', event.target.value);

  return (
    <AuthLayout>
      <Form
        name="signup"
        onFinish={onSignUpClick}
        requiredMark="optional"
      >
        <Form.Item
          name="email"
          rules={[{ required: true, message: 'Please input Email!' }]}
        >
          <Input
            prefix={<FontAwesomeIcon icon={faUser} className="site-form-item-icon" />}
            name="email"
            type="email"
            id="email"
            value={state.email}
            onChange={onEmailChange}
          />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input password!' }]}
        >
          <Input.Password
            prefix={<FontAwesomeIcon icon={faLock} className="site-form-item-icon" />}
            name="password"
            type="password"
            id="password"
            value={state.password}
            onChange={onPasswordChange}
          />
        </Form.Item>

        <Form.Item
          name="repeatPassword"
          rules={[{ required: true, message: 'Please input repeat password!' }]}
        >
          <Input.Password
            prefix={<FontAwesomeIcon icon={faLock} className="site-form-item-icon" />}
            name="repeatPassword"
            id="repeatPassword"
            value={state.repeatPassword}
            onChange={onRepeatPasswordChange}
          />
        </Form.Item>

        {
          state && state.error
            ? <Form.Item>
              <div className="auth-error-text">
                {state.error}
              </div>
            </Form.Item>
            : null
        }

        <Form.Item>
          <Button className="auth-button" type="primary" htmlType="submit">
            Sign Up
          </Button>
          <div className="auth-link">
            <UISref to={'auth-login-advanced'}>
              <a>Advanced Login</a>
            </UISref>
          </div>
        </Form.Item>
      </Form>
    </AuthLayout>
  );
};
