import {
  PERMISSION_CREATE,
  PERMISSION_OBJ_TRANSFER,
  PERMISSION_READ,
  PERMISSION_UPDATE,
} from '@/constants/permissions';

import { TransferAttachments } from './components/attachments';
import { TransferForm } from './components/form';
import { LotsSelector } from './components/selector';
import { Transfers } from './components/table';

export const states = [
  {
    name: 'base-layout.transfers',
    url: '/transfers',
    component: Transfers,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_TRANSFER, PERMISSION_READ],
      ],
    },
  },
  {
    name: 'base-layout.transfers.create',
    url: '/new',
    component: TransferForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_TRANSFER, PERMISSION_CREATE],
      ],
    },
  },
  {
    name: 'base-layout.transfers.edit',
    url: '/:transferId/edit',
    component: TransferForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_TRANSFER, PERMISSION_UPDATE],
      ],
    },
  },
  {
    name: 'base-layout.transfers.lots',
    url: '/:transferId/lots',
    component: LotsSelector,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_TRANSFER, PERMISSION_UPDATE],
      ],
    },
  },
  {
    name: 'base-layout.transfers.attachments',
    url: '/:transferId/attachments',
    component: TransferAttachments,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_TRANSFER, PERMISSION_UPDATE],
      ],
    },
  },
];
