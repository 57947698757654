import { PlusOutlined } from '@ant-design/icons';
import { Form, FormItemProps, Input, InputNumber } from 'antd';
import React from 'react';

import { getFormItemLabel } from '@/components/form-fields/utils';
import { addressStore, bvStore, campaignStore, cvExecutionStore } from '@/stores';
import { Ii18nLabel } from '@/types/common';
import { ProductionStatus, ProductQuality } from '@/types/enums';
import { buildDropdownOptionsFromEnum, buildStoreOptions, productOptions } from '@/utils/common';
import { getFormattedVolume } from '@/utils/formatters';
import { getParsedVolume } from '@/utils/parsers';
import { getTranslatedString } from '@/utils';


const Numbers = ({ label, i18nLabel }: FormItemProps & Ii18nLabel) => {
  const fieldLabel = getFormItemLabel(i18nLabel, label);
  return <Form.Item label={fieldLabel}>
    <Input.Group compact={true}>
      <div className="lot-numbers">
        <Form.Item
          name="number"
          noStyle={true}
          rules={[{ required: true }]}
        >
          <InputNumber className="lot-numbers-number" min={1} />
        </Form.Item>
        <PlusOutlined className="lot-numbers-plus" />
        <Form.Item
          name="count"
          noStyle={true}
          rules={[{ required: true }]}
        >
          <InputNumber className="lot-numbers-count" min={0} max={50} placeholder="Count" />
        </Form.Item>
      </div>
    </Input.Group>
  </Form.Item>;
};

export const getFormFields = (onChangeLocation: any, includeCount = false) => [
  {
    name: 'number',
    params: {
      i18nLabel: 'lots.number',
      type: 'number',
    },
    conditionalRender: () => includeCount,
    component: Numbers,
  },
  {
    name: 'executionCvId',
    componentName: 'dropdown',
    params: {
      i18nLabel: 'lots.execution-cv',
      options: buildStoreOptions(cvExecutionStore, 'number'),
    },
    rules: [{ required: !true }],
  },
  {
    name: 'bvId',
    componentName: 'dropdown',
    params: {
      i18nLabel: 'lots.bv',
      options: buildStoreOptions(bvStore, 'name'),
    },
    rules: [{ required: !true }],
  },
  {
    name: 'productionDate',
    componentName: 'datepicker',
    params: {
      i18nLabel: 'lots.production-date',
      showTime: true,
    },
    rules: [{ required: true }],
  },
  {
    name: 'factoryId',
    componentName: 'dropdown',
    params: {
      i18nLabel: 'lots.factory',
      options: buildStoreOptions(addressStore, 'name'),
      onChange: onChangeLocation,
    },
    rules: [{ required: true }],
  },
  {
    name: 'locationId',
    componentName: 'dropdown',
    params: {
      i18nLabel: 'lots.location',
      options: buildStoreOptions(addressStore, 'name'),
    },
    rules: [{ required: true }],
  },
  {
    name: 'productId',
    componentName: 'dropdown',
    params: {
      i18nLabel: 'lots.product',
      options: productOptions(),
    },
    rules: [{ required: true }],
  },
  {
    name: 'quality',
    componentName: 'dropdown',
    params: {
      i18nLabel: 'lots.quality',
      options: buildDropdownOptionsFromEnum(ProductQuality),
    },
    rules: [{ required: true }],
  },
  {
    name: 'sacksQuantity',
    componentName: 'input-number',
    params: {
      i18nLabel: 'lots.sacks-quantity',
      formatter: getFormattedVolume,
      parser: getParsedVolume,
    },
    rules: [{ required: true }],
  },
  {
    name: 'weight',
    componentName: 'input-number',
    params: {
      i18nLabel: 'lots.weight',
    },
    rules: [{ required: true }],
  },
  {
    name: 'campaignId',
    componentName: 'dropdown',
    params: {
      i18nLabel: 'lots.campaign',
      options: buildStoreOptions(campaignStore, 'name'),
    },
    rules: [{ required: true }],
  },
  {
    name: 'productionStatus',
    componentName: 'dropdown',
    params: {
      i18nLabel: 'lots.production-status',
      options: buildDropdownOptionsFromEnum(ProductionStatus)
        .map(({ label, value }) => ({
          value,
          label: getTranslatedString(`lots.production-status.${label}`),
        })),
    },
    rules: [{ required: true }],
  },
];
