import { BVStatus } from '@/types/enums';
import { buildDropdownOptionsFromEnum } from '@/utils/common';
import { getTranslatedString } from '@/utils';

export const getFormFields = () => {
  return [
    {
      name: 'number',
      componentName: 'input',
      params: {
        i18nLabel: 'bvs.number',
      },
      rules: [
        { required: true },
        { max: 20 },
      ],
    },
    {
      name: 'name',
      componentName: 'input',
      params: {
        i18nLabel: 'bvs.name',
      },
      rules: [
        { required: false },
        { max: 50 },
      ],
    },
    {
      name: 'status',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'bvs.status',
        options: buildDropdownOptionsFromEnum(BVStatus)
        .map(({ label, value }) => ({
          value,
          label: getTranslatedString(`bvs.status.${label}`),
        })),
      },
      rules: [{ required: true }],
    },
    {
      name: 'acceptanceDate',
      componentName: 'datepicker',
      params: {
        i18nLabel: 'bvs.acceptance-date',
        showTime: true,
      },
      rules: [{ required: true }],
    },
    {
      name: 'expirationDate',
      componentName: 'datepicker',
      params: {
        i18nLabel: 'bvs.expiration-date',
        showTime: true,
      },
      rules: [{ required: true }],
    },
  ];
};
