import { PERMISSION_CREATE, PERMISSION_READ, PERMISSION_UPDATE } from '@/constants/permissions';
import { authStore } from '@/stores';

import { FormBuilderOptionItem } from './index';

export const getAllowedFormFields = (
  setup: FormBuilderOptionItem[],
  isCreate: boolean,
  obj: string,
  excludedFields: string[] = [],
): FormBuilderOptionItem[] => {
  const permission: number = isCreate ? PERMISSION_CREATE : PERMISSION_UPDATE;

  return setup.map((field: FormBuilderOptionItem) => {
    const permissionField: string = `${obj}.${field.name}`;
    const permissionRead = authStore.hasPermissions([[permissionField, PERMISSION_READ]]);
    const permissionModify = authStore.hasPermissions([[permissionField, permission]]);
    const condPermissionRender: boolean = isCreate ? permissionModify : permissionModify || permissionRead;

    if (excludedFields.includes(field.name)) {
      return field;
    }

    return {
      ...field,
      params: {
        ...field.params,
        readOnly: (field.params.hasOwnProperty('readOnly') && field.params.readOnly)
          || (permissionRead && !permissionModify),
      },
      conditionalRender: (values: any) => field.hasOwnProperty('conditionalRender')
        ? field.conditionalRender(values) && condPermissionRender
        : condPermissionRender,
    };
  });
};
