import { request } from '@/utils/request';
import { ApiResponse, WrappedResult } from './types';

export default {
  getAll(params?: cyrian.api.products.GetParams): ApiResponse<WrappedResult<cyrian.api.products.Product[]>> {
    return request({
      method: 'get',
      url: '/products',
      params,
    });
  },
  create(data: cyrian.api.products.ProductParams): ApiResponse<WrappedResult<cyrian.api.products.Product>> {
    return request({
      method: 'post',
      url: '/products',
      data,
    });
  },
  update(id: number, data: cyrian.api.products.ProductParams): ApiResponse<WrappedResult<cyrian.api.products.Product>> {
    return request({
      method: 'patch',
      url: `/products/${id}`,
      data,
    });
  },
  delete(id: number): ApiResponse<WrappedResult<any>> {
    return request({
      method: 'delete',
      url: `/products/${id}`,
    });
  },
};
