import { request } from '@/utils/request';
import { ApiResponse, WrappedItemsResult, WrappedResult } from './types';

export default {
  getAll(params?: cyrian.api.transfers.GetParams): ApiResponse<WrappedItemsResult<cyrian.api.transfers.Transfer[]>> {
    return request({
      method: 'get',
      url: '/transfers',
      params,
    });
  },
  get(id: number): ApiResponse<WrappedResult<cyrian.api.transfers.Transfer>> {
    return request({
      method: 'get',
      url: `/transfers/${id}`,
    });
  },
  create(data: cyrian.api.transfers.Params): ApiResponse<WrappedResult<cyrian.api.transfers.Transfer>> {
    return request({
      method: 'post',
      url: '/transfers',
      data,
    });
  },
  update(id: number, data: cyrian.api.transfers.Params): ApiResponse<WrappedResult<cyrian.api.transfers.Transfer>> {
    return request({
      method: 'patch',
      url: `/transfers/${id}`,
      data,
    });
  },
  delete(id: number): ApiResponse<WrappedResult<any>> {
    return request({
      method: 'delete',
      url: `/transfers/${id}`,
    });
  },
  getAvailableLots(id: number): ApiResponse<WrappedItemsResult<cyrian.api.transfers.Lot[]>> {
    return request({
      method: 'get',
      url: `/transfers/${id}/available-lots`,
    });
  },
  addLots(id: number, data: cyrian.api.transfers.LotsParam): ApiResponse<WrappedResult<cyrian.api.transfers.Transfer>> {
    return request({
      method: 'post',
      url: `/transfers/${id}/add-lots`,
      data,
    });
  },
  removeLots(id: number, data: cyrian.api.transfers.LotsParam): ApiResponse<WrappedResult<cyrian.api.transfers.Transfer>> {
    return request({
      method: 'post',
      url: `/transfers/${id}/remove-lots`,
      data,
    });
  },
  report(id: number) {
    return request({
      method: 'get',
      url: `/transfers/${id}/report`,
      config: {
        responseType: 'blob',
      },
    });
  },
};
