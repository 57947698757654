import { addressStore, containerStore, counterpartyContractStore, counterpartyStore } from '@/stores';
import { AddressType } from '@/types/enums';
import { buildStoreOptions, productOptions } from '@/utils/common';

const harborOptions = (store: any, labelKey: string) => {
  return store.items
    .filter(({ type }: cyrian.api.addresses.Address) => type === AddressType.Harbor)
    .map((item: any) => ({ value: item.id, label: item[labelKey] }));
};

export const getFormFields = () => {
  return [
    {
      name: 'productId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'boardings.product',
        options: productOptions(),
      },
      rules: [{ required: true }],
    },
    {
      name: 'containerId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'boardings.container',
        options: buildStoreOptions(containerStore, 'name'),
      },
      rules: [{ required: true }],
    },
    {
      name: 'departureAddressId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'boardings.departure-address',
        options: buildStoreOptions(addressStore, 'name'),
      },
      rules: [{ required: true }],
    },
    {
      name: 'destinationAddressId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'boardings.destination-address',
        options: buildStoreOptions(addressStore, 'name'),
      },
      rules: [{ required: true }],
    },
    {
      name: 'exporterId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'boardings.exporter',
        options: buildStoreOptions(counterpartyStore, 'name'),
      },
      rules: [{ required: true }],
    },
    {
      name: 'internalClientId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'boardings.internal-client',
        options: buildStoreOptions(counterpartyStore, 'name'),
      },
      rules: [{ required: true }],
    },
    {
      name: 'finalClientId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'boardings.final-client',
        options: buildStoreOptions(counterpartyStore, 'name'),
      },
      rules: [{ required: true }],
    },
    {
      name: 'consigneeId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'boardings.consignee',
        options: buildStoreOptions(counterpartyStore, 'name'),
      },
      rules: [{ required: true }],
    },
    {
      name: 'finalConsigneeId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'boardings.final-consignee',
        options: buildStoreOptions(counterpartyStore, 'name'),
      },
      rules: [{ required: true }],
    },
    {
      name: 'freightPayerId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'boardings.freight-payer',
        options: buildStoreOptions(counterpartyStore, 'name'),
      },
      rules: [{ required: true }],
    },
    {
      name: 'internalClientContractId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'boardings.internal-client-contract',
        options: buildStoreOptions(counterpartyContractStore, 'clientContract'),
      },
      rules: [{ required: true }],
    },
    {
      name: 'finalClientContractId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'boardings.final-client-contract',
        options: buildStoreOptions(counterpartyContractStore, 'clientContract'),
      },
      rules: [{ required: true }],
    },
    {
      name: 'serviceContractId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'boardings.service-contract',
        options: buildStoreOptions(counterpartyContractStore, 'clientContract'),
      },
      rules: [{ required: true }],
    },
    {
      name: 'notify',
      componentName: 'input',
      params: {
        i18nLabel: 'boardings.notify',
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'seaLine',
      componentName: 'input',
      params: {
        i18nLabel: 'boardings.sea-line',
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'number',
      componentName: 'input',
      params: {
        i18nLabel: 'boardings.number',
      },
      rules: [
        { required: true },
        { max: 100 },
      ],
    },
    {
      name: 'date',
      componentName: 'datepicker',
      params: {
        i18nLabel: 'boardings.date',
        showTime: true,
      },
      rules: [{ required: true }],
    },
  ];
};
