import { Space } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { api } from '@/api';
import {
  DeleteAction,
  EditAction,
  ReportAction,
  SelectorAction,
} from '@/components/table/actions';
import { getColumnSearchFields } from '@/components/table/filters';
import {
  PERMISSION_DELETE,
  PERMISSION_OBJ_BOARDING,
  PERMISSION_UPDATE,
} from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';
import { boardingStore } from '@/stores';
import { productFormattedLabel, showError } from '@/utils/common';
import { getFormattedDate } from '@/utils/moment';

const PermissionedEditAction = withPermissions([[PERMISSION_OBJ_BOARDING, PERMISSION_UPDATE]])(EditAction);
const PermissionedSelectorAction = withPermissions([[PERMISSION_OBJ_BOARDING, PERMISSION_UPDATE]])(SelectorAction);
const PermissionedDeleteAction = withPermissions([[PERMISSION_OBJ_BOARDING, PERMISSION_DELETE]])(DeleteAction);

const Actions = (_: any, record: any) => {
  const onDelete = () => {
    api.boardings.delete(record.id).source
      .then(() => boardingStore.deleteItem(record.id))
      .catch(showError);
  };

  const onReportClick = () => {
    api.boardings.report(record.id).source
      .then((data) => {
        const url = URL.createObjectURL(data);
        window.open(url);
      })
      .catch(showError);
  };

  return (
    <Space>
      <PermissionedEditAction uiSref={{ to: 'base-layout.boardings.edit', params: { boardingId: record.id } }} />
      <PermissionedSelectorAction uiSref={{ to: 'base-layout.boardings.lots', params: { boardingId: record.id } }} title="Lots"/>
      <PermissionedDeleteAction onConfirm={onDelete} />
      <ReportAction onClick={onReportClick} />
    </Space>
  );
};

export const columns = [
  {
    title: 'id',
    dataIndex: 'id',
    sorter: true,
  },
  {
    title: <FormattedMessage id="boardings.container" />,
    dataIndex: ['container', 'name'],
  },
  {
    title: <FormattedMessage id="boardings.number" />,
    dataIndex: 'number',
    ...getColumnSearchFields(),
  },
  {
    title: <FormattedMessage id="boardings.departure-address" />,
    dataIndex: ['departureAddress', 'name'],
  },
  {
    title: <FormattedMessage id="boardings.destination-address" />,
    dataIndex: ['destinationAddress', 'name'],
  },
  {
    title: <FormattedMessage id="boardings.date" />,
    dataIndex: 'date',
    sorter: true,
    render: date => getFormattedDate(date),
  },
  {
    title: <FormattedMessage id="boardings.product" />,
    dataIndex: ['product', 'name'],
    render: (_, record) => productFormattedLabel(record.product),
  },
  {
    title: <FormattedMessage id="boardings.exporter" />,
    dataIndex: ['exporter', 'name'],
  },
  {
    title: <FormattedMessage id="boardings.internal-client" />,
    dataIndex: ['internalClient', 'name'],
  },
  {
    title: <FormattedMessage id="boardings.final-client" />,
    dataIndex: ['finalClient', 'name'],
  },
  {
    title: <FormattedMessage id="boardings.consignee" />,
    dataIndex: ['consignee', 'name'],
  },
  {
    title: <FormattedMessage id="boardings.final-consignee" />,
    dataIndex: ['finalConsignee', 'name'],
  },
  {
    title: <FormattedMessage id="boardings.freight-payer" />,
    dataIndex: ['freightPayer', 'name'],
  },
  {
    title: <FormattedMessage id="boardings.internal-client-contract" />,
    dataIndex: ['internalClientContract', 'clientContract'],
  },
  {
    title: <FormattedMessage id="boardings.final-client-contract" />,
    dataIndex: ['finalClientContract', 'clientContract'],
  },
  {
    title: <FormattedMessage id="boardings.service-contract" />,
    dataIndex: ['serviceContract', 'clientContract'],
  },

  {
    title: <FormattedMessage id="boardings.sea-line" />,
    dataIndex: 'seaLine',
  },
  {
    title: <FormattedMessage id="boardings.notify" />,
    dataIndex: 'notify',
  },
  {
    title: "",
    render: Actions,
    className: "actions",
  },
];
