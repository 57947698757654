import { counterpartyStore, counterpartyContractStore, currencyStore, cvStore } from '@/stores';
import { buildStoreOptions, productOptions } from '@/utils/common';
import { getFormattedVolume } from '@/utils/formatters';
import { getFormattedQuarterDate as format } from '@/utils/moment';
import { getParsedVolume } from '@/utils/parsers';

export const getFormFields = () => {
  return [
    {
      name: 'exporterId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'contract-line-items.exporter',
        options: counterpartyStore.items
          .filter(({ type }) => type === 'Customer')
          .map((item: any) => ({ value: item.id, label: item.name })),
      },
      rules: [{ required: false }],
    },
    {
      name: 'contractId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'contract-line-items.final-contract',
        options: buildStoreOptions(counterpartyContractStore, 'clientContract'),
      },
      rules: [{ required: true }],
    },
    {
      name: 'productId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'contract-line-items.product',
        options: productOptions(),
      },
      rules: [{ required: false }],
    },
    {
      name: 'number',
      componentName: 'input',
      params: {
        i18nLabel: 'contract-line-items.number',
      },
      rules: [
        { required: true },
        { max: 50 },
      ],
    },
    {
      name: 'volume',
      componentName: 'input-number',
      params: {
        i18nLabel: 'contract-line-items.volume',
        min: 0,
        formatter: getFormattedVolume,
        parser: getParsedVolume,
      },
      rules: [{ required: true }],
    },
    {
      name: 'contractPeriod',
      componentName: 'datepicker',
      params: {
        i18nLabel: 'contract-line-items.execution-period',
        picker: 'quarter',
        format,
      },
      rules: [{ required: true }],
    },
    {
      name: 'boardingPeriod',
      componentName: 'datepicker',
      params: {
        i18nLabel: 'contract-line-items.boarding-period',
        picker: 'quarter',
        format,
      },
      rules: [{ required: false }],
    },
    {
      name: 'differential',
      componentName: 'input-number',
      params: {
        i18nLabel: 'contract-line-items.differential',
        min: 0,
      },
      rules: [{ required: false }],
    },
    {
      name: 'materialFixingDate',
      componentName: 'datepicker',
      params: {
        i18nLabel: 'contract-line-items.material-fixing-date',
        showTime: true,
      },
      rules: [{ required: false }],
    },
    {
      name: 'currencyFixingDate',
      componentName: 'datepicker',
      params: {
        i18nLabel: 'contract-line-items.currency-fixing-date',
        showTime: true,
      },
      rules: [{ required: false }],
    },
    {
      name: 'fixingPrice',
      componentName: 'input-with-dropdown',
      params: {
        i18nLabel: 'contract-line-items.fixing-price-gbp',
        min: 0,
        options: buildStoreOptions(currencyStore, 'code'),
      },
      rules: [{ required: false }],
    },
    {
      name: 'totalFobPrice',
      componentName: 'input-with-dropdown',
      params: {
        i18nLabel: 'contract-line-items.total-fob-price',
        min: 0,
        options: buildStoreOptions(currencyStore, 'code'),
      },
      rules: [{ required: false }],
    },
    {
      name: 'qualityPrice',
      componentName: 'input-with-dropdown',
      params: {
        i18nLabel: 'contract-line-items.price-quality',
        min: 0,
        options: buildStoreOptions(currencyStore, 'code'),
      },
      rules: [{ required: false }],
    },
    {
      name: 'cyrianBasicPrice',
      componentName: 'input-with-dropdown',
      params: {
        i18nLabel: 'contract-line-items.cyrian-basic-price',
        min: 0,
        options: buildStoreOptions(currencyStore, 'code'),
      },
      rules: [{ required: false }],
    },
    {
      name: 'cyrianDiscount',
      componentName: 'input-with-dropdown',
      params: {
        i18nLabel: 'contract-line-items.cyrian-discount',
        min: 0,
        options: buildStoreOptions(currencyStore, 'code'),
      },
      rules: [{ required: false }],
    },
    {
      name: 'graining',
      componentName: 'input-number',
      params: {
        i18nLabel: 'contract-line-items.graining',
        min: 0,
      },
      rules: [{ required: false }],
    },
    {
      name: 'signature',
      componentName: 'input',
      params: {
        i18nLabel: 'contract-line-items.signature',
      },
      rules: [
        { required: false },
      ],
    },
  ];
};
