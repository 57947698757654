import { ProductType } from '@/types/enums';
import { buildDropdownOptionsFromEnum } from '@/utils/common';
import { getTranslatedString } from '@/utils';

export const getFormFields = () => {
  return [
    {
      name: 'name',
      componentName: 'input',
      params: {
        i18nLabel: 'products.name',
      },
      rules: [
        { required: true },
        { max: 50 },
      ],
    },
    {
      name: 'type',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'products.type',
        options: buildDropdownOptionsFromEnum(ProductType)
          .map(({ label, value }) => ({
          value,
          label: getTranslatedString(`products.type.${label}`),
        })),
      },
      rules: [{ required: true }],
    },
    {
      name: 'samplingMethod',
      componentName: 'input',
      params: {
        i18nLabel: 'products.sampling-method',
      },
      rules: [
        { required: true },
        { max: 20 },
      ],
    },
  ];
};
