import { addressStore, counterpartyStore } from '@/stores';
import { AnalysisClassification } from '@/types/enums';
import { buildDropdownOptionsFromEnum, buildStoreOptions } from '@/utils/common';

export const getFormFields = () => [
  {
    name: 'addressId',
    componentName: 'dropdown',
    params: {
      i18nLabel: 'lot.analysis.address',
      options: buildStoreOptions(addressStore, 'name'),
    },
    rules: [{ required: true }],
  },
  {
    name: 'counterpartyId',
    componentName: 'dropdown',
    params: {
      i18nLabel: 'lot.analysis.counterparty',
      options: counterpartyStore.items
        .filter(({ type }) => type === 'Verification')
        .map(({ id, name }) => ({
          value: id,
          label: name,
        })),
    },
    rules: [{ required: true }],
  },

  {
    name: 'analysisDate',
    componentName: 'datepicker',
    params: {
      i18nLabel: 'lot.analysis.analysis-date',
      showTime: true,
    },
    rules: [{ required: true }],
  },
  {
    name: 'samplingDate',
    componentName: 'datepicker',
    params: {
      i18nLabel: 'lot.analysis.sampling-date',
      showTime: true,
    },
    rules: [{ required: true }],
  },
  {
    name: 'samplingMethod',
    componentName: 'input',
    params: {
      i18nLabel: 'lot.analysis.sampling-method',
    },
    rules: [
      { required: true },
      { max: 100 },
    ],
  },
  {
    name: 'analysisReference',
    componentName: 'input',
    params: {
      i18nLabel: 'lot.analysis.analysis-reference',
    },
    rules: [
      { required: true },
      { max: 100 },
    ],
  },
  {
    name: 'samplingReference',
    componentName: 'input',
    params: {
      i18nLabel: 'lot.analysis.sampling-reference',
    },
    rules: [
      { required: true },
      { max: 100 },
    ],
  },
  {
    name: 'classification',
    componentName: 'dropdown',
    params: {
      i18nLabel: 'lot.analysis.classification',
      options: buildDropdownOptionsFromEnum(AnalysisClassification),
    },
    rules: [{ required: true }],
  },
  {
    name: 'documentUrl',
    componentName: 'input',
    params: {
      i18nLabel: 'lot.analysis.document-url',
    },
    rules: [{ required: true }],
  },
  {
    name: 'totalSampleWeight',
    componentName: 'input-number',
    params: {
      i18nLabel: 'lot.analysis.total-sample-weight',
      min: 0,
    },
    rules: [{ required: true }],
  },
  {
    name: 'specificWeightOn100g',
    componentName: 'input-number',
    params: {
      i18nLabel: 'lot.analysis.specific-weight-100g',
      min: 0,
    },
    rules: [{ required: true }],
  },
  {
    name: 'graining',
    componentName: 'input-number',
    params: {
      i18nLabel: 'lot.analysis.graining',
      min: 0,
    },
    rules: [{ required: true }],
  },
  {
    name: 'humidity',
    componentName: 'input-number',
    params: {
      i18nLabel: 'lot.analysis.humidity',
      min: 0,
    },
    rules: [{ required: false }],
  },
  {
    name: 'screeningScrap',
    componentName: 'input-number',
    params: {
      i18nLabel: 'lot.analysis.screening-scrap',
      min: 0,
    },
    rules: [{ required: false }],
  },
  {
    name: 'flatBeans',
    componentName: 'input-number',
    params: {
      i18nLabel: 'lot.analysis.flat-beans',
      min: 0,
    },
    rules: [{ required: false }],
  },
  {
    name: 'crabots',
    componentName: 'input-number',
    params: {
      i18nLabel: 'lot.analysis.crabots',
      min: 0,
    },
    rules: [{ required: false }],
  },
  {
    name: 'foreignBodies',
    componentName: 'input-number',
    params: {
      i18nLabel: 'lot.analysis.foreign-bodies',
      min: 0,
    },
    rules: [{ required: false }],
  },
  {
    name: 'brokenBeans',
    componentName: 'input-number',
    params: {
      i18nLabel: 'lot.analysis.broken-beans',
      min: 0,
    },
    rules: [{ required: false }],
  },
  {
    name: 'fragments',
    componentName: 'input-number',
    params: {
      i18nLabel: 'lot.analysis.fragments',
      min: 0,
    },
    rules: [{ required: false }],
  },
  {
    name: 'shell',
    componentName: 'input-number',
    params: {
      i18nLabel: 'lot.analysis.shell',
      min: 0,
    },
    rules: [{ required: false }],
  },
  {
    name: 'materialsDerived',
    componentName: 'input-number',
    params: {
      i18nLabel: 'lot.analysis.materials-derived',
      min: 0,
    },
    rules: [{ required: false }],
  },
  {
    name: 'moldy',
    componentName: 'input-number',
    params: {
      i18nLabel: 'lot.analysis.moldy',
      min: 0,
    },
    rules: [{ required: false }],
  },
  {
    name: 'moths',
    componentName: 'input-number',
    params: {
      i18nLabel: 'lot.analysis.moths',
      min: 0,
    },
    rules: [{ required: false }],
  },
  {
    name: 'slate',
    componentName: 'input-number',
    params: {
      i18nLabel: 'lot.analysis.slate',
      min: 0,
    },
    rules: [{ required: false }],
  },
  {
    name: 'germinated',
    componentName: 'input-number',
    params: {
      i18nLabel: 'lot.analysis.germinated',
      min: 0,
    },
    rules: [{ required: false }],
  },
  {
    name: 'violets',
    componentName: 'input-number',
    params: {
      i18nLabel: 'lot.analysis.violets',
      min: 0,
    },
    rules: [{ required: false }],
  },
  {
    name: 'buttered',
    componentName: 'input-number',
    params: {
      i18nLabel: 'lot.analysis.buttered',
      min: 0,
    },
    rules: [{ required: false }],
  },
];
