import { UIViewInjectedProps } from '@uirouter/react';
import React from 'react';

import { Attachments } from '@/components/attachments';
import { AttachmentType } from '@/types/enums';

export const BVAttachments = ({ transition }: UIViewInjectedProps) => {
  const params = {
    transition,
    apiEntity: 'BV',
    paramId: 'bvId',
    attachmentType: AttachmentType.BV,
    closeRoute: 'base-layout.bvs'
  };

  return <Attachments<cyrian.api.bvs.BVWithAttachment> {...params} />;
};
