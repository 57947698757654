import { Admin } from './components';

export const states = [
  {
    name: 'base-layout.admin',
    url: '/admin',
    component: Admin,
    data: {
      requiresAuth: true,
    },
  },
];
