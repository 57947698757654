import { Transition } from '@uirouter/react';
import { Col } from 'antd';
import { RcFile } from 'antd/lib/upload';
import React, { useEffect, useState } from 'react';

import { api } from '@/api';
import { WrappedItemsResult, WrappedResult } from '@/api/types';
import { Drawer } from '@/components/drawer';
import { Upload } from '@/components/upload';
import { AttachmentType } from '@/types/enums';
import { getTranslatedString } from '@/utils';
import { showError } from '@/utils/common';

import { AttachedFiles } from './attached-files';

interface AttachmentsProps {
  transition: Transition;
  apiEntity: string;
  closeRoute: string;
  paramId: string;
  attachmentType: AttachmentType;
}

// tslint:disable-next-line:function-name
export function Attachments<T extends { attachments: cyrian.api.attachments.Attachment[] }>(
  {
    transition,
    apiEntity,
    paramId,
    attachmentType,
    closeRoute,
  }: AttachmentsProps,
) {
  const { [paramId]: id } = transition.router.globals.params;

  const [attachments, setAttachments] = useState<cyrian.api.attachments.Attachment[]>([]);
  useEffect(() => {
    api.attachments.getAll(apiEntity, id).source
      .then(({ data }: WrappedItemsResult<cyrian.api.attachments.Attachment[]>) => setAttachments(data.items || []))
      .catch((err) => {
        showError(err);
        onClose();
      });
  }, []);

  const onClose = () => {
    transition.router.stateService.go(closeRoute);
  };

  const request = (fileList: RcFile[]) => {
    const formData = new FormData();
    fileList.forEach((file: RcFile) => formData.append('files', file));
    formData.append('objectId', id);
    formData.append('type', attachmentType);

    return api.attachments.attachFiles(formData).source
      .then(({ data }: WrappedItemsResult<cyrian.api.attachments.Attachment[]>) => {
        setAttachments([...attachments, ...data.items]);
      });
  };

  const title = getTranslatedString('attachments.title', { 0: id });

  return (
    <Drawer
      onClose={onClose}
      visible={true}
      title={title}
    >
      <Col>
        <Upload request={request} />
      </Col>
      {
        Boolean(attachments.length)
        && <AttachedFiles attachments={attachments} />
      }
    </Drawer>
  );
}
