import { UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';

import { api } from '@/api';
import { getAllowedFormFields } from '@/components/generic-form/utils';
import { DrawerFormWithNote } from '@/components/note-form';
import { PERMISSION_OBJ_BOARDING } from '@/constants/permissions';
import {
  addressStore,
  boardingStore,
  containerStore,
  counterpartyContractStore,
  counterpartyStore,
  productStore,
} from '@/stores';
import { NoteObjectType } from '@/types/enums';
import { getTranslatedString } from '@/utils';
import { withoutRepeatValue } from '@/utils/controller';
import { useMassUpdateCashedStores } from '@/utils/store';

import { getFormFields } from './setup';

const entity = 'boardings';
const store = boardingStore;

export const BoardingForm = observer(({ transition }: UIViewInjectedProps) => {
  const isCreate = transition.router.globals.current.name === `base-layout.${entity}.create`;
  const { boardingId: itemId } = transition.router.globals.params;
  const foundItem = store.items.find(({ id }: any) => id === +itemId);

  const { loading } = useMassUpdateCashedStores([
    counterpartyStore,
    counterpartyContractStore,
    productStore,
    addressStore,
    containerStore,
  ]);

  const onClose = () => {
    transition.router.stateService.go(`base-layout.${entity}`);
  };

  const initValues = !isCreate && foundItem
    ? {
      ...foundItem,
      containerId: foundItem.container?.id,
      productId: foundItem.product?.id,
      departureAddressId: foundItem.departureAddress?.id,
      destinationAddressId: foundItem.destinationAddress?.id,
      exporterId: foundItem.exporter?.id,
      internalClientId: foundItem.internalClient?.id,
      finalClientId: foundItem.finalClient?.id,
      consigneeId: foundItem.consignee?.id,
      finalConsigneeId: foundItem.finalConsignee?.id,
      freightPayerId: foundItem.freightPayer?.id,
      internalClientContractId: foundItem.internalClientContract?.id,
      finalClientContractId: foundItem.finalClientContract?.id,
      serviceContractId: foundItem.serviceContract?.id,
      date: foundItem.date && moment(foundItem.date),
    }
    : {};

  const title = isCreate
    ? getTranslatedString('boardings.create-new')
    : getTranslatedString('boardings.boarding-number', { 0: itemId });

  const formFields = getFormFields();
  const allowedFields = getAllowedFormFields(formFields, isCreate, PERMISSION_OBJ_BOARDING);

  const resourceController = withoutRepeatValue(initValues, {
    create: (values: any) => api[entity].create(values).source
      .then(({ data }: any) => store.addItem(data)),
    update: (values: any) => api[entity].update(foundItem.id, values).source
      .then(({ data }: any) => store.updateItem(data)),
  });

  const layout = {
    labelCol: { span: 11 },
    wrapperCol: { span: 13 },
  };

  return (
    <DrawerFormWithNote
      {...layout}
      objectType={NoteObjectType.Boarding}
      resourceId={itemId}
      title={title}
      initValues={initValues}
      formFields={allowedFields}
      onClose={onClose}
      resourceController={resourceController}
      loaderCondition={loading || (!isCreate && !foundItem)}
    />
  );
});
