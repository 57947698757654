import { FormattedMessage } from 'react-intl';
import { Space } from 'antd';

import { getFormattedDate } from '@/utils/moment';
import { api } from '@/api';
import { AttachmentAction, DeleteAction } from '@/components/table/actions';
import {
  PERMISSION_DELETE,
  PERMISSION_OBJ_LOT_WEIGHING,
  PERMISSION_UPDATE,
} from '@/constants/permissions';
import { lotWeighingStore } from '@/stores';
import { showError } from '@/utils/common';
import { withPermissions } from '@/hocs/permission';

const PermissionedDeleteAction = withPermissions([[PERMISSION_OBJ_LOT_WEIGHING, PERMISSION_DELETE]])(DeleteAction);
const PermissionedAttachmentAction = withPermissions([[PERMISSION_OBJ_LOT_WEIGHING, PERMISSION_UPDATE]])(AttachmentAction);

const Actions = ({ lotId, weighingId }: { lotId: number; weighingId: number }) => {
  const onDelete = () => {
    api.lots.deleteWeighing(lotId, weighingId).source
      .then(() => lotWeighingStore.deleteItem(weighingId))
      .catch(showError);
  };

  return (
    <Space>
      <PermissionedAttachmentAction uiSref={{ to: 'base-layout.lots.view.weighing-attachments', params: { lotId, weighingId } }} />
      <PermissionedDeleteAction onConfirm={onDelete} />
    </Space>
  );
};

export const getColumns = (lotId: number) => {
  return [
    {
      title: 'id',
      dataIndex: 'id',
      sorter: true,
    },
    {
      title: <FormattedMessage id="lot.weighing.address" />,
      dataIndex: ['address', 'name'],
      sorter: true,
    },
    {
      title: <FormattedMessage id="lot.weighing.counterparty" />,
      dataIndex: ['counterparty', 'name'],
      sorter: true,
    },
    {
      title: <FormattedMessage id="lot.weighing.date" />,
      dataIndex: 'date',
      render: getFormattedDate,
      sorter: true,
    },
    {
      title: <FormattedMessage id="lot.weighing.weight" />,
      dataIndex: 'weight',
      sorter: true,
    },
    {
      title: '',
      render: (_, record: any) => <Actions lotId={lotId} weighingId={record.id} />,
      className: 'actions',
    },
  ];
}
