import { faInbox } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { message, Button, Upload as AntdUpload } from 'antd';
import { RcFile, UploadFile, UploadProps as AntdUploadProps } from 'antd/lib/upload/interface';
import React, { useState } from 'react';

import { getTranslatedString } from '@/utils';

const { Dragger } = AntdUpload;

interface UploadProps extends AntdUploadProps {
  title?: string;
  description?: string;
  children?: React.ReactNode;
  request?: (fileList: RcFile[]) => Promise<any>;
}

export const Upload = (
  {
    title = 'Click or drag file to this area to upload',
    description = 'Support for a single or bulk upload.',
    multiple = true,
    request,
  }: UploadProps,
) => {

  const [uploading, setUploading] = useState<boolean>(false);
  const [fileList, setFileList] = useState<any[]>([]);

  const handleUpload = () => {
    if (!request) {
      return;
    }

    setUploading(true);

    return request(fileList)
      .then(() => {
        setUploading(false);
        setFileList([]);
        message.success('Upload Successfully.');
      })
      .catch(() => {
        setUploading(false);
        message.error('Upload Failed.');
      });
  };

  const beforeUpload = (file: RcFile) => {
    setFileList((prevFileList: RcFile[]) => [...prevFileList, file]);
    return false;
  };

  const onRemove = (file: UploadFile) => {
    setFileList((prevFileList: RcFile[]) => prevFileList
      .filter(({ uid }: RcFile) => uid !== file.uid));
  };

  const uploadingLabel: string = getTranslatedString('attachments.uploading');
  const startUploadLabel: string = getTranslatedString('attachments.start-upload');

  return (
    <>
      <Dragger
        multiple={multiple}
        fileList={fileList}
        beforeUpload={beforeUpload}
        onRemove={onRemove}
      >
        <p className="ant-upload-drag-icon">
          <span className="anticon anticon-inbox"><FontAwesomeIcon icon={faInbox} /></span>
        </p>
        <p className="ant-upload-text">{title}</p>
        <p className="ant-upload-hint">{description}</p>
      </Dragger>

      <Button
        block={true}
        type="primary"
        onClick={handleUpload}
        disabled={fileList.length === 0}
        loading={uploading}
        style={{ marginTop: 16 }}
      >
        {uploading ? uploadingLabel : startUploadLabel}
      </Button>
    </>
  );
};
