import { Form } from 'antd';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';

import { api } from '@/api';
import { DrawerForm } from '@/components/drawer-form';
import { getAllowedFormFields } from '@/components/generic-form/utils';
import { PERMISSION_OBJ_PROPOSAL } from '@/constants/permissions';
import { counterpartyContractStore, counterpartyStore, lotStore } from '@/stores';
import { getTranslatedString } from '@/utils';
import { useMassUpdateCashedStores } from '@/utils/store';

import { getFormFields } from './setup';

interface UpdateFormProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  selectedProposal?: cyrian.api.lots.Proposal & { lotId: number };
  handleExpand: (expanded: boolean, record: { id: number }) => void;
}

export const UpdateStatusForm = observer(({ selectedProposal, visible, setVisible, handleExpand }: UpdateFormProps) => {
  const [form] = Form.useForm();
  const { loading } = useMassUpdateCashedStores([counterpartyStore, counterpartyContractStore]);

  const [customerContracts, setCustomerContracts] = useState<any[]>([]);

  useEffect(() => {
    onCustomerChange(selectedProposal?.counterparty.id, false);
  }, []);

  const onCustomerChange = (customerId: number, withReset: boolean = true) => {
    const contracts = counterpartyContractStore.items
      .filter(({ counterparty }: cyrian.api.counterpartyContracts.CounterpartyContract) => (
        counterparty.id === customerId
      ))
      .map((item: cyrian.api.counterpartyContracts.CounterpartyContract) => ({
        value: item.id,
        label: item.clientContract,
      }));

    // tslint:disable-next-line:no-unused-expression
    withReset && form.setFieldsValue({ contractId: null });
    setCustomerContracts(contracts);
  };

  const onClose = () => {
    setVisible(false);
  };

  const resourceController = {
    create: (value: any) => {
      return api.lots.updateProposal(selectedProposal?.lotId, selectedProposal?.id, value).source
        .then(() => {
          handleExpand(true, { id: selectedProposal.lotId });
          onClose();
          return lotStore.refresh();
        });
    },
  };

  const initValues = selectedProposal
    ? {
      ...selectedProposal,
      contractId: selectedProposal.contract.id,
      counterpartyId: selectedProposal.counterparty.id,
      acceptanceDate: selectedProposal.acceptanceDate && moment(selectedProposal.acceptanceDate),
    }
    : {};

  const title = getTranslatedString('proposal.update', [selectedProposal?.id]);
  const formFields = getFormFields(initValues, customerContracts, onCustomerChange);
  const allowedFields = getAllowedFormFields(formFields, false, PERMISSION_OBJ_PROPOSAL);

  return (
    <DrawerForm
      visible={visible}
      form={form}
      title={title}
      initValues={initValues}
      formFields={allowedFields}
      onClose={onClose}
      resourceController={resourceController}
      loaderCondition={loading || !selectedProposal}
      useDisableSubmit={false}
    />
  );
});
