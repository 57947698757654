import { useOnStateChanged, StateDeclaration, UISref, UIViewInjectedProps } from '@uirouter/react';
import { Layout as AntdLayout, Menu as AntdMenu } from 'antd';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { IMenuItem } from '@/constants/menu';
import { authStore } from '@/stores';

const { Sider } = AntdLayout;
const { SubMenu } = AntdMenu;

interface MenuProps extends UIViewInjectedProps {
  collapsed: boolean;
  defaultSelectedKey?: string;
  menuItems: IMenuItem[];
}

export interface BaseMenuItemProps {
  state: string;
  icon: React.ReactElement;
  messageId: string;
  className?: string;
}

type UnknownProps = Record<string, unknown>;

const BaseMenuItem = ({ state, icon, messageId, ...rest }: BaseMenuItemProps) => {

  return (
    <AntdMenu.Item icon={icon} {...rest} >
      <UISref to={state}>
        <a>
          <FormattedMessage id={messageId} />
        </a>
      </UISref>
    </AntdMenu.Item>
  );
};

const renderMenuItem = ({ key, state, messageId, permissions, icon }: IMenuItem) => {
  if (!authStore.hasPermissions(permissions)) {
    return null;
  }

  return (
    <BaseMenuItem
      key={key}
      state={state}
      messageId={messageId}
      icon={icon}
      className="menu-modified"
    />
  );
};

const renderSubMenuItem = ({ key, messageId, permissions, icon, style }: IMenuItem, children: any) => {
  const additionalPermissions = children.map(item => item.permissions);

  if (!authStore.hasAtLeastOnePermission([...permissions, ...additionalPermissions])) {
    return null;
  }

  return (
    <SubMenu
      key={key}
      title={<FormattedMessage id={messageId} />}
      icon={icon}
      className="sub-menu"
      style={style}
    >
      {children.map(renderMenuItem)}
    </SubMenu>
  );
};

export const Menu = ({ collapsed, transition, menuItems }: MenuProps) => {
  const [routerState, setRouterState] = useState(transition.router.globals.current.name);
  const selectedKey = routerState.split('.')[1];
  useOnStateChanged((state: StateDeclaration) => setRouterState(state.name));

  return <Sider theme="light" collapsed={collapsed} width={220} >
    <AntdMenu theme="light" mode="inline" selectedKeys={[selectedKey]}>
      {menuItems.filter(menuItem => menuItem.parent === 'root').map((menuItem) => {
        if (menuItem.menuType && menuItem.menuType === 'group') {
          const subMenuItems = menuItems.filter(menu => menu.parent === menuItem.key);

          return renderSubMenuItem(menuItem, subMenuItems);
        }
        return renderMenuItem(menuItem);
      })
      }
    </AntdMenu>
  </Sider>;
};
