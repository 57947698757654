import { FormattedMessage } from 'react-intl';
import { Space } from 'antd';

import { getFormattedDate } from '@/utils/moment';

import { api } from '@/api';
import { AttachmentAction, DeleteAction } from '@/components/table/actions';
import {
  PERMISSION_DELETE,
  PERMISSION_OBJ_LOT_ANALYSIS,
  PERMISSION_UPDATE,
} from '@/constants/permissions';
import { lotAnalysisStore } from '@/stores';
import { showError } from '@/utils/common';
import { withPermissions } from '@/hocs/permission';

const PermissionedDeleteAction = withPermissions([[PERMISSION_OBJ_LOT_ANALYSIS, PERMISSION_DELETE]])(DeleteAction);
const PermissionedAttachmentAction = withPermissions([[PERMISSION_OBJ_LOT_ANALYSIS, PERMISSION_UPDATE]])(AttachmentAction);

const Actions = ({ lotId, analysisId }: { lotId: number; analysisId: number }) => {
  const onDelete = () => {
    api.lots.deleteAnalysis(lotId, analysisId).source
      .then(() => lotAnalysisStore.deleteItem(analysisId))
      .catch(showError);
  };

  return (
    <Space>
      <PermissionedAttachmentAction uiSref={{ to: 'base-layout.lots.view.analysis-attachments', params: { lotId, analysisId } }} />
      <PermissionedDeleteAction onConfirm={onDelete} />
    </Space>
  );
};


export const getColumns = (lotId: number) => {
  return [
    {
      title: 'id',
      dataIndex: 'id',
      sorter: true,
    },
    {
      title: <FormattedMessage id="lot.analysis.address" />,
      dataIndex: ['address', 'name'],
      sorter: true,
    },
    {
      title: <FormattedMessage id="lot.analysis.counterparty" />,
      dataIndex: ['counterparty', 'name'],
      sorter: true,
    },
    {
      title: <FormattedMessage id="lot.analysis.analysis-date" />,
      dataIndex: 'analysisDate',
      render: getFormattedDate,
      sorter: true,
    },
    {
      title: <FormattedMessage id="lot.analysis.sampling-date" />,
      dataIndex: 'samplingDate',
      render: getFormattedDate,
      sorter: true,
    },
    {
      title: <FormattedMessage id="lot.analysis.sampling-method" />,
      dataIndex: 'samplingMethod',
      sorter: true,
    },
    {
      title: <FormattedMessage id="lot.analysis.analysis-reference" />,
      dataIndex: 'analysisReference',
      sorter: true,
    },
    {
      title: <FormattedMessage id="lot.analysis.sampling-reference" />,
      dataIndex: 'samplingReference',
      sorter: true,
    },
    {
      title: <FormattedMessage id="lot.analysis.classification" />,
      dataIndex: 'classification',
      sorter: true,
    },
    {
      title: <FormattedMessage id="lot.analysis.document-url" />,
      dataIndex: 'documentUrl',
      sorter: true,
    },
    {
      title: <FormattedMessage id="lot.analysis.total-sample-weight" />,
      dataIndex: 'totalSampleWeight',
      sorter: true,
    },
    {
      title: <FormattedMessage id="lot.analysis.specific-weight-100g" />,
      dataIndex: 'specificWeightOn100g',
      sorter: true,
    },
    {
      title: <FormattedMessage id="lot.analysis.graining" />,
      dataIndex: 'graining',
      sorter: true,
    },
    {
      title: <FormattedMessage id="lot.analysis.humidity" />,
      dataIndex: 'humidity',
      sorter: true,
    },
    {
      title: <FormattedMessage id="lot.analysis.screening-scrap" />,
      dataIndex: 'screeningScrap',
      sorter: true,
    },
    {
      title: <FormattedMessage id="lot.analysis.flat-beans" />,
      dataIndex: 'flatBeans',
      sorter: true,
    },
    {
      title: <FormattedMessage id="lot.analysis.crabots" />,
      dataIndex: 'crabots',
      sorter: true,
    },
    {
      title: <FormattedMessage id="lot.analysis.foreign-bodies" />,
      dataIndex: 'foreignBodies',
      sorter: true,
    },
    {
      title: <FormattedMessage id="lot.analysis.broken-beans" />,
      dataIndex: 'brokenBeans',
      sorter: true,
    },
    {
      title: <FormattedMessage id="lot.analysis.fragments" />,
      dataIndex: 'fragments',
      sorter: true,
    },
    {
      title: <FormattedMessage id="lot.analysis.shell" />,
      dataIndex: 'shell',
      sorter: true,
    },
    {
      title: <FormattedMessage id="lot.analysis.materials-derived" />,
      dataIndex: 'materialsDerived',
      sorter: true,
    },
    {
      title: <FormattedMessage id="lot.analysis.moldy" />,
      dataIndex: 'moldy',
      sorter: true,
    },
    {
      title: <FormattedMessage id="lot.analysis.moths" />,
      dataIndex: 'moths',
      sorter: true,
    },
    {
      title: <FormattedMessage id="lot.analysis.slate" />,
      dataIndex: 'slate',
      sorter: true,
    },
    {
      title: <FormattedMessage id="lot.analysis.germinated" />,
      dataIndex: 'germinated',
      sorter: true,
    },
    {
      title: <FormattedMessage id="lot.analysis.violets" />,
      dataIndex: 'violets',
      sorter: true,
    },
    {
      title: <FormattedMessage id="lot.analysis.buttered" />,
      dataIndex: 'buttered',
      sorter: true,
    },
    {
      title: '',
      render: (_, record: any) => <Actions lotId={lotId} analysisId={record.id} />,
      className: 'actions',
    },
  ];
}
