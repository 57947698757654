import { Space } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { api } from '@/api';
import {
  DeleteAction,
  EditAction,
} from '@/components/table/actions';
import { CompanyIcon } from '@/components/icons';
import { getColumnSearchFields } from '@/components/table/filters';
import {
  PERMISSION_DELETE,
  PERMISSION_OBJ_COUNTERPARTY,
  PERMISSION_UPDATE,
} from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';
import { counterpartyStore } from '@/stores';
import { CounterpartyType } from '@/types/enums';
import { buildFilterOptionsFromEnum, showError } from '@/utils/common';

const PermissionedEditAction = withPermissions([[PERMISSION_OBJ_COUNTERPARTY, PERMISSION_UPDATE]])(EditAction);
const PermissionedDeleteAction = withPermissions([[PERMISSION_OBJ_COUNTERPARTY, PERMISSION_DELETE]])(DeleteAction);

const Actions = (_: any, record: any) => {
  const onDelete = () => {
    api.counterparties.delete(record.id).source
      .then(() => counterpartyStore.deleteItem(record.id))
      .catch(showError);
  };

  return (
    <Space>
      <PermissionedEditAction uiSref={{ to: 'base-layout.counterparties.edit', params: { counterpartyId: record.id } }} />
      <PermissionedDeleteAction onConfirm={onDelete} />
    </Space>
  );
};

export const columns = [
  {
    dataIndex: 'id',
    sorter: false,
    width: 50,
    render: (_, record) => <CompanyIcon type={record?.type} />,
  },
  {
    title: <FormattedMessage id="counterparties.name" />,
    dataIndex: 'name',
    ...getColumnSearchFields(),
  },
  {
    title: <FormattedMessage id="counterparties.type" />,
    dataIndex: 'type',
    filterMultiple: false,
    filters: buildFilterOptionsFromEnum(CounterpartyType),
  },
  {
    title: "",
    render: Actions,
    className: "actions",
  },
];
