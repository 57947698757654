import { UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { api } from '@/api';
import { DrawerForm } from '@/components/drawer-form';
import {
  addressStore,
  campaignStore,
  counterpartyStore,
  cvExecutionStore,
  formulaStore,
  productStore,
} from '@/stores';
import { getTranslatedString } from '@/utils';
import { withoutRepeatValue } from '@/utils/controller';

import { getFormFields } from './setup';
import { useMassUpdateCashedStores } from '@/utils/store';
import moment from 'moment';

const entity = 'formulas';
const store = formulaStore;

export const Form = observer(({ transition }: UIViewInjectedProps) => {
  const isCreate = transition.router.globals.current.name === `base-layout.${entity}.create`;
  const { formulaId } = transition.router.globals.params;
  const foundItem = store.items.find(({ id }: any) => id === +formulaId);

  const { loading } = useMassUpdateCashedStores([
    addressStore,
    campaignStore,
    counterpartyStore,
    cvExecutionStore,
    productStore,
  ]);

  const onClose = () => {
    transition.router.stateService.go('base-layout.formulas');
  };

  const initValues = !isCreate && foundItem
    ? {
      ...foundItem,
      exporterId: foundItem.exporter?.id,
      freightForwarderId: foundItem.freightForwarder?.id,
      productId: foundItem.product?.id,
      campaignId: foundItem.campaign.id,
      destinationId: foundItem.destination?.id,
      departurePortId: foundItem.departurePort?.id,
      recipientId: foundItem.recipient?.id,
      executionCvId: foundItem.executionCv ? foundItem.executionCv.id : null,
      fO1Date: foundItem.fO1Date && moment(foundItem.fO1Date),
    }
    : {};

  const title = isCreate
    ? getTranslatedString('formulas.create-new')
    : getTranslatedString('formulas.formula-number', { 0: formulaId });

  const formFields = getFormFields();

  const resourceController = withoutRepeatValue(initValues, {
    create: (values: any) => api[entity].create(values).source
      .then(({ data }: any) => store.addItem(data)),
    update: (values: any) => api[entity].update(foundItem.id, values).source
      .then(({ data }: any) => store.updateItem(data)),
  });

  const layout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 15 },
  };

  return (
    <DrawerForm
      {...layout}
      resourceId={formulaId}
      title={title}
      initValues={initValues}
      formFields={formFields}
      onClose={onClose}
      resourceController={resourceController}
      loaderCondition={loading || (!isCreate && !foundItem)}
    />
  );
});
