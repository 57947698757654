import {
  addressStore,
  campaignStore,
  counterpartyStore,
  cvExecutionStore,
} from '@/stores';
import { buildStoreOptions, productOptions } from '@/utils/common';

export const getFormFields = () => {
  return [
    {
      name: 'exporterId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'formulas.exporter',
        options: buildStoreOptions(counterpartyStore, 'name'),
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'freightForwarderId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'formulas.freight-forwarder',
        options: buildStoreOptions(counterpartyStore, 'name'),
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'productId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'formulas.product',
        options: productOptions(),
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'campaignId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'formulas.campaign',
        options: buildStoreOptions(campaignStore, 'name'),
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'destinationId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'formulas.destination',
        options: buildStoreOptions(addressStore, 'name'),
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'departurePortId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'formulas.departure-port',
        options: buildStoreOptions(addressStore, 'name'),
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'recipientId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'formulas.recipient',
        options: buildStoreOptions(counterpartyStore, 'name'),
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'executionCvId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'formulas.execution-cv',
        options: buildStoreOptions(cvExecutionStore, 'number'),
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'authorizationNumber',
      componentName: 'input',
      params: {
        i18nLabel: 'formulas.authorization-number',
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'harvest',
      componentName: 'input',
      params: {
        i18nLabel: 'formulas.harvest',
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'grade',
      componentName: 'input',
      params: {
        i18nLabel: 'formulas.grade',
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'quality',
      componentName: 'input',
      params: {
        i18nLabel: 'formulas.quality',
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'netWeight',
      componentName: 'input-number',
      params: {
        i18nLabel: 'formulas.net-weight',
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'grossWeight',
      componentName: 'input-number',
      params: {
        i18nLabel: 'formulas.gross-weight',
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'packaging',
      componentName: 'input',
      params: {
        i18nLabel: 'formulas.packaging',
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'packagingCount',
      componentName: 'input-number',
      params: {
        i18nLabel: 'formulas.packaging-count',
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'customsNomenclature',
      componentName: 'input',
      params: {
        i18nLabel: 'formulas.customs-nomenclature',
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'ship',
      componentName: 'input',
      params: {
        i18nLabel: 'formulas.ship',
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'fO1Date',
      componentName: 'datepicker',
      params: {
        i18nLabel: 'formulas.fO1Date',
        showTime: true,
      },
      rules: [
        { required: true },
      ],
    },
  ];
};
