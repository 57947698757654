import { Space } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { getFormattedPriceWithCurrency } from '@/utils/formatters';
import { api } from '@/api';
import { showError } from '@/utils/common';
import { withPermissions } from '@/hocs/permission';
import {
  PERMISSION_UPDATE,
  PERMISSION_DELETE,
  PERMISSION_OBJ_FORMULA_PAYMENT,
  PERMISSION_OBJ_FORMULA_TAX,
} from '@/constants/permissions';
import { EditAction, DeleteAction } from '@/components/table/actions';
import { formulaStore } from '@/stores';

const PermissionedPaymentEditAction = withPermissions([[PERMISSION_OBJ_FORMULA_PAYMENT, PERMISSION_UPDATE]])(EditAction);
const PermissionedPyamentDeleteAction = withPermissions([[PERMISSION_OBJ_FORMULA_PAYMENT, PERMISSION_DELETE]])(DeleteAction);

const PermissionedTaxEditAction = withPermissions([[PERMISSION_OBJ_FORMULA_TAX, PERMISSION_UPDATE]])(EditAction);
const PermissionedTaxDeleteAction = withPermissions([[PERMISSION_OBJ_FORMULA_TAX, PERMISSION_DELETE]])(DeleteAction);

const PaymentActions = (record: any, formulaId: number) => {
  const onDelete = () => {
    api.formulas.deletePayment(formulaId, record.id).source
      .then(() => {
        const found = formulaStore.items
          .find((formula: cyrian.api.formulas.Formula) => formula.id === formulaId);

        const filteredItems = found.payments
          .filter(({ id }: cyrian.api.formulas.Payment) => id !== record.id);

        formulaStore.updateItem({ ...found, payments: filteredItems });
      })
      .catch(showError);
  };

  return (
    <Space>
      <PermissionedPaymentEditAction
        uiSref={{ to: `base-layout.formulas.view.payments-edit`, params: { paymentId: record.id, formulaId } }} />
      <PermissionedPyamentDeleteAction onConfirm={onDelete} />
    </Space>
  );
};

const TaxActions = (record: any, formulaId: number) => {
  const onDelete = () => {
    api.formulas.deleteTax(formulaId, record.id).source
      .then(() => {
        const found = formulaStore.items
          .find((formula: cyrian.api.formulas.Formula) => formula.id === formulaId);

        const filteredItems = found.taxes
          .filter(({ id }: cyrian.api.formulas.Tax) => id !== record.id);

        formulaStore.updateItem({ ...found, taxes: filteredItems });
      })
      .catch(showError);
  };

  return (
    <Space>
      <PermissionedTaxEditAction
        uiSref={{ to: `base-layout.formulas.view.taxes-edit`, params: { taxId: record.id, formulaId } }} />
      <PermissionedTaxDeleteAction onConfirm={onDelete} />
    </Space>
  );
};

export const paymentColumns = (id: number) => [
  {
    title: 'id',
    dataIndex: 'id',
  },
  {
    title: <FormattedMessage id="formula.payment.type" />,
    dataIndex: 'type',
  },
  {
    title: <FormattedMessage id="formula.payment.weight" />,
    dataIndex: 'weight',
  },
  {
    title: <FormattedMessage id="formula.payment.rate" />,
    dataIndex: 'rate',
  },
  {
    title: <FormattedMessage id="formula.payment.support" />,
    dataIndex: 'support',
  },
  {
    title: <FormattedMessage id="table.actions" />,
    render: (_, record) => PaymentActions(record, id),
  },
];

export const taxColumns = (id: number) => [
  {
    title: 'id',
    dataIndex: 'id',
  },
  {
    title: <FormattedMessage id="formula.taxes.name" />,
    dataIndex: 'name',
  },
  {
    title: <FormattedMessage id="formula.taxes.check" />,
    dataIndex: 'check',
  },
  {
    title: <FormattedMessage id="formula.taxes.date" />,
    dataIndex: 'date',
  },
  {
    title: <FormattedMessage id="formula.taxes.rate" />,
    dataIndex: 'rate',
  },
  {
    title: <FormattedMessage id="formula.taxes.price" />,
    dataIndex: 'price',
    render: getFormattedPriceWithCurrency,
  },
  {
    title: <FormattedMessage id="table.actions" />,
    render: (_, record) => TaxActions(record, id),
  },
];

