import { campaignStore, counterpartyStore, currencyStore } from '@/stores';
import { buildStoreOptions } from '@/utils/common';
import { getFormattedVolume } from '@/utils/formatters';
import { getFormattedDateOnly, getFormattedQuarterDate } from '@/utils/moment';
import { getParsedVolume } from '@/utils/parsers';

export const getFormFields = () => {
  return [
    {
      name: 'name',
      componentName: 'input',
      params: {
        i18nLabel: 'cvs.name',
      },
      rules: [
        { required: true },
        { max: 50 },
      ],
    },
    {
      name: 'period',
      componentName: 'datepicker',
      params: {
        i18nLabel: 'cvs.period',
        picker: 'quarter',
        format: getFormattedQuarterDate,
      },
      rules: [{ required: true }],
    },
    {
      name: 'number',
      componentName: 'input',
      params: {
        i18nLabel: 'cvs.number',
      },
      rules: [
        { required: true },
        { max: 20 },
      ],
    },
    {
      name: 'volume',
      componentName: 'input-number',
      params: {
        i18nLabel: 'cvs.volume',
        min: 0,
        formatter: getFormattedVolume,
        parser: getParsedVolume,
      },
      rules: [{ required: true }],
    },
    {
      name: 'price',
      componentName: 'input-with-dropdown',
      params: {
        i18nLabel: 'cvs.price',
        options: buildStoreOptions(currencyStore, 'code'),
      },
      rules: [{ required: false }],
    },
    {
      name: 'taxes',
      componentName: 'input-number',
      params: {
        i18nLabel: 'cvs.taxes',
        min: 0,
      },
      rules: [{ required: true }],
    },
    {
      name: 'counterpartyId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'cvs.counterparty',
        options: buildStoreOptions(counterpartyStore, 'name'),
      },
      rules: [{ required: true }],
    },
    {
      name: 'campaignId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'cvs.campaign',
        options: buildStoreOptions(campaignStore, 'name'),
      },
      rules: [{ required: true }],
    },
    {
      name: 'unlockingDate',
      componentName: 'datepicker',
      params: {
        i18nLabel: 'cvs.unlocking-date',
        format: getFormattedDateOnly,
      },
      rules: [{ required: true }],
    },
  ];
};
