import { FormattedMessage } from 'react-intl';
import { Space } from 'antd';

import { getFormattedDate } from '@/utils/moment';

import { api } from '@/api';
import { AttachmentAction, DeleteAction } from '@/components/table/actions';
import {
  PERMISSION_DELETE,
  PERMISSION_OBJ_LOT_FUMIGATION,
  PERMISSION_UPDATE,
} from '@/constants/permissions';
import { lotFumigationStore } from '@/stores';
import { showError } from '@/utils/common';
import { withPermissions } from '@/hocs/permission';

const PermissionedDeleteAction = withPermissions([[PERMISSION_OBJ_LOT_FUMIGATION, PERMISSION_DELETE]])(DeleteAction);
const PermissionedAttachmentAction = withPermissions([[PERMISSION_OBJ_LOT_FUMIGATION, PERMISSION_UPDATE]])(AttachmentAction);

const Actions = ({ lotId, fumigationId }: { lotId: number; fumigationId: number }) => {
  const onDelete = () => {
    api.lots.deleteFumigation(lotId, fumigationId).source
      .then(() => lotFumigationStore.deleteItem(fumigationId))
      .catch(showError);
  };

  return (
    <Space>
      <PermissionedAttachmentAction uiSref={{ to: 'base-layout.lots.view.fumigation-attachments', params: { lotId, fumigationId } }} />
      <PermissionedDeleteAction onConfirm={onDelete} />
    </Space>
  );
};

export const getColumns = (lotId: number) => {
  return [
    {
      title: 'id',
      dataIndex: 'id',
      sorter: true,
    },
    {
      title: <FormattedMessage id="lot.fumigation.location" />,
      dataIndex: ['address', 'name'],
      sorter: true,
    },
    {
      title: <FormattedMessage id="lot.fumigation.start-date" />,
      dataIndex: 'startDate',
      render: getFormattedDate,
      sorter: true,
    },
    {
      title: <FormattedMessage id="lot.fumigation.end-date" />,
      dataIndex: 'endDate',
      render: getFormattedDate,
      sorter: true,
    },
    {
      title: '',
      render: (_, record: any) => <Actions lotId={lotId} fumigationId={record.id} />,
      className: 'actions',
    },
  ];
}
