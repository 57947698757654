import { UIViewInjectedProps } from '@uirouter/react';
import { Form } from 'antd';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useMemo } from 'react';

import { api } from '@/api';
import { getAllowedFormFields } from '@/components/generic-form/utils';
import { DrawerFormWithNote } from '@/components/note-form';
import { PERMISSION_OBJ_LOT } from '@/constants/permissions';
import {
  addressStore,
  bvStore,
  campaignStore,
  counterpartyContractStore,
  counterpartyStore,
  cvExecutionStore,
  lotStore,
  productStore,
} from '@/stores';
import { NoteObjectType, ProductionStatus } from '@/types/enums';
import { getTranslatedString } from '@/utils';
import { withoutRepeatValue } from '@/utils/controller';
import { useMassUpdateCashedStores } from '@/utils/store';

import { getFormFields } from './setup';

export const LotForm = observer(({ transition }: UIViewInjectedProps) => {
  const isCreate = transition.router.globals.current.name === 'base-layout.lots.create';
  const { lotId } = transition.router.globals.params;
  const lot = lotStore.items.find(({ id }: any) => id === +lotId);

  const [form] = Form.useForm();

  const { loading } = useMassUpdateCashedStores([
    bvStore,
    cvExecutionStore,
    campaignStore,
    counterpartyStore,
    counterpartyContractStore,
    productStore,
    addressStore,
  ]);

  const onClose = () => {
    transition.router.stateService.go('base-layout.lots');
  };

  const defaultCampaignId = isCreate && useMemo(
    () => campaignStore.items.slice()
      .sort((a, b) => Number(b.year) - Number(a.year))[0]?.id,
    [campaignStore.items]);

  const initValues = !isCreate && lot
    ? {
      ...lot,
      // unblockCvId: lot.unblockCv.id,
      executionCvId: lot.executionCv ? lot.executionCv.id : null,
      bvId: lot.bv?.id,
      campaignId: lot.campaign.id,
      ownerId: lot.owner?.id,
      productId: lot.product.id,
      factoryId: lot.product.id,
      locationId: lot.product.id,
      productionDate: lot.productionDate && moment(lot.productionDate),
    }
    : {
      productionDate: moment(new Date()),
      sacksQuantity: 385,
      weight: 25025,
      campaignId: defaultCampaignId,
      productionStatus: ProductionStatus.Produced,
    };

  const title = isCreate
    ? getTranslatedString('lots.create-new')
    : getTranslatedString('lots.lot-number', { 0: lotId });

  const onChangeLocation = (id: number) => {
    isCreate && form.setFieldsValue({ locationId: id });
  };

  const formFields = getFormFields(onChangeLocation);
  const allowedFields = getAllowedFormFields(formFields, isCreate, PERMISSION_OBJ_LOT);

  const resourceController = withoutRepeatValue(initValues, {
    create: (values: any) => api.lots.create(values).source
      .then(({ data }: any) => lotStore.addItem(data)),
    update: (values: any) => api.lots.update(lot.id, values).source
      .then(({ data }: any) => lotStore.updateItem(data)),
  });

  return (
    <DrawerFormWithNote
      objectType={NoteObjectType.Lot}
      form={form}
      resourceId={lotId}
      title={title}
      initValues={initValues}
      formFields={allowedFields}
      onClose={onClose}
      resourceController={resourceController}
      loaderCondition={loading || (!isCreate && !lot)}
    />
  );
});
