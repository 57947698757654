import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UISref } from '@uirouter/react';
import { Button, Space } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { api } from '@/api';
import {
  AttachmentAction,
  DeleteAction,
  EditAction,
  ReportAction,
} from '@/components/table/actions';
import {
  PERMISSION_DELETE,
  PERMISSION_OBJ_TRANSFER,
  PERMISSION_UPDATE,
} from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';
import { transferStore } from '@/stores';
import { booleanFormatted, productFormattedLabel, showError } from '@/utils/common';

export const SelectorAction = ({ uiSref: { to, params }, title }: any) => {
  return (
    <UISref to={to} params={params}>
      <a>
        <Button type="primary" shape="round" icon={<FontAwesomeIcon icon={faPen} />}>{title}</Button>
      </a>
    </UISref>
  );
};

const PermissionedEditAction = withPermissions([[PERMISSION_OBJ_TRANSFER, PERMISSION_UPDATE]])(EditAction);
const PermissionedSelectorAction = withPermissions([[PERMISSION_OBJ_TRANSFER, PERMISSION_UPDATE]])(SelectorAction);
const PermissionedDeleteAction = withPermissions([[PERMISSION_OBJ_TRANSFER, PERMISSION_DELETE]])(DeleteAction);
const PermissionedAttachmentAction = withPermissions([[PERMISSION_OBJ_TRANSFER, PERMISSION_UPDATE]])(AttachmentAction);

const Actions = (_: any, record: any) => {
  const onDelete = () => {
    api.transfers.delete(record.id).source
      .then(() => transferStore.deleteItem(record.id))
      .catch(showError);
  };

  const onReportClick = () => {
    api.transfers.report(record.id).source
      .then((data) => {
        const url = URL.createObjectURL(data);
        window.open(url);
      })
      .catch(showError);
  };

  return (
    <Space>
      <PermissionedEditAction uiSref={{ to: 'base-layout.transfers.edit', params: { transferId: record.id } }} />
      <PermissionedAttachmentAction
        uiSref={{ to: 'base-layout.transfers.attachments', params: { transferId: record.id } }} />
      <PermissionedSelectorAction uiSref={{ to: 'base-layout.transfers.lots', params: { transferId: record.id } }}
                                  title="Lots" />
      <PermissionedDeleteAction onConfirm={onDelete} />
      <ReportAction onClick={onReportClick} />
    </Space>
  );
};

export const columns = [
  {
    title: 'id',
    dataIndex: 'id',
    sorter: true,
  },
  {
    title: <FormattedMessage id="transfers.product" />,
    dataIndex: ['product', 'name'],
    sorter: true,
    render: (_, record) => productFormattedLabel(record.product),
  },
  {
    title: <FormattedMessage id="transfers.campaign" />,
    dataIndex: ['campaign', 'name'],
    sorter: true,
  },
  {
    title: <FormattedMessage id="transfers.carrier" />,
    dataIndex: ['carrier', 'name'],
    sorter: true,
  },
  {
    title: <FormattedMessage id="transfers.transport-charge-counterparty" />,
    dataIndex: ['transportChargeCounterparty', 'name'],
    sorter: true,
  },
  {
    title: <FormattedMessage id="transfers.loading-counterparty" />,
    dataIndex: ['loadingCounterparty', 'name'],
    sorter: true,
  },
  {
    title: <FormattedMessage id="transfers.start-location" />,
    dataIndex: ['startLocation', 'name'],
    sorter: true,
  },
  {
    title: <FormattedMessage id="transfers.end-location" />,
    dataIndex: ['endLocation', 'name'],
    sorter: true,
  },
  {
    title: <FormattedMessage id="transfers.number" />,
    dataIndex: 'number',
    sorter: true,
  },
  {
    title: <FormattedMessage id="transfers.status" />,
    dataIndex: 'status',
    sorter: true,
    render: (_, record) => <FormattedMessage id={`transfers.status.${record?.status}`} />,
  },
  {
    title: <FormattedMessage id="transfers.phyto-position" />,
    dataIndex: 'phytoPosition',
    sorter: true,
    render: booleanFormatted,
  },
  {
    title: <FormattedMessage id="transfers.phyto-treatment-days" />,
    dataIndex: 'phytoTreatmentDays',
    sorter: true,
  },
  {
    title: <FormattedMessage id="transfers.phone-number" />,
    dataIndex: 'phoneNumber',
    sorter: true,
  },
  {
    title: "",
    render: Actions,
    className: "actions",
  },
];
