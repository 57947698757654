import React from 'react';
import {
  faHomeAlt,
  faShip,
  faTruckContainer,
  faUniversity,
  faUserCrown,
  faUserShield,
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface CompanyIconProps {
  type: cyrian.api.counterparties.Type;
}

export const CompanyIcon = ({ type }: CompanyIconProps) => {
  switch (type) {
    case 'Bank':
      return <FontAwesomeIcon className="fa-stack-1x" icon={faUniversity} style={{ color: 'grey' }} />
    case 'Customer':
      return <FontAwesomeIcon className="fa-stack-1x" icon={faUserCrown} style={{ color: 'grey' }} />
    case 'Forwarder':
      return <FontAwesomeIcon className="fa-stack-1x" icon={faTruckContainer} style={{ color: 'grey' }} />
    case 'Shipping line':
      return (
        <span style={{ fontSize: '1rem' }}>
          <FontAwesomeIcon icon={faHomeAlt} className="fa-stack-2x" transform="up-4" style={{ color: 'grey' }} />
          <FontAwesomeIcon icon={faShip} className="fa-stack-1x" transform="shrink-3" style={{ color: 'grey','--fa-secondary-opacity': 0.7 } as React.CSSProperties} />
        </span>
      )
    case 'Verification':
      return <FontAwesomeIcon className="fa-stack-1x" icon={faUserShield} style={{ color: 'grey' }} />
    default:
      return null;
  }
}
