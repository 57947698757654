import { Space } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { api } from '@/api';
import {
  AttachmentAction,
  DeleteAction,
  EditAction,
  NotesAction,
} from '@/components/table/actions';
import {
  PERMISSION_OBJ_BV,
  PERMISSION_DELETE,
  PERMISSION_UPDATE,
} from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';
import { bvStore } from '@/stores';
import { BVStatus } from '@/types/enums';
import { buildFilterOptionsFromEnum, showError } from '@/utils/common';
import { getFormattedDate } from '@/utils/moment';
import { getTranslatedString } from '@/utils';

const PermissionedEditAction = withPermissions([[PERMISSION_OBJ_BV, PERMISSION_UPDATE]])(EditAction);
const PermissionedDeleteAction = withPermissions([[PERMISSION_OBJ_BV, PERMISSION_DELETE]])(DeleteAction);
const PermissionedAttachmentAction = withPermissions([[PERMISSION_OBJ_BV, PERMISSION_UPDATE]])(AttachmentAction);
const PermissionedNotesAction = withPermissions([[PERMISSION_OBJ_BV, PERMISSION_UPDATE]])(NotesAction);

const Actions = (_: any, record: any) => {
  const onDelete = () => {
    api.bvs.delete(record.id).source
      .then(() => bvStore.deleteItem(record.id))
      .catch(showError);
  };

  return (
    <Space>
      <PermissionedEditAction uiSref={{ to: 'base-layout.bvs.edit', params: { bvId: record.id } }} />
      <PermissionedAttachmentAction uiSref={{ to: 'base-layout.bvs.attachments', params: { bvId: record.id } }} />
      <PermissionedNotesAction uiSref={{ to: 'base-layout.bvs.notes', params: { bvId: record.id } }} />
      <PermissionedDeleteAction onConfirm={onDelete} />
    </Space>
  );
};

export const columns = [
  {
    title: 'id',
    dataIndex: 'id',
    sorter: true,
  },
  {
    title: <FormattedMessage id="bvs.name" />,
    dataIndex: 'name',
    sorter: true,
  },
  {
    title: <FormattedMessage id="bvs.number" />,
    dataIndex: 'number',
    sorter: true,
  },
  {
    title: <FormattedMessage id="bvs.lots" />,
    dataIndex: 'lots',
    width: 700,
    render: (_, record) => (record?.lots ?? []).sort((a, b) => a - b).join(', '),
  },
  {
    title: <FormattedMessage id="bvs.status" />,
    dataIndex: 'status',
    filterMultiple: false,
    filters: buildFilterOptionsFromEnum(BVStatus),
    render: (_, record) => getTranslatedString(`bvs.status.${record?.status}`),
  },
  {
    title: <FormattedMessage id="bvs.acceptance-date" />,
    dataIndex: 'acceptanceDate',
    sorter: true,
    render: getFormattedDate,
  },
  {
    title: <FormattedMessage id="bvs.expiration-date" />,
    dataIndex: 'expirationDate',
    sorter: true,
    render: getFormattedDate,
  },
  {
    title: "",
    render: Actions,
    className: "actions",
  },
];
