import {
  PERMISSION_CREATE,
  PERMISSION_OBJ_CV_EXECUTION,
  PERMISSION_READ,
  PERMISSION_UPDATE,
} from '@/constants/permissions';

import { CVExecutionAttachments } from './components/attachments';
import { CVExecutionForm } from './components/form';
import { CVExecutions } from './components/table';

export const states = [
  {
    name: 'base-layout.cv-executions',
    url: '/cv-executions',
    component: CVExecutions,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_CV_EXECUTION, PERMISSION_READ],
      ],
    },
  },
  {
    name: 'base-layout.cv-executions.create',
    url: '/new',
    component: CVExecutionForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_CV_EXECUTION, PERMISSION_CREATE],
      ],
    },
  },
  {
    name: 'base-layout.cv-executions.edit',
    url: '/:cvExecutionId/edit',
    component: CVExecutionForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_CV_EXECUTION, PERMISSION_UPDATE],
      ],
    },
  },
  {
    name: 'base-layout.cv-executions.attachments',
    url: '/:cvExecutionId/attachments',
    component: CVExecutionAttachments,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_CV_EXECUTION, PERMISSION_UPDATE],
      ],
    },
  },
];
