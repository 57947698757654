import { UIViewInjectedProps } from '@uirouter/react';
import { notification, Form } from 'antd';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useMemo } from 'react';

import { api } from '@/api';
import { DrawerForm } from '@/components/drawer-form';
import { getAllowedFormFields } from '@/components/generic-form/utils';
import { PERMISSION_OBJ_LOT } from '@/constants/permissions';
import {
  addressStore,
  bvStore,
  campaignStore,
  counterpartyContractStore,
  counterpartyStore,
  cvExecutionStore,
  lotStore,
  productStore,
} from '@/stores';
import { ProductionStatus } from '@/types/enums';
import { getTranslatedString } from '@/utils';
import { withoutRepeatValue } from '@/utils/controller';
import { useMassUpdateCashedStores } from '@/utils/store';

import { getFormFields } from './setup';

const warn = (diff: number[]) => {
  if (diff.length) {
    notification.warn({
      message: getTranslatedString('common.warning'),
      description: getTranslatedString('lots.mass-create-warn', { 0: diff.join(', ') }),
      duration: 3,
    });
  }
};

export const LotMassCreateForm = observer(({ transition }: UIViewInjectedProps) => {
  const [form] = Form.useForm();

  const { loading } = useMassUpdateCashedStores([
    bvStore,
    cvExecutionStore,
    campaignStore,
    counterpartyStore,
    counterpartyContractStore,
    productStore,
    addressStore,
  ]);

  const onClose = () => {
    transition.router.stateService.go('base-layout.lots');
  };

  const defaultCampaignId = useMemo(
    () => campaignStore.items.slice()
      .sort((a, b) => Number(b.year) - Number(a.year))[0]?.id,
    [campaignStore.items]);

  const initValues = {
    count: 0,
    number: 1,
    productionDate: moment(new Date()),
    sacksQuantity: 385,
    weight: 25025,
    campaignId: defaultCampaignId,
    productionStatus: ProductionStatus.Produced,
  };
  const title = getTranslatedString('lots.mass-add-new');

  const onChangeLocation = (id: number) => {
    form.setFieldsValue({ locationId: id });
  };

  const formFields = getFormFields(onChangeLocation, true);
  const allowedFields = getAllowedFormFields(formFields, true, PERMISSION_OBJ_LOT, ['count']);

  const create = async (values: any) => {
    const params = Array
      .from(new Array(values.count + 1), (x, i) => i)
      .map(i => ({ ...values, number: values.number + i }));

    const numbers: number[] = params.map(({ number }) => number);

    try {
      const { data: { invalid = [] } } = await api.lots.checkNumbers({ numbers }).source;

      if (invalid.length) {
        const errMsg: string = getTranslatedString('lots.mass-create-warn', { 0: invalid.join(', ') });

        form.setFields([{
          name: 'number',
          errors: [errMsg],
        }]);

        form.scrollToField('number');
        return Promise.reject(new Error(getTranslatedString('lots.mass-create-warn2')));
      }

      const { data: { items = [] } } = await api.lots.createMany({ items: params }).source;
      return items.forEach((item: cyrian.api.lots.Lot) => lotStore.addItem(item));
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const resourceController = withoutRepeatValue(initValues, { create });

  return (
    <DrawerForm
      form={form}
      title={title}
      initValues={initValues}
      formFields={allowedFields}
      onClose={onClose}
      resourceController={resourceController}
      loaderCondition={loading}
    />
  );
});
