import { useEffect, useState, DependencyList, EffectCallback } from 'react';

import { CollectionStore, RelCollectionStore } from '@/stores/collection';
import { settingsStore } from '@/stores';

interface LoadItemsOptions {
  limit: number;
}

export const defaultMassUpdateCashedStores = (cachedStores: CollectionStore<any, any>[], options: LoadItemsOptions) => {
  // TODO remove limit when will refactor store
  const promises = cachedStores.map((cachedStore => cachedStore.asyncItems(options)));
  return Promise.all(promises);
};

export const useMassUpdateCashedStores = (cachedStores: CollectionStore<any, any>[], deps: DependencyList = []) => {
  const [loading, setLoading] = useState<boolean>(false);

  const effect: EffectCallback = () => {
    setLoading(true);

    defaultMassUpdateCashedStores(cachedStores, { limit: 50 })
      .then(() => setLoading(false));
  };

  useEffect(effect, deps);

  return { loading, setLoading };
};

export const useMassUpdateCashedRelStores = (cachedStores: RelCollectionStore<any, any>[], relId: number, deps: DependencyList = []) => {
  const [loading, setLoading] = useState<boolean>(false);

  const effect: EffectCallback = () => {
    setLoading(true);

    Promise.all(cachedStores.map((cachedStore => cachedStore.asyncItems(relId, { limit: 10 }))))
      .then(() => setLoading(false));
  };

  useEffect(effect, deps);

  return { loading, setLoading };
};
